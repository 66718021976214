import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = ['modal'];

  connect(){
  }

  openModal(){
    this.closeDropdown();
    const modalId = event.currentTarget.dataset.id;
    MicroModal.show(modalId);
  }

  closeDropdown(){
    // Remove any dropdowns if loaded on the page before opening the modal
    const dropdown = document.querySelector('[data-tippy-root]');
    if (dropdown) dropdown.remove();
  }
}
