import { Controller } from "@hotwired/stimulus"
import ChartJs from "../../components/initialize_graph";

export default class extends Controller {
  static targets = ['chart'];

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    this.initializeChart()
  }

  initializeChart(){    
    if (!this.hasChartTarget) return;

    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    this.historyChart = new ChartJs(this.chartTarget, graphData);
    this.historyChart.drawChart();
  }
}