import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ['modal'];

  openModal(){
    this.closeDropdown();
    const modalId = event.currentTarget.dataset.id;
    MicroModal.show(modalId);

    // Initialize
    this.initializeFormula(modalId)
  }

  initializeFormula(modalId){
    const select = document.querySelector(`#${modalId} [data-select]`)

    if(!select) return;

    const tomInstance = select.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    initializeSelect({
      selector: select,
      multiple: true,
      addable: false,
      closeAfterSelect: false,
      columnGroup: true,
      otherSettings: {
        options: JSON.parse(select.dataset.options),
        optgroups: JSON.parse(select.dataset.optgroups),
        items: JSON.parse(select.dataset.items),
        optgroupField: 'type',
        optgroupLabelField: 'name',
        optgroupValueField: 'type',
        lockOptgroupOrder: true,
        hideSelected: false,
        duplicates: true,
        plugins: ['optgroup_columns'],
        persist: false,
        onItemAdd: function(str){
          const tomInstance = select.tomselect;
          tomInstance.setTextboxValue('')
          tomInstance.refreshOptions()
        }
      }
    });
  }

  closeDropdown(){
    // Remove any dropdowns if loaded on the page before opening the modal
    const dropdown = document.querySelector('[data-tippy-root]');
    if (dropdown) dropdown.remove();
  }
}
