import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';

export default class extends Controller {
  static targets = ['map', 'table', 'zoomBtnWrapper', 'screenSizeBtn']

  switchTableMapHandler = (event) => this.switchView(event.detail.state)
  toggleScreenSizeHandler = () => this.toggleMapSize()
  
  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    window.addEventListener('switch-table-map', this.switchTableMapHandler)
    window.addEventListener('toggle-screen-size', this.toggleScreenSizeHandler)
  }

  disconnect(){
    window.removeEventListener('switch-table-map', this.switchTableMapHandler)
    window.removeEventListener('toggle-screen-size', this.toggleScreenSizeHandler)
  }

  switchView(state){
    if (state === 'map'){
      this.tableTarget.classList.add('hidden')
      this.mapTarget.classList.remove('hidden')
      this.zoomBtnWrapperTarget.classList.remove('hidden')
    } else {
      this.tableTarget.classList.remove('hidden')
      this.mapTarget.classList.add('hidden')
      this.zoomBtnWrapperTarget.classList.add('hidden')
    }

    // Events
    const stateEvent = new CustomEvent("state-updated", { detail: { display: state } });
    window.dispatchEvent(stateEvent);
  }

  switchValidated(){
    const stateEvent = new CustomEvent("state-updated", { detail: { validated_only: event.currentTarget.checked } });
    window.dispatchEvent(stateEvent);
    const validationEvent = new CustomEvent("validated-only-switch-toggled", { detail: { validated_only: event.currentTarget.checked } });
    window.dispatchEvent(validationEvent);
  }

  toggleMapSize(){
    const button = this.screenSizeBtnTarget;
    const oldSize = button.dataset.graphSize;
    const newSize = (oldSize === 'big') ? 'small' : 'big';

    // Update button status
    button.classList.toggle("fa-chevron-right");
    button.classList.toggle("fa-chevron-left");
    button.dataset.graphSize = newSize;

    const testDataset = (newSize === 'big') ? 'decrease-graph-size' : 'increase-graph-size'
    button.dataset.test = testDataset

    // Events
    const stateEvent = new CustomEvent("state-updated", { detail: { screen: newSize } });
    window.dispatchEvent(stateEvent);
    
    const fullScreenEvent = new CustomEvent("screen-size-toggled", { detail: { screen: newSize } });
    window.dispatchEvent(fullScreenEvent);
  }

  zoomIn(){
    const zoomEvent = new CustomEvent("zoom-in");
    window.dispatchEvent(zoomEvent);
  }

  zoomOut(){
    const zoomEvent = new CustomEvent("zoom-out");
    window.dispatchEvent(zoomEvent);
  }
}
