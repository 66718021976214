import {Controller} from "@hotwired/stimulus";
import initializeSelect from '../components/initialize_select';
import {removeContent, projectName} from "../components/utilities";

export default class extends Controller {
    static targets = ["form", "searchInput", "wordInput", "example", "loadMore", "wordsPane", "formSubmit"];

    connect() {
        this.selectedWords = []
        const selectDico = document.querySelector('#select-dico');
        const ajaxParams = {
            url: `${window.location.origin}/${projectName(window.location.pathname)}/admin/lab/search_dico`,
            input: {}
        }

        // initialize the select for link to
        this.selectDico = initializeSelect({
            selector: selectDico,
            config: {
                addable: true,
                remove_button: true,
                ajax_params: ajaxParams
            },
            changeCallback: () => this.checkSelectInput()
        });

        // For search
        this.typingTimer = null;
    }

    // Launch the search if the user stops typing for more than 50 ms
    launchSearch(){
      if (event.key === 'Enter' || event.type === 'click') {
        this.search(this.searchInputTarget);
      }
    }

    initForm() {
        const word = event.currentTarget.querySelectorAll('span')[0].innerText;

        if (this.selectedWords.includes(word)) {
            event.currentTarget.classList.add('bg-gray-300');
            event.currentTarget.classList.remove('bg-dark-200', 'text-green-100');
            this.selectedWords.splice(this.selectedWords.indexOf(word), 1);
        } else {
            event.currentTarget.classList.add('bg-dark-200', 'text-green-100');
            event.currentTarget.classList.remove('bg-gray-300')
            this.selectedWords.push(word);
        }

        if (this.selectedWords.length === 0) {
            this.resetForm();
            return;
        } else {
            // Enable the form if not already done
            this.formTarget.querySelectorAll('input[type="radio"]').forEach(el => {
                el.removeAttribute('disabled');
            })

            this.formTarget.classList.remove('opacity-25');
            this.formSubmitTarget.classList.remove('btn-disabled');
        }

        // Update the value of the word input
        this.wordInputTarget.value = this.selectedWords;

        // Get an extract of a phrase with the selected word
        this.getExample(this.selectedWords[this.selectedWords.length - 1]);

        // Disable select dico
        this.selectDico.disable();
    }

      // disableSelect and enableSelect also modifies formSubmitTarget
    checkSelectInput() {
      if (this.element.querySelector('#select-dico').value === "") {
        this.formSubmitTarget.classList.add('btn-disabled');
      } else {
        this.formSubmitTarget.classList.remove('btn-disabled');
      }
    }

    resetForm() {
        // Disable the form if not already done
        this.formTarget.querySelectorAll('input[type="radio"]').forEach(el => {
            el.setAttribute('disabled', '');
        })

        this.formTarget.classList.add('opacity-25');
        this.formSubmitTarget.classList.add('btn-disabled');
        this.selectedWords = [];

        this.wordInputTarget.value = this.selectedWords;
        // Get an extract of a phrase with the selected word
        this.getExample(this.selectedWords[this.selectedWords.length - 1]);

        // Reinitialize color of word tags
        this.wordsPaneTarget.querySelectorAll("[data-lab-words]").forEach((words) => {
            words.classList.remove('bg-dark-200', 'text-green-100');
            words.classList.add('bg-gray-300');
        })
    }

    enableSelect() {
        // We enable the select only if we clicked before on a word and that we click on the radio btn link to
        if (!event.currentTarget.querySelector('input[type="radio"]').hasAttribute('disabled')) {
            document.querySelector('#add').checked = false;
            document.querySelector('#link_to').checked = true;
            this.selectDico.enable();

            // checkForm and disableSelect also modifies formSubmitTarget
            this.formSubmitTarget.classList.add('btn-disabled');
        }
    }

    disableSelect() {
        // We disable the select only if we clicked before on a word and that we click on the radio btn add to dictionnary
        if (!event.currentTarget.querySelector('input[type="radio"]').hasAttribute('disabled')) {
            document.querySelector('#add').checked = true;
            document.querySelector('#link_to').checked = false;
            this.selectDico.disable();

            // checkForm and enableSelect also modifies formSubmitTarget
            this.formSubmitTarget.classList.remove('btn-disabled');
        }
    }

    // AJAX request to find an example among the csv content
    getExample(word) {
        if (word === undefined) return;
        
        // Set the url
        let url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/lab/find_example`;

        const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
            .content.value;
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/js",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            credentials: "same-origin",
            body: JSON.stringify({word: word})
        })
            .then(response => response.json())
            .then(data => {
                this.exampleTarget.classList.remove('hidden');
                const textExample = this.exampleTarget.querySelectorAll('div')[1]
                textExample.innerText = data["example"]

                // Highlight the word
                const regex = RegExp(String.raw`${word}`, 'gi')
                textExample.innerHTML = textExample.innerText.replace(regex, "<span class='bg-yellow-100'>$&</span>")
            });
    }

    loadMoreWords() {
        let target = event.currentTarget;
        let url = target.dataset.url;

        const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
            .content.value;
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/js",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            credentials: "same-origin",
            body: JSON.stringify({offset: target.dataset.offset, panel: "vocabulary", type: "new_words"})
        })
            .then(response => response.json())
            .then(data => {
                target.insertAdjacentHTML('beforebegin', data['html']);
                target.dataset.offset = data['offset'];
                if (data['offset'] % 20 > 0) target.classList.add('hidden');
            });
    }

    search(input) {
        let url = input.dataset.url;
        if (input.value.length > 0 && input.value.length < 3) return;
        this.resetForm();

        const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/js",
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken
            },
            credentials: "same-origin",
            body: JSON.stringify({search: input.value})
        })
            .then(response => response.json())
            .then(data => {
                this.wordsPaneTarget.querySelectorAll("[data-lab-words]").forEach((words) => {
                    words.remove()
                })
                let button = this.wordsPaneTarget.firstElementChild;

                if (input.value.length > 0) button.classList.add('hidden');
                else button.classList.remove('hidden');
                button.dataset.offset = data['offset'];

                
                this.wordsPaneTarget.insertAdjacentHTML('afterbegin', data['html']);
            });
    }

}
