import { Controller } from "@hotwired/stimulus"

import cytoscape from 'cytoscape';

import coseBilkent from 'cytoscape-cose-bilkent';
cytoscape.use( coseBilkent );

import chroma from 'chroma-js';

export default class extends Controller {

  connect(){
    const mapData = JSON.parse(this.element.dataset.mapData)
    if (mapData === null) return

    this.cy = cytoscape({
      container: this.element,
      elements: mapData["elements"],
      wheelSensitivity: 0.05
    });

    // Define basic style
    this.cy.style([
      {
        selector: 'node',
        style: {
            'label': '',
            'background-opacity': '0.6',
            'text-halign': 'center',
            'text-valign': 'center',
            'font-size': 0,
            'min-zoomed-font-size': 0,
            'font-family': 'urbanist',
            'width': 'data(metric)',
            'height': 'data(metric)'
        }
      },
      {
        selector: 'edge',
        style: {
          'line-color': '#98A2B3',
          'width': 2,
          'opacity': 0.3,
          'transition-property': 'opacity',
          'transition-duration': '0.3s'
        }
      },
    ]);

    this.cy.nodes().forEach(node => {
      node.css('background-color', this.setColor(node));
    })

    // Define the layout COSE-BILKENT
    let layoutOptions = {
      name: "cose-bilkent",
      nodeDimensionsIncludeLabels: true
    }

    // Print the map without filters
    this.layout = this.cy.layout(layoutOptions);
    this.layout.run();
  }

  setColor(node){
    const colorScale = chroma.scale(['#ff6778', '#ffe58b', '#76d59e']);
    return colorScale(node.data('id') * 100/10000).css() // Coef used just to adjust the rendering with the present ids, needs to be change if we change the preview data
  }
}