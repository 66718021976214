import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper", "template"];

  addUrl() {
    const newUrl = this.templateTarget.innerHTML

    this.wrapperTarget.insertAdjacentHTML('beforeend', newUrl)
  }

  removeUrl(){
    event.currentTarget.closest("[data-url]").remove()
  }
}