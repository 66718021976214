import { Controller } from "@hotwired/stimulus"
import ChartJs from '../components/initialize_graph';

export default class extends Controller {
  static targets = ['chart']

  connect(){
    this.initializeGraph()
  }

  initializeGraph(){
    this.chartTargets.forEach(chart => {
      const graphData = JSON.parse(chart.dataset.chart);

      const graph = new ChartJs(chart, graphData);
      graph.drawChart();
    })
  }

  updateQuantityThreshold(){
    if (!event.currentTarget.checkValidity()) {      
      // Afficher le message de validation natif du navigateur
      event.currentTarget.reportValidity();
      return;
    }

    const url = `${window.location.origin}/datas/update_quantity_threshold`;

    const body = { quantity_threshold: event.currentTarget.value }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
      method: 'POST',
      headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data);
    });
  }

}
