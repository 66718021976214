import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["errorFlash", "doneFlash", "infoFlash",
                    "errorList"];

  connect(){
    // mutation observe on flashContainer.dataset.message
    this.listenFlashAttribute();
  }

  // create flash out of template
  // define if alert, info, done or error
  // hide OR delete flash which is not permanent
  createFlash(){
    const flashContent = JSON.parse(this.element.dataset.content)

    const flashType = Object.keys(flashContent)[0];

    let content;
    let message;
    let errorList;
    switch (flashType) {
      case 'error':
        content = this.errorFlashTarget.innerHTML;
        message = flashContent['error']['message'];
        errorList = flashContent['error']['list'];
      break;
      case 'done':
        content = this.doneFlashTarget.innerHTML;
        message = flashContent['done'];
      break;
      case 'info':
        content = this.infoFlashTarget.innerHTML;
        message = flashContent['info'];
      break;
    }

    content = content.replace(/FLASH_MESSAGE/g, message);
    if (errorList) {
      content = this.updateErrorList(content, errorList);
    }
    
    // Set the permanent dataset of the flash based on the value that comes from the flashContent
    const permanent = flashContent['permanent'];
    content = content.replace(/data-permanent=""/g, `data-permanent=${permanent}`)

    // Delete older flashes (max 3)
    const oldFlashes = Array.from(this.element.children).filter(child => child.tagName.toLowerCase() !== 'template')
    if (oldFlashes.length > 2) oldFlashes[0].remove()

    this.element.insertAdjacentHTML('beforeend', content);
  }

  // look for 'data-list' string index and insert errors in between the ul attributes
  updateErrorList(content, errorList){
    let errorContent = "";
    let listIndex = content.indexOf('data-list');

    errorList.forEach(error => {
      errorContent = errorContent + `<li>${error}</li>`;
    })

    return [content.slice(0, listIndex+15), errorContent, content.slice(listIndex+16)].join('');
    // return content.slice(0,listIndex+16) + errorContent + content.slice(listIndex+17);
  }

  // Listen the flash Container attributes to update the change
  // mutation observe on flashContainer.dataset.message method
  listenFlashAttribute(){
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

    // Options for the observer (which mutations to observe)
    var config = {attributes: true};

    // Callback function to execute when mutations are observed
    var toDoOnChange = (mutationList, observer) => {
      mutationList.forEach(mutationRecord => {
        this.createFlash();
      })
    }

    // Create an observer instance linked to the callback function
    this.filterObserver = new MutationObserver(toDoOnChange);

    // Start observing the target node for configured mutations
    this.filterObserver.observe(this.element, config);
  }
}
