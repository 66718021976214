
// remove all the content inside a container
const removeContent = (contentWrapper) => {
    // Deletion of the old content
  while (contentWrapper.lastChild) {
    contentWrapper.removeChild(contentWrapper.lastChild);
  }
}

// Capitalize letters
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// Convert lower_snake_case to camelCase
const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const truncate = function(str, maxLength){
     return (str.length <= maxLength) ? str : str.substring(0, maxLength) + '...';
}

const projectName = function(pathname){
     return pathname.split('/')[1];
}

export { removeContent, capitalize, toCamel, truncate, projectName };

