import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../../components/utilities';

export default class extends Controller {
  static targets = ["action", "actionsContainer"]

  // Event handlers
  timeFrameUpdatedHandler = (event) => this.initializePanel()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    window.addEventListener('time-frame-updated', this.timeFrameUpdatedHandler)

    this.initializePanel()
  }

  disconnect() {
    window.removeEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
  }

  initializePanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/results_panel`;

    const body = { type: this.element.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.element)
      this.element.insertAdjacentHTML('afterbegin', data["Workflows::Results::LeftPanelComponent"] );
  
    })
  }

  toggleAutomation(){
    let automationId;
    if (this.pageState().selected_automation_id === event.currentTarget.dataset.automationId){
      return
    } else {
      automationId = event.currentTarget.dataset.automationId
    }

    // Update page state
    const stateEvent = new CustomEvent("state-updated", { detail: { selected_automation_id: automationId } });
    window.dispatchEvent(stateEvent);

    // Update button class
    this.actionTargets.forEach(btn => btn.classList.remove('automation-btn-selected'))
    if (automationId) event.currentTarget.classList.add('automation-btn-selected')

    // Update right panel
    const automationUpdateEvent = new CustomEvent("automation-updated");
    window.dispatchEvent(automationUpdateEvent);
  }
}