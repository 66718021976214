import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';

export default class extends Controller {
  openCreateForm(){  
    this.closeDropdown();
    MicroModal.show('modal-edit-chart');

    // Trigger the event to fetch the content of the modal
    const customEvent = new CustomEvent('fetch-modal-content', { detail: { type: 'create' } });
    window.dispatchEvent(customEvent);
  }

  openEditForm(){
    this.closeDropdown();
    MicroModal.show('modal-edit-chart');
    const chartId = event.currentTarget.dataset.chartId;

    // Trigger the event to fetch the content of the modal
    const customEvent = new CustomEvent('fetch-modal-content', { detail: { type: 'edit', id: chartId } });
    window.dispatchEvent(customEvent);
  }

  closeDropdown(){
    // Remove any dropdowns if loaded on the page before opening the modal
    const dropdown = document.querySelector('[data-tippy-root]');
    if (dropdown) dropdown.remove();
  }
}
