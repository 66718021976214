import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';

export default class extends Controller {
  static targets = ['ngramContainer']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    this.getNgrams()
  }

  getNgrams(){
    // Get the top ngrams
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.element.dataset.id}/ngrams`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Update ngrams
      this.ngramContainerTarget.innerHTML = data['Topics::Ngrams']
    });
  }

  // Activated when clicking on an ngrams tag
  updateSearch(){
    const terms = event.currentTarget.firstElementChild.innerText;
    const searchEvent = new CustomEvent("search-terms-updated", { detail: { list_id: this.ngramContainerTarget.dataset.listId, terms: terms, mode: 'add' } });
    window.dispatchEvent(searchEvent);
  }
}
