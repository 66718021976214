import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['granularityWrapper', 'classificationWrapper', 'submitBtn', 'form']

  updateForm(){
    if (event.currentTarget.dataset.type === 'clusterization'){
      this.granularityWrapperTarget.classList.remove('hidden')
      this.classificationWrapperTarget.classList.add('hidden')
      this.submitBtnTarget.classList.remove('btn-disabled')
      return;
    }

    if (event.currentTarget.dataset.type === 'classification'){
      this.granularityWrapperTarget.classList.add('hidden')
      this.classificationWrapperTarget.classList.remove('hidden')
      this.submitBtnTarget.classList.add('btn-disabled')
      return;
    }

    if (event.currentTarget.dataset.type === 'scratch'){
      this.granularityWrapperTarget.classList.add('hidden')
      this.classificationWrapperTarget.classList.add('hidden')
      this.submitBtnTarget.classList.remove('btn-disabled')
      return;
    }
  }

  updateSubmit(){
    if (event.currentTarget.value !== '') {
      this.submitBtnTarget.classList.remove('btn-disabled')
    } else {
      this.submitBtnTarget.classList.add('btn-disabled')
    }
  }

  changeStep(){
    const [_data, _status, xhr] = event.detail;
    const data = JSON.parse(xhr.response)

    if (data.progress_component){
      this.formTarget.insertAdjacentHTML('afterend', data.progress_component)
      this.formTarget.remove();
    }
  }
}