import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
    static targets = ["header", 'channelSelect', 'startBeaconSelect', 'endBeaconSelect', 'beaconWrapper', 'tpl'];

    connect(){
        this.initializeSelectInstances()
    }

    initializeSelectInstances(){
        this.channelSelectTargets.forEach(select => this.initializeChannelSelect(select))
        this.startBeaconSelectTargets.forEach(select => this.initializeBeaconSelect(select))
        this.endBeaconSelectTargets.forEach(select => this.initializeBeaconSelect(select))
    }

    initializeChannelSelect(select){
        initializeSelect({
          selector: select,
          addable: true,
          otherSettings: {
            options: JSON.parse(select.dataset.options),
            items: select.dataset.selected
          }
        })
    }

    initializeBeaconSelect(select){
      initializeSelect({
        selector: select,
        otherSettings: {
          options: JSON.parse(select.dataset.options),
          items: select.dataset.selected
        }
      })
    }

    toggleOptionInput(){
      const beacon = event.currentTarget.value;
      const container = event.currentTarget.closest('[data-beacon]')
      const optionInput = container.querySelector('[data-option]')

      if (beacon === 'BC_SPECIFIC_PART_OF_TEXT'){
        optionInput.classList.remove('hidden')
      } else {
        optionInput.classList.add('hidden')
        optionInput.value = ''
      }
    }

    addBeacon(){
        // Show headers
        this.headerTarget.classList.remove('hidden')

        // Insert new row
        const newBeaconHTML = this.tplTarget.innerHTML;
        this.beaconWrapperTarget.insertAdjacentHTML('beforeend', newBeaconHTML );

        // Initialize selects of the new row
        const lastChannel = this.channelSelectTargets[this.channelSelectTargets.length - 1]
        const lastStartBeacon = this.startBeaconSelectTargets[this.startBeaconSelectTargets.length - 1]
        const lastEndBeacon = this.endBeaconSelectTargets[this.endBeaconSelectTargets.length - 1]
        this.initializeChannelSelect(lastChannel)
        this.initializeBeaconSelect(lastStartBeacon)
        this.initializeBeaconSelect(lastEndBeacon)
    }

    deleteBeacon(){
        const row = event.currentTarget.closest('[data-row]')
        row.remove();

        // Hide header if no left row
        if (this.channelSelectTargets.length === 0) this.headerTarget.classList.add('hidden')
    }
}