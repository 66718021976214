import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../components/utilities';
import ChartJs from '../../components/initialize_graph';

export default class extends Controller {

  // Event handlers
  loadIndicatorHandler = (event) => this.loadIndicator(event.detail.indicator_id)
  filteredDataUpdateHandler = (event) => this.updateIndicator(event.detail.data);

  connect(){
    window.addEventListener('load-indicator', this.loadIndicatorHandler)
    window.addEventListener('filtered-data-updated', this.filteredDataUpdateHandler )

    const id = this.element.dataset.indicatorId
    this.loadIndicator(id);
  }
 
  disconnect(){
    window.removeEventListener('load-indicator', this.loadIndicatorHandler)
    window.removeEventListener('filtered-data-updated', this.filteredDataUpdateHandler )
  }

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]')?.dataset?.pageState || '{}')
  }

  loadIndicator(id){
    // Return clause to check if the loading instruction comes as part of an event propagated from a different controller
    if (this.element.dataset.indicatorId !== id ) return;

    const dashboardId = this.element.dataset.dashboardId;

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/dashboards/${dashboardId}/indicator`;

    const origin = this.element.dataset.origin

    const body = { 
      indicator_id: id,
      origin: origin,
      page_state: this.pageState()
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.updatePanel(data.component)
      this.prepareIndicator(data.indicator_id)
      const loadedEvent = new CustomEvent("indicator-loaded", { detail: { indicator_id: data.indicator_id }});
      window.dispatchEvent(loadedEvent);
    })
  }

  updatePanel(component){
    removeContent(this.element)
    this.element.insertAdjacentHTML('afterbegin', component);
  }

  prepareIndicator(indicator_id){
    // Draw the chart in few steps
    // First, identify the chart target based on the id
    const indicator = document.querySelector(`#indicator-${indicator_id}`)
    if (indicator) this.drawAChart(indicator);

    // Check how many indicator placeholders are left in the DOM
    const placeholdersCount = document.querySelectorAll('[data-controller="dashboards--indicator"]').length

    // If no placeholders are present in the DOM that means that all indicators have been loaded, therefore we can initialize the drag and drop 
    if ( placeholdersCount === 0 ) {

      this.initializeDragAndDrop();
    }
  }

  updateIndicator(data){
    const indicator = data.indicators.find(indicator => indicator.indicator_id === this.element.dataset.indicatorId)
    if (!indicator) return;

    this.updatePanel(indicator.component)
    this.prepareIndicator(indicator.indicator_id)
  }

  drawAChart(chart) {
    // Skip this logic if the graph dataset is not present, this means that the component is not a chart
    if (!chart.dataset.graph) return;

    const graphData = JSON.parse(chart.dataset.graph);
    if (chart.closest('.origin-chart_crea')){
      if (graphData.options?.plugins?.legend){
        graphData['options']['plugins']['legend']['display'] = false;
      }
      if (graphData.options?.scales?.y?.title){
        graphData['options']['scales']['y']['title']['display'] = false;
      }
      if (graphData.options?.scales?.x?.title){
        graphData['options']['scales']['x']['title']['display'] = false;
      }
      graphData['options']['animation'] = false;
    }
    const newChart = new ChartJs(chart, graphData);
     // Static chart for charts in the charts gallery modal
    if (chart.closest('.origin-chart_crea')) {
      newChart.drawStatic();
      // Static chart for sortable charts in edit view
    } else {
      // Default Dynamic chart for charts in dashboard show view
      newChart.drawChart();
    }

    return newChart
  }
}
