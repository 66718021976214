import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';

export default class extends Controller {
  static targets = ['microMap', 'microTable', 'macroMap', 'macroTable', 'zoomBtnWrapper', 'screenSizeBtn', 'validateSwitch']

  // Event handlers
  switchTableMapHandler = (event) => this.switchView(event.detail.state);
  switchMacroMicroHandler = (event) => this.switchScale(event.detail.state);
  toggleScreenSizeHandler = () => this.toggleMapSize();
  filterDataUpdatedHandler = (event) => this.updateFrontAfterFiltering();
  reduceScreenSizeHandler = (event) => this.reduceMapSize();

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    window.addEventListener('switch-table-map', this.switchTableMapHandler)
    window.addEventListener('switch-macro-micro', this.switchMacroMicroHandler)
    window.addEventListener('toggle-screen-size', this.toggleScreenSizeHandler)
    window.addEventListener('reduce-screen-size', this.reduceScreenSizeHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)

    this.toggleVisibilityValidationSwitch()
  }

  disconnect(){
    window.removeEventListener('switch-table-map', this.switchTableMapHandler)
    window.removeEventListener('switch-macro-micro', this.switchMacroMicroHandler)
    window.removeEventListener('toggle-screen-size', this.toggleScreenSizeHandler)
    window.removeEventListener('reduce-screen-size', this.reduceScreenSizeHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  switchView(state){
    if (state === 'map'){
      if (this.pageState().scale === 'micro'){
        this.microTableTarget.classList.add('hidden')
        this.microMapTarget.classList.remove('hidden')
      } else {
        this.macroTableTarget.classList.add('hidden')
        this.macroMapTarget.classList.remove('hidden')
      }
      this.zoomBtnWrapperTarget.classList.remove('hidden')
    } else {
      if (this.pageState().scale === 'micro'){
        this.microTableTarget.classList.remove('hidden')
        this.microMapTarget.classList.add('hidden')
      } else {
        this.macroTableTarget.classList.remove('hidden')
        this.macroMapTarget.classList.add('hidden')
      }
      this.zoomBtnWrapperTarget.classList.add('hidden')
    }

    // Events
    const stateEvent = new CustomEvent("state-updated", { detail: { display: state } });
    window.dispatchEvent(stateEvent);
  }

  switchValidated(){
    const stateEvent = new CustomEvent("state-updated", { detail: { validated_only: event.currentTarget.checked } });
    window.dispatchEvent(stateEvent);
    const validationEvent = new CustomEvent("validated-only-switch-toggled", { detail: { validated_only: event.currentTarget.checked } });
    window.dispatchEvent(validationEvent);
  }

  // Triggered after a filtering
  // If a search was made, we need to show the right panel
  // Thus reduce the map size (if in big screen)
  updateFrontAfterFiltering(){
    if (!this.pageState().search_panel || this.pageState().screen === 'small') return;

    this.toggleMapSize()
  }

  reduceMapSize(){
    if (this.pageState().screen === 'small')  return;
    
    this.toggleMapSize()
  }

  toggleMapSize(){
    const button = this.screenSizeBtnTarget;
    const oldSize = button.dataset.graphSize;
    const newSize = (oldSize === 'big') ? 'small' : 'big';

    // Update button status
    button.classList.toggle("fa-chevron-right");
    button.classList.toggle("fa-chevron-left");
    button.dataset.graphSize = newSize;
    const testDataset = (newSize === 'big') ? 'decrease-graph-size' : 'increase-graph-size'
    button.dataset.test = testDataset

    // Events
    const stateEvent = new CustomEvent("state-updated", { detail: { screen: newSize } });
    window.dispatchEvent(stateEvent);
    const fullScreenEvent = new CustomEvent("screen-size-toggled", { detail: { screen: newSize } });
    window.dispatchEvent(fullScreenEvent);
  }

  switchScale(scale){
    // Update page state
    const stateEvent = new CustomEvent("state-updated", { detail: { mode: 'topics', scale: scale, selected_ids: [], right_panel: null } });
    window.dispatchEvent(stateEvent);

    const pageState = JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/change_scale`
    const body = { page_state: pageState }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Hide right panel if present
      const topicPanelEvent = new CustomEvent("topic-panel-updated");
      window.dispatchEvent(topicPanelEvent);

      // Toggle visibility of validation switch
      this.toggleVisibilityValidationSwitch()

      // Hide tabs (macro mode)
      const tabEvent = new CustomEvent("toggle-tabs");
      window.dispatchEvent(tabEvent);

      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Overview display
      const overviewEvent = new CustomEvent("overview-updated", { detail: { data: data } });
      window.dispatchEvent(overviewEvent);
    });
  }

  toggleVisibilityValidationSwitch(){
    if (this.pageState().scale === 'micro'){
      this.validateSwitchTarget.classList.remove('hidden')
    } else {
      this.validateSwitchTarget.classList.add('hidden')
    }
  }

  zoomIn(){
    const zoomEvent = new CustomEvent("zoom-in");
    window.dispatchEvent(zoomEvent);
  }

  zoomOut(){
    const zoomEvent = new CustomEvent("zoom-out");
    window.dispatchEvent(zoomEvent);
  }
}
