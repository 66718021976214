import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {

  connect(){
    initializeSelect({
      selector: this.element,
      config: JSON.parse(this.element.dataset.config),
      otherSettings: {
        items: JSON.parse(this.element.dataset.items),
        options: JSON.parse(this.element.dataset.options),
        optgroups: JSON.parse(this.element.dataset.optgroups)
      }
    });
  }
}
