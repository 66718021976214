import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['leftPanel', 'rightPanel']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  screenSizeToggledHandler = (event) => this.changeLayout(event.detail.screen)

  connect(){
    this.changeLayout(this.pageState().screen)

    window.addEventListener('screen-size-toggled', this.screenSizeToggledHandler)
  }

  disconnect(){
    window.removeEventListener('screen-size-toggled', this.screenSizeToggledHandler)
  }

  changeLayout(screenSize){
    if (screenSize === 'big'){
      this.element.style.gridTemplateColumns = '100% 0%'
      this.rightPanelTarget.classList.add('overflow-x-hidden')
      setTimeout(() => {
        this.rightPanelTarget.classList.add('hidden')
      }, 500) // Transition is set to 0.5s, so we wait 0.5s before hidden panels 
    } else {
      this.element.style.gridTemplateColumns = '60% 40%'
      this.rightPanelTarget.classList.remove('hidden')
      this.rightPanelTarget.classList.remove('overflow-x-hidden')
    }
  }
}
