import { Controller } from "@hotwired/stimulus"
import ChartJs from '../../../components/initialize_graph';

export default class extends Controller {
  static targets = ['chart']

  screenSizeToggledHandler = () => this.initializeChart()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    window.addEventListener('screen-size-toggled', this.screenSizeToggledHandler)

    // Initialize Chart
    this.initializeChart()
  }

  disconnect(){
    window.removeEventListener('screen-size-toggled', this.screenSizeToggledHandler)
  }

  initializeChart(){  
    if (this.pageState().screen === 'big') return;

    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    const historyChart = new ChartJs(this.chartTarget, graphData);
    historyChart.drawChart();
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);
  }
}
