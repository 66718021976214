import { Controller } from "@hotwired/stimulus"
import { projectName, removeContent } from '../../../components/utilities';

export default class extends Controller {
  static targets = ['ticketsPanel', 'agentMetricsBtns', 'agentEvolution']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  closePanel(){
    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: null } });
    window.dispatchEvent(stateEvent);
    
    // Reset page
    const resetEvent = new CustomEvent("agent-panel-closed");
    window.dispatchEvent(resetEvent);
  }

  toggleTicketsPanel(){
    this.agentMetricsBtnsTarget.classList.toggle('hidden')
    this.agentEvolutionTarget.classList.toggle('hidden')
    this.ticketsPanelTarget.classList.toggle('hidden')
    event.currentTarget.classList.toggle('display-btn')
    event.currentTarget.classList.toggle('display-btn-selected')

    const stateEvent = new CustomEvent("state-updated", { detail: { tickets_visible: !this.pageState().tickets_visible } });
    window.dispatchEvent(stateEvent);
  }
}
