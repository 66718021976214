import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['cobbai', 'section', 'button', 'buttonTitle'];

  connect(){
  }

  toggleSidebar(){
    this.element.classList.toggle('sidebar-reduced')
    this.element.classList.toggle('sidebar-extended')
    event.currentTarget.classList.toggle('rotate-180')
  }

  showTitles(){
    if (this.element.offsetWidth === 56) return

    this.sectionTargets.forEach(section => {
      section.classList.remove('hidden')
      section.classList.replace('opacity-0', 'opacity-100')
    })
    this.buttonTitleTargets.forEach(title => {
      title.classList.remove('hidden')
      title.classList.replace('opacity-0', 'opacity-100')
    })

    this.cobbaiTarget.classList.remove('hidden')

    // Deactivate tooltips
    this.buttonTargets.forEach(button => button.setAttribute('disabled', ''))
  }

  hideTitles(){
    if (this.element.offsetWidth === 300) return

    this.sectionTargets.forEach(section => {
      section.classList.add('hidden')
      section.classList.replace('opacity-100', 'opacity-0')
    })
    this.buttonTitleTargets.forEach(title => {
      title.classList.add('hidden')
      title.classList.replace('opacity-100', 'opacity-0')
    })

    this.cobbaiTarget.classList.add('hidden')

    // Activate tooltips
    this.buttonTargets.forEach(button => button.removeAttribute('disabled'))
  }
}
