import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  changeStep(){
    const [_data, _status, xhr] = event.detail;
    const data = JSON.parse(xhr.response)

    if (data.progress_component){
      this.formTarget.insertAdjacentHTML('afterend', data.progress_component)
      this.formTarget.remove();
    }
  }
}