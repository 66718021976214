import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["newProject"];

    connect(){
    }

    renameProject(){
      const body = { name: this.newProjectTarget.value }
      const url = `${window.location.origin}/projects/${event.currentTarget.dataset.projectId}`;
      const verb = "PATCH";
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
          .content.value;
      fetch(url, {
          method: verb,
          headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin",
          body: JSON.stringify(body)
        })
      .then(response => response.json())
      .then(data => {
        window.location.href = data["url"];
      });
    }

    securityCheck() {
      const input = document.querySelector('#security_check');
      const submit = document.querySelector('#submit');

      if (input.value === input.dataset.projectName){
        submit.classList.remove('btn-disabled');
      } else {
        submit.classList.add('btn-disabled');
      }
    }

    deleteProject() {
      const allowedAction = event.currentTarget.dataset.allowed === "true";
      if (!allowedAction) return;

      const url = `${window.location.origin}/projects/${event.currentTarget.dataset.projectId}`;
      const verb = "DELETE";
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
          .content.value;
      fetch(url, {
          method: verb,
          headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          },
          credentials: "same-origin"
        })
      .then(response => response.json())
      .then(data => {
        window.location.href = data["url"];
      });
    }

}
