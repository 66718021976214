import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';
import initializeSelect from "../../components/initialize_select";

export default class extends Controller {
  static targets = ['tpl', 'criteriaWrapper']

  connect(){}

  addCriterion(){
    this.criteriaWrapperTarget.insertAdjacentHTML('beforeend', this.tplTarget.innerHTML)
  }

  initializeValues(){
    let [table, column] = event.currentTarget.value.split('.')
    const select = event.currentTarget.closest('[data-row]').querySelector('[data-values]');
    select.classList.remove('hidden')

    const tomInstance = select.tomselect
    if (tomInstance) tomInstance.destroy()
    
    // Initialize value Select
    const config = JSON.parse(select.dataset.config)

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/items/search`;

    config.ajax_params = {
      url: url,
      input: { filter_type: column }
    }

    initializeSelect({
      selector: select,
      config: config
    });
  }

  removeCriterion(){
    event.currentTarget.closest('[data-row]').remove()
    
    // Update parameters
    const updateParametersEvent = new CustomEvent("parameters-updated");
    window.dispatchEvent(updateParametersEvent);
  }


}