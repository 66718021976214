import { Controller } from "@hotwired/stimulus"
import initializeTooltips from '../../../../../components/app_tooltips';
import { projectName } from '../../../../../components/utilities';

export default class extends Controller {
  static targets = ['tag', 'dropdown']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Events Handlers
  resetTagsHandler = (event) => this.resetTags(event.detail.excluded_tag);
  macroTopicTagsUpdatedHandler = (event) => this.updateMacroTopicTags(event.detail.data);

  connect(){
    initializeTooltips(this.element)
    // Events
    window.addEventListener('reset-tags', this.resetTagsHandler)
    window.addEventListener('macro-topic-tags-updated', this.macroTopicTagsUpdatedHandler)
  }

  disconnect(){
    // Events
    window.removeEventListener('reset-tags', this.resetTagsHandler)
    window.removeEventListener('macro-topic-tags-updated', this.macroTopicTagsUpdatedHandler)
  }

  highlightMacroTopic(){
    this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
    event.currentTarget.classList.remove('opacity-25')

    const mouseenterEvent = new CustomEvent("tag-macro-topic-mouseover", { detail: { selected_macro_cluster_id: event.currentTarget.dataset.macroClusterId } });
    window.dispatchEvent(mouseenterEvent);
  }

  unhighlightMacroTopic(){
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
    if (this.pageState().selected_macro_cluster_id){
      this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
      this.tagTargets.find(tag => tag.dataset.macroClusterId === this.pageState().selected_macro_cluster_id).classList.remove('opacity-25')
    }

    const mouseoutEvent = new CustomEvent("tag-macro-topic-mouseout");
    window.dispatchEvent(mouseoutEvent);
  }

  selectMacroTopic(){
    let stateEvent;

    // If we click on the edit button or the edit dropdown, we do not consider the tag selected
    if (event.target.tagName === 'I' || (this.hasDropdownTarget && this.dropdownTarget.contains(event.target))) return;

    if (this.pageState().selected_macro_cluster_id === event.currentTarget.dataset.macroClusterId){
      stateEvent = new CustomEvent("state-updated", { detail: { selected_macro_cluster_id: null } });
    } else {
      stateEvent = new CustomEvent("state-updated", { detail: { selected_macro_cluster_id: event.currentTarget.dataset.macroClusterId } });

      const resetTagEvent = new CustomEvent("reset-tags", { detail: { excluded_tag: 'macro_topic' }});
      window.dispatchEvent(resetTagEvent);
    }

    window.dispatchEvent(stateEvent);
  }

  resetTags(excludedTag){
    if (excludedTag === 'macro_topic') return

    const stateEvent = new CustomEvent("state-updated", { detail: { selected_macro_cluster_id: null } });
    window.dispatchEvent(stateEvent);
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
  }

  createMacroCluster(){
    event.preventDefault()

    // Show loader and disable submit button
    event.currentTarget.classList.add('btn-disabled')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    let formData = new FormData(event.currentTarget.form)
    let body = {}

    formData.forEach((value, key) => {
      if (['authenticity_token', '_method'].includes(key)) return;
      body[key] = value
    })

    body['page_state'] = this.pageState()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
		fetch(event.currentTarget.form.action, {
		    method: 'POST',
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      // Update Macro List
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::Overview::MacroTopicListComponent'])
      this.element.remove();

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		});
  }

  updateMacroCluster(){
    event.preventDefault()

    // Show loader and disable submit button
    event.currentTarget.classList.add('btn-disabled')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    let formData = new FormData(event.currentTarget.form)
    let body = {}

    formData.forEach((value, key) => {
      if (['authenticity_token', '_method'].includes(key)) return;
      body[key] = value
    })

    body['page_state'] = this.pageState()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
		fetch(event.currentTarget.form.action, {
		    method: 'PUT',
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Update Macro List
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::Overview::MacroTopicListComponent'])
      this.element.remove();

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		});
  }

  deleteMacroCluster(){
    // Show loader and disable submit button
    event.currentTarget.classList.add('btn-disabled')
    event.currentTarget.querySelector('[data-icon]').classList.add('hidden')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    let body = {
      page_state: this.pageState()
    }

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/macro_clusters/${event.currentTarget.dataset.macroClusterId}`

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
		fetch(url, {
		    method: 'DELETE',
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Update Macro List
      this.updateMacroTopicTags(data)

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		});
  }

  updateMacroTopicTags(data){
    if (!Object.keys(data).includes('Topics::Topics::Micro::Overview::MacroTopicListComponent')) return

    this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::Overview::MacroTopicListComponent'])
    this.element.remove();
  }
}
