import { Controller } from "@hotwired/stimulus"
import { projectName, removeContent } from "../components/utilities"

export default class extends Controller {
  static targets = ['bar', 'percentage', 'step', 'error', 'endContainer'];

  connect(){
    document.addEventListener('progress-bar-updated', () => {
      this.update()
    })
  }

  // Triggered by the custom-event "progress-bar-updated" dispatch by cable ready
  update(){
    if (this.element.dataset.uid !== event.detail.uid) return;

    // Bar
    this.barTarget.style.width = `${event.detail.percentage}%`
    if (['error', 'error_no_item', 'error_limit'].includes(event.detail.state)){
      this.barTarget.classList.remove('bg-green-500')
      this.barTarget.classList.add('bg-orange-100')
    }

    // Percentage
    this.percentageTarget.innerHTML = `${event.detail.percentage}%`

    // Steps
    const step = this.stepTargets.find(step => step.dataset.id === event.detail.currentStep)
    if (step){
      step.querySelector('div').classList.remove('text-gray-500')
      if (['error', 'error_no_item', 'error_limit'].includes(event.detail.state)){
        step.querySelector('i').classList.remove('fa-check', 'text-gray-400')
        step.querySelector('i').classList.add('fa-xmark', 'text-orange-100')
      } else {
        step.querySelector('i').classList.remove('text-gray-400')
        step.querySelector('i').classList.add('text-green-500')
      }
    }

    // Error messages
    const errorMsg = this.errorTargets.find(msg => msg.dataset.state === event.detail.state)
    if (errorMsg) {
      this.errorTargets.forEach(msg => msg.classList.add('hidden'))
      errorMsg.classList.remove('hidden')
    }

    // End process
    if (['success', 'error', 'error_no_item', 'error_limit'].includes(event.detail.state) ) this.getEndContainer(event)
  }

  getEndContainer(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/connectors/connectors/end_process?uid=${event.detail.uid}`
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin"
    })
    .then(response => response.json())
    .then(data => {
      removeContent(this.endContainerTarget);
      this.endContainerTarget.insertAdjacentHTML('beforeend', data['content'])
    });
  }

  disconnect () {
    document.removeEventListener('progress-bar-updated', () => {
      this.update()
    })
  }
}
