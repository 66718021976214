import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['select', 'remainder']

  // Event handlers
  launchComparisonHandler = (event) => this.initializeComparison()

  connect(){
    window.addEventListener('launch-comparison', this.launchComparisonHandler)
  }

  disconnect(){
    window.removeEventListener('launch-comparison', this.launchComparisonHandler)
  }

  initializeComparison(){
    this.perimSelect = this.selectTarget.tomselect
    this.compare();
  }

  compare(){
    // Remove former period comparison
    const filterBar = document.querySelector('[data-controller="filter-bar"]')
    let filters = JSON.parse(filterBar.dataset.filters)
    filters = filters.filter(el => el['operator'] !== 'perimeter')

    // Prepare new period comparison
    const perimeterComparison = {
      table_id: '',
      operator: 'perimeter',
      values: this.perimSelect.getValue(),
      remainder: this.remainderTarget.classList.contains('display-btn-selected'),
      num_categories: 10
    }

    // Save the new filters
    filters.push(perimeterComparison)
    filterBar.dataset.filters = JSON.stringify(filters)

    const filterEvent = new CustomEvent("filters-updated", { detail: { filters: filters } });
    window.dispatchEvent(filterEvent);
  }

  toggleRemainder(){
    this.remainderTarget.classList.toggle('display-btn')
    this.remainderTarget.classList.toggle('display-btn-selected')

    this.compare()
  }

  populateFilters(filter){
    const tomPeriodSelect = this.selectTarget.tomselect;

    tomPeriodSelect.addItem(filter['values']);

    if (filter['remainder']){
      this.remainderTarget.classList.add('display-btn-selected')
      this.remainderTarget.classList.remove('display-btn')
    } else {
      this.remainderTarget.classList.add('display-btn')
      this.remainderTarget.classList.remove('display-btn-selected')
    }
  }
}