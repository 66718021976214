import { Controller } from "@hotwired/stimulus"
import ChartJs from '../../../../../components/initialize_graph';
import { projectName } from '../../../../../components/utilities';

export default class extends Controller {
  static targets = ['chart']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    if (!this.hasChartTarget) return // Means that there is not data with the selected filters

    // Initialize Chart
    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    const historyChart = new ChartJs(this.chartTarget, graphData);
    historyChart.drawChart();
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);
  }

  updateName(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/macro_clusters/${this.element.dataset.id}/update_name`
    const body = { name: event.currentTarget.value, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Overview display
      const overviewEvent = new CustomEvent('overview-updated', { detail: { data: data } });
      window.dispatchEvent(overviewEvent);
      
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }
}
