import { Controller } from "@hotwired/stimulus"
import ChartJs from '../../../../../components/initialize_graph';

export default class extends Controller {
  static targets = ['chart']

  connect(){
    if (!this.hasChartTarget) return // Means that there is not data with the selected filters
    
    // Initialize Chart
    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    const historyChart = new ChartJs(this.chartTarget, graphData);
    historyChart.drawChart();
  }
}
