import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';
import { projectName } from "../../components/utilities"

export default class extends Controller {
  static targets = ['modal', 'automationCard', 'addBtn', 'connector', 'addAutomationBtn', 'emptyConnector', 'startConnector', 'endConnector']

  // Events Handlers
  automationAddedHandler = (event) => this.insertAutomation(event.detail);

  connect(){
    window.addEventListener('automation-added', this.automationAddedHandler)
  }

  disconnect(){
    window.removeEventListener('automation-added', this.automationAddedHandler)
  }

  openModal(){
    this.modalTarget.dataset.position = event.currentTarget.dataset.position
    this.modalId = event.currentTarget.dataset.id;
    MicroModal.show(this.modalId);
  }

  insertAutomation(data){
    if (data.mode === 'start'){
      this.insertStartAutomation(data)
    } else if (data.mode === 'between'){
      this.insertBetweenAutomation(data)
    } else if (data.mode === 'end'){
      this.insertEndAutomation(data)
    }

    // Close the modal
    MicroModal.close(this.modalId)
  }

  insertStartAutomation(data){
    this.emptyConnectorTarget.insertAdjacentHTML('afterend', data.connector_end)
    this.emptyConnectorTarget.insertAdjacentHTML('afterend', data.automation_card)
    this.emptyConnectorTarget.insertAdjacentHTML('afterend', data.connector_start)
    this.emptyConnectorTarget.remove()
    this.addAutomationBtnTarget.remove()
  }

  insertBetweenAutomation(data){
    const connector = this.addBtnTargets.find(btn => parseInt(btn.dataset.position, 10) === data.position).closest('[data-connector]')
    // Update positions
    this.incrementCardPosition(data.position)
    this.incrementConnectorPosition(data.position)
    // Insert new automation card
    connector.insertAdjacentHTML('afterend', data.connector_between)
    connector.insertAdjacentHTML('afterend', data.automation_card)
    this.showAutomationCardParameters(data)
  }

  insertEndAutomation(data){
    const connector = this.addBtnTargets.find(btn => parseInt(btn.dataset.position, 10) === data.position).closest('[data-connector]')
    // Insert new automation card
    connector.insertAdjacentHTML('afterend', data.connector_end)
    connector.insertAdjacentHTML('afterend', data.automation_card)
    connector.insertAdjacentHTML('afterend', data.connector_between)
    this.showAutomationCardParameters(data)

    connector.remove()
  }

  // Show the parameters of the automation that was just added
  showAutomationCardParameters(data){
    setTimeout(() => {
      const automationCardEvent = new CustomEvent("toggle-automation-parameters", { detail: { id: data.id } });
      window.dispatchEvent(automationCardEvent);
    }, 100)
  }

  removeAutomation(){
    const destroyConfirmation = confirm(event.currentTarget.dataset.confirm)
    if (!destroyConfirmation) return;

    const automationId = event.currentTarget.dataset.automationId

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/automations/${automationId}`

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin"
      })
    .then(response => response.json())
    .then(data => {
      if (data.mode === 'empty'){
        // Add after end connector the add automation button
        this.endConnectorTarget.insertAdjacentHTML('afterend', data.add_btn)
        this.endConnectorTarget.insertAdjacentHTML('afterend', data.connector_empty)
        // Remove the automation + connectors
        const card = this.automationCardTargets.find(card => parseInt(card.dataset.position) === data.position)
        card.remove()
        this.startConnectorTarget.remove()
        this.endConnectorTarget.remove()
      } else if (data.mode === 'start'){
        // Remove the automation + connectors
        const card = this.automationCardTargets.find(card => parseInt(card.dataset.position) === data.position)
        card.remove()
        const nextConnector = this.addBtnTargets.find(btn => parseInt(btn.dataset.position) === data.position + 1).closest('[data-connector]')
        nextConnector.remove()
        // Decrement the position of the next card and connectors
        this.decrementCardPosition(data.position)
        this.decrementConnectorPosition(data.position)
      } else if (data.mode === 'between'){
        // Remove the automation + connectors
        const card = this.automationCardTargets.find(card => parseInt(card.dataset.position) === data.position)
        card.remove()
        const formerConnector = this.addBtnTargets.find(btn => parseInt(btn.dataset.position) === data.position).closest('[data-connector]')
        formerConnector.remove()
        // Decrement the position of the next card and connectors
        this.decrementCardPosition(data.position)
        this.decrementConnectorPosition(data.position)
      }
    });
  }

  incrementCardPosition(position){
    this.automationCardTargets.forEach(card => {
      const currentPosition = parseInt(card.dataset.position, 10)
      if (currentPosition < position) return
      card.dataset.position = currentPosition + 1
    })
  }

  incrementConnectorPosition(position){
    this.addBtnTargets.filter(btn => {
      const currentPosition = parseInt(btn.dataset.position, 10)
      if (currentPosition < position + 1) return
      btn.dataset.position = currentPosition + 1
    })
  }

  decrementCardPosition(position){
    this.automationCardTargets.forEach(card => {
      const currentPosition = parseInt(card.dataset.position, 10)
      if (currentPosition > position) card.dataset.position = currentPosition - 1
    })
  }

  decrementConnectorPosition(position){
    this.addBtnTargets.filter(btn => {
      const currentPosition = parseInt(btn.dataset.position, 10)
      if (currentPosition > position) btn.dataset.position = currentPosition - 1
    })
  }
}

