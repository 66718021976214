import { Controller } from "@hotwired/stimulus"
import ChartJs from '../components/initialize_graph';

export default class extends Controller {
  static targets = ["chart"]

  connect(){
    this.drawCharts()
    this.listenForUpdates()
  }

  // Listen to data-graph attribute and update graphs when new data are available
  listenForUpdates(){
    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

    // Options for the observer (which mutations to observe)
    var config = {attributes: true};

    // Callback function to execute when mutations are observed
    var redrawChart = (mutationList, observer) => {
      if (mutationList[0].type == 'attributes' && mutationList[0]["attributeName"] == "data-results"){
        const results = JSON.parse(document.querySelector('body').dataset.results)

        this.element.innerHTML = results['automation_dashboard']
        this.drawCharts()
      }
    }

    // Create an observer instance linked to the callback function
    var observer = new MutationObserver(redrawChart);

    // Start observing the target node for configured mutations
    const body = document.querySelector('body')
    observer.observe(body, config);
  }

  drawCharts(){
    this.chartTargets.forEach(chart => {
      this.drawChart(chart);
    })
  }

  drawChart(chart) {
    const graphData = JSON.parse(chart.dataset.graph);
    const newChart = new ChartJs(chart, graphData);
    newChart.drawChart();

    return newChart
  }
}
