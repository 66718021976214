import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../../components/utilities';

export default class extends Controller {
  static targets = ['chart', 'list'];

  // Event handlers
  timeFrameUpdatedHandler = (event) => this.initializePanel()
  automationUpdatedHandler = (event) => this.initializePanel()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    window.addEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
    window.addEventListener('automation-updated', this.automationUpdatedHandler)

    this.initializePanel()
  }

  disconnect() {
    window.removeEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
    window.removeEventListener('automation-updated', this.automationUpdatedHandler)
  }

  initializePanel(){
    if (this.pageState().time_frame === 'all'){
      this.element.classList.add('hidden')
      return;
    }

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/results_panel`;

    const body = { type: this.element.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.element)
      this.element.insertAdjacentHTML('afterbegin', data["Automations::RecommendationsComponent"] );
      this.element.classList.remove('hidden')
    })
  }

  updateRecommendations(){
    const updateConfirmation = confirm(event.currentTarget.dataset.confirm)
    if (!updateConfirmation) return;

    const button = event.currentTarget;
    const originIcon = button.querySelector('[data-icon]');
    const loadIcon = button.querySelector('[data-loading]');

    // Manage button state
    originIcon.classList.add('hidden');
    loadIcon.classList.remove('hidden');
    button.classList.add('btn-disabled');

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/automations/update_recommendations`;

    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Manage button state
      originIcon.classList.remove('hidden');
      loadIcon.classList.add('hidden');
      button.classList.remove('btn-disabled');

      if (data["recommendations"]){
        removeContent(this.listTarget)
        this.listTarget.insertAdjacentHTML('afterbegin', data["recommendations"] );        
      }

      if (data['flash']){
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
      }
    })
  }
}