import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';
import initializeTooltips from '../../components/app_tooltips';
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = ['name', 'changeNameInput']

  showNameInput(){
    this.closeDropdown()
    this.cancelUpdateName()

    const name = this.nameTargets.find(name => name.dataset.workflowId === event.currentTarget.dataset.workflowId)
    const inputWrapper = this.changeNameInputTargets.find(wrapper => wrapper.dataset.workflowId === event.currentTarget.dataset.workflowId)

    name.classList.add('hidden')
    inputWrapper.classList.remove('hidden')
  }

  closeDropdown(){
    // Remove any dropdowns if loaded on the page before opening the modal
    const dropdown = document.querySelector('[data-tippy-root]');
    if (dropdown) dropdown.remove();
  }

  cancelUpdateName(){
    this.nameTargets.forEach(name => name.classList.remove('hidden'))
    this.changeNameInputTargets.forEach(inputWrapper => inputWrapper.classList.add('hidden'))
  }

  updateName(){
    if (!(event.key === 'Enter' || event.type === "click")) return
    
    const input = this.changeNameInputTargets.find(wrapper => wrapper.dataset.workflowId === event.currentTarget.dataset.workflowId).querySelector('input')
    const workflowId = input.dataset.workflowId
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/${workflowId}/update_name`
    const body = { name: input.value, selected_workflow_id: this.element.dataset.selectedWorkflowId }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.element.innerHTML = data.updated_list
      initializeTooltips(this.element)

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data.flash);
    });
  }

  updateState(){
    const workflowId = event.currentTarget.dataset.workflowId
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/${workflowId}/update_state`
    const body = { selected_workflow_id: this.element.dataset.selectedWorkflowId }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.element.innerHTML = data.updated_list
      initializeTooltips(this.element)

      if (data.show_lock_modal){
        MicroModal.show('modal_lock_workflow');
      } else {
        // Display flash message
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data.flash);
      }
    });
  }
}