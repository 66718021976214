import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon";

export default class extends Controller {
  connect(){
    const utcStringDate = this.element.dataset.date
    let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let utcDate = DateTime.fromFormat(utcStringDate, "yyyy-MM-dd HH:mm:ss 'UTC'", { zone: 'utc' })
    let userTime = utcDate.setZone(userTimeZone);
    this.element.innerText = userTime.toLocaleString(DateTime.DATETIME_MED)
  }
}