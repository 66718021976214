import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tag']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Events Handlers
  resetTagsHandler = (event) => this.resetTags(event.detail.excluded_tag);
  workflowsUpdatedHandler = (event) => this.updateWorkflows(event.detail.data);

  connect(){
    // Events
    window.addEventListener('reset-tags', this.resetTagsHandler)
    window.addEventListener('workflows-updated', this.workflowsUpdatedHandler)
  }

  disconnect(){
    // Events
    window.removeEventListener('reset-tags', this.resetTagsHandler)
    window.removeEventListener('workflows-updated', this.workflowsUpdatedHandler)
  }

  highlightWorkflow(){
    this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
    event.currentTarget.classList.remove('opacity-25')

    const mouseenterEvent = new CustomEvent("workflow-mouseover", { detail: { cluster_ids: JSON.parse(event.currentTarget.dataset.clusterIds) } });
    window.dispatchEvent(mouseenterEvent);
  }

  unhighlightWorkflow(){
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
    if (this.pageState().selected_workflow){
      this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
      this.tagTargets.find(tag => tag.dataset.workflowId === this.pageState().selected_workflow.id).classList.remove('opacity-25')
    }

    const mouseoutEvent = new CustomEvent("workflow-mouseout");
    window.dispatchEvent(mouseoutEvent);
  }

  selectWorkflow(){
    let stateEvent;

    if (this.pageState().selected_workflow?.id === event.currentTarget.dataset.workflowId){
      stateEvent = new CustomEvent("state-updated", { detail: { selected_workflow: null } });
    } else {
      stateEvent = new CustomEvent("state-updated", { detail: { selected_workflow: {
        id: event.currentTarget.dataset.workflowId,
        cluster_ids: JSON.parse(event.currentTarget.dataset.clusterIds)
      } } });

      const resetTagEvent = new CustomEvent("reset-tags", { detail: { excluded_tag: 'workflow' }});
      window.dispatchEvent(resetTagEvent);
    }

    window.dispatchEvent(stateEvent);
  }

  resetTags(excludedTag){
    if (excludedTag === 'workflow') return

    const stateEvent = new CustomEvent("state-updated", { detail: { selected_workflow: null } });
    window.dispatchEvent(stateEvent);
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
  }

  updateWorkflows(data){
    if (!Object.keys(data).includes('Topics::Workflows::Overview::WorkflowListComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['Topics::Workflows::Overview::WorkflowListComponent'])
    this.element.remove();
  }
}