import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button']

  changeState(){
    const state = event.currentTarget.dataset.state;

    if (event.currentTarget.classList.contains('dual-button-selected')) return; // means the button is already selected

    this.buttonTargets.forEach(btn => {
        btn.classList.toggle('dual-button-selected')
        btn.classList.toggle('dual-button')
    })

    const customEvent = new CustomEvent(this.element.dataset.event, { detail: { state: state }});
    window.dispatchEvent(customEvent);
  }
}