import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";
import { projectName } from "../components/utilities";

export default class extends Controller {
  static targets = ['select', 'settings'];

  // Event handlers
  allIndicatorsRemovedHandler = (event) => this.dashboardEdit();

  connect(){
    window.addEventListener('all-indicators-removed', this.allIndicatorsRemovedHandler)

    this.initializeSelect();
  }

  disconnect(){
    window.removeEventListener('all-indicators-removed', this.allIndicatorsRemovedHandler)
  }

  initializeSelect(){
    let tomInstance;

    // Operator
    tomInstance = this.selectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    initializeSelect({
      selector: this.selectTarget,
      config: JSON.parse(this.selectTarget.dataset.config),
      otherSettings: {
        options: JSON.parse(this.selectTarget.dataset.options),
        items: JSON.parse(this.selectTarget.dataset.items),
        render: {
          option: function(data, escape) {
              return `<div class="flex items-center justify-start gap-2">
                        <div>${escape(data.text)}</div>
                        ${data.public != true ? '<i class="fa-light fa-lock"></i>' : ''}
                      </div>`                       
          },
          item: function(data, escape) {
            return `<div class="flex items-center justify-start gap-2">
                      <div>${escape(data.text)}</div>
                      ${data.public != true ? '<i class="fa-light fa-lock"></i>' : ''}
                    </div>`
          },
        }
      }
    });    
  }

  changeView(){
    // First we check if the selected option is an automation or dashboard
    if(event.currentTarget.value === 'automation'){
      this.automationHistoryIndex();
    }else if(event.currentTarget.value === 'create_new'){
      this.dashboardIndex();
    }else if(this.element.dataset.pageMode !== 'dashboards_edit') {
      this.dashboardShow();
    } else {
      this.dashboardEdit();
    }
  }

  dashboardShow(){
    // Refreshes the show page to include the id of the selected dashboard
    let dashboard_id =  this.selectTarget.value
    window.location = `${window.location.origin}/${projectName(window.location.pathname)}/dashboards/${dashboard_id}`;
  }

  dashboardIndex(){
    window.location = `${window.location.origin}/${projectName(window.location.pathname)}/dashboards`;
  }
 
  automationHistoryIndex(){
    window.location = `${window.location.origin}/${projectName(window.location.pathname)}/automation_histories`;
  }

  dashboardEdit(){
    // Redirrects to the edit page of the dashboard selected
    let dashboard_id =  this.selectTarget.value
    window.location = `${window.location.origin}/${projectName(window.location.pathname)}/dashboards/${dashboard_id}/edit`;
  }
}

