import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";
import { projectName, capitalize } from '../components/utilities';

export default class extends Controller {
  static targets = ['selectDiv', 'selectClusterCategory', 'createDiv', 'nameInput', 'colorInput'];

  connect(){
    this.categoryType = this.element.dataset.category;
    const table = this.categoryType === 'tag' ? 'tags' : 'macro_clusters'
    this.baseUrl = `${window.location.origin}/${projectName(window.location.pathname)}/${table}`
  }

  initializeSelectClusterCategory(){
    const tomInstance = this.selectClusterCategoryTarget.tomselect;
    if (tomInstance !== undefined) return;

    // Styling of the items/options
    let itemClass;
    if (this.selectClusterCategoryTarget.dataset.type === 'tag'){
      itemClass = "flex-shrink-0 w-1 h-5"
    } else {
      itemClass = "flex-shrink-0 w-3 h-3 rounded-full"
    }

    const settings = {
      items: JSON.parse(this.selectClusterCategoryTarget.dataset.items),
      options: JSON.parse(this.selectClusterCategoryTarget.dataset.options),
      placeholder: 'Select category...',
      searchField: 'name',
      labelField: 'name',
      persist: false,
      render: {
        option: (data, escape) => {
          const color = (data.color === undefined) ? '#667085' : data.color

          return `<div class="cursor-pointer flex justify-start items-center gap-1"
                    style="color:${color}">
                    <div class="${itemClass}" style="background-color:${color}"></div>
                    <span class="">${capitalize(escape(data.name))}</span>
                  </div>`
        },
        item: (data, escape) => {
          const color = (data.color === undefined) ? '#667085' : data.color

          return `<div class="cursor-pointer flex justify-start items-center gap-1"
                    style="color:${color}">
                    <div class="${itemClass}" style="background-color:${color}"></div>
                    <span class="">${capitalize(escape(data.name))}</span>
                  </div>`
        },
      },
      onItemAdd: (value, item) => {
        if (this.selectClusterCategoryTarget.hasAttribute('data-select')) return;

        // Check if the value is a string or a num.
        // If num --> The record already exists
        // If not --> the user just created it and we need to add it the category list
        if (isNaN(item.dataset.value)){
          this.selectDivTarget.classList.add('hidden');
          this.createDivTarget.classList.remove('hidden');
          this.nameInputTarget.value = value;
          this.nameInputTarget.focus();

          this.categorySelect.destroy() // Reset select to avoid to add the created item but unformated
        } else {
          this.addCategory(value)
        }
      }
    }

    this.categorySelect = initializeSelect({
      selector: this.selectClusterCategoryTarget,
      config: JSON.parse(this.selectClusterCategoryTarget.dataset.config),
      otherSettings: settings
    });
  }

  getContrastYIQ(hexcolor){
    const color = hexcolor.substring(1)
    var r = parseInt(color.substr(0,2),16);
    var g = parseInt(color.substr(2,2),16);
    var b = parseInt(color.substr(4,2),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    return (yiq >= 145) ? 'black' : 'white';
  }

  openSelect(){
    this.initializeSelectClusterCategory();
    this.categorySelect.focus();
  }

  // Only for tags: look among the index list tags if one is selected
  findSelectedTag(){
    if (this.categoryType !== 'tag') return;

    const selectedTag = Array.from(document.querySelectorAll('[data-insights-target="tag"]'))
                             .filter(tag => tag.dataset.selected === 'on')[0];
    if (selectedTag) return selectedTag.dataset.categoryId;
  }

  createMacroCluster(){
    const url = this.baseUrl;
    const body = {
                   name: this.nameInputTarget.value,
                   color: this.colorInputTarget.value,
                   cluster_id: this.element.dataset.clusterId,
                   selected_tag_id: this.findSelectedTag()
                 }


    this.sendRequest(url, 'POST', body)
  }

  addCategory(categoryId){
    const route = this.categoryType === 'tag' ? 'tags' : 'macro_clusters'
    const url = `${this.baseUrl}/add`;
    const body = {
                   category_id: categoryId,
                   cluster_id: this.element.dataset.clusterId,
                   selected_tag_id: this.findSelectedTag()
                 }

    this.sendRequest(url, 'POST', body)
  }

  removeClusterCategory(){
    const categoryId = event.currentTarget.dataset.clusterCategoryId;
    let url = `${this.baseUrl}/remove`;
    const body = {
                   category_id: categoryId,
                   cluster_id: this.element.dataset.clusterId,
                   selected_tag_id: this.findSelectedTag()
                 }

    this.sendRequest(url, 'POST', body)
  }

  updateClusterCategory(){
    // Find tag info
    const updateForm = event.currentTarget.closest('[data-form]');
    const categoryType = updateForm.dataset.form;
    const categoryId = updateForm.querySelector('input[name="category[id]"]').value;
    const categoryName = updateForm.querySelector('input[name="category[name]"]').value;
    const categoryColor = updateForm.querySelector('input[name="category[color]"]').value;

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/${categoryType}s/${categoryId}`;
    const body = {
      id: categoryId,
      name: categoryName,
      color: categoryColor
    }

    this.sendRequest(url, 'PATCH', body)
  }

  destroyClusterCategory(){
    // Find tag info
    const updateForm = event.currentTarget.closest('[data-form]');
    const categoryType = updateForm.dataset.form;
    const categoryId = updateForm.querySelector('input[name="category[id]"]').value;

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/${categoryType}s/${categoryId}`;
    const body = {}

    this.sendRequest(url, 'DELETE', body)
  }

  sendRequest(url, verb, body){
    if (this.hasSelectDivTarget) this.selectDivTarget.classList.add('hidden')

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    fetch(url, {
        method: verb,
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      const body = document.querySelector('body')
      body.dataset.results = JSON.stringify(data)
    });
  }

}
