import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ['select', 'submitBtn']

  connect(){
    this.enableSubmitBtn()

    this.selectTargets.forEach(select => {
      initializeSelect({
        selector: select,
        multiple: select.multiple,
        addable: false,
        closeAfterSelect: true,
        otherSettings: {
          options: JSON.parse(select.dataset.options),
          items: JSON.parse(select.dataset.items)
        }
      });
    })
  }

  // Enable/Disable dependent inputs
  toggleInputs(){
    const isChecked = event.currentTarget.checked
    const group = event.currentTarget.dataset.targetedInputs;
    const inputs = document.querySelectorAll(`[data-inputs="${group}"]`)

    if (isChecked) {
      inputs.forEach(input => {
        // Check which type of input
        if (['select-one', 'select-multiple'].includes(input.type)) {
          input.tomselect.enable()
        } else {
          input.disabled = false
          if (input.type === 'checkbox') input.closest('.checkbox-label').classList.remove('disabled')
        }
      })
    } else {
      inputs.forEach(input => {
        // Check which type of input
        if (['select-one', 'select-multiple'].includes(input.type)) {
          input.tomselect.disable()
        } else {
          input.disabled = true
          if (input.type === 'checkbox') input.closest('.checkbox-label').classList.add('disabled')
        }
      })
    }
  }

  enableSubmitBtn(){
    // Check that this form is valid
    const inputs = document.querySelectorAll('[data-required-group]')
    const groupIds = Array.from(inputs).map(input => input.dataset.requiredGroup)
                                       .filter((v, i, a) => a.indexOf(v) === i); // Get uniq values

    let formValid = true
    groupIds.forEach(id => {
      const fields = Array.from(document.querySelectorAll(`[data-required-group='${id}']`)).filter(el => el.disabled === false)
      const values = fields.map(el => this.isFieldValid(el))
      if (values.length === 0) return;

      if (values.every(value => value === false)) formValid = false
    })

    const submitBtn = this.submitBtnTarget
    formValid ? submitBtn.classList.remove('btn-disabled') : submitBtn.classList.add('btn-disabled')
  }

  isFieldValid(field) {
    if (field.type === "checkbox") {
      return field.checked;
    } else {
      return field.value.length > 0;
    }
    return false;
}
}
