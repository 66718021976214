import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  openModal(){
    const modalId = event.currentTarget.dataset.id;
    const content = event.currentTarget.dataset.content
    MicroModal.show(modalId);

    // Trigger the event to fetch the content of the modal
    const customEvent = new CustomEvent('fetch-modal-content', { detail: { content: content } });
    window.dispatchEvent(customEvent);
  }

  closeModal(){
    const modalId = event.currentTarget.dataset.modalId;
    MicroModal.close(modalId);
  }
}