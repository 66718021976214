import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tag']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Events Handlers
  resetTagsHandler = (event) => this.resetTags(event.detail.excluded_tag);
  knowledgesUpdatedHandler = (event) => this.updateKnowledges(event.detail.data);

  connect(){
    // Events
    window.addEventListener('reset-tags', this.resetTagsHandler)
    window.addEventListener('knowledges-updated', this.knowledgesUpdatedHandler)
  }

  disconnect(){
    // Events
    window.removeEventListener('reset-tags', this.resetTagsHandler)
    window.removeEventListener('knowledges-updated', this.knowledgesUpdatedHandler)
  }

  highlightKnowledge(){
    this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
    event.currentTarget.classList.remove('opacity-25')

    const mouseenterEvent = new CustomEvent("knowledge-mouseover", { detail: { cluster_ids: JSON.parse(event.currentTarget.dataset.clusterIds) } });
    window.dispatchEvent(mouseenterEvent);
  }

  unhighlightKnowledge(){
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
    if (this.pageState().selected_knowledge){
      this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
      this.tagTargets.find(tag => tag.dataset.knowledgeId === this.pageState().selected_knowledge.id).classList.remove('opacity-25')
    }

    const mouseoutEvent = new CustomEvent("knowledge-mouseout");
    window.dispatchEvent(mouseoutEvent);
  }

  selectKnowledge(){
    let stateEvent;

    if (this.pageState().selected_knowledge?.id === event.currentTarget.dataset.knowledgeId){
      stateEvent = new CustomEvent("state-updated", { detail: { selected_knowledge: null } });
    } else {
      stateEvent = new CustomEvent("state-updated", { detail: { selected_knowledge: {
        id: event.currentTarget.dataset.knowledgeId,
        cluster_ids: JSON.parse(event.currentTarget.dataset.clusterIds)
      } } });

      const resetTagEvent = new CustomEvent("reset-tags", { detail: { excluded_tag: 'knowledge' }});
      window.dispatchEvent(resetTagEvent);
    }

    window.dispatchEvent(stateEvent);
  }

  resetTags(excludedTag){
    if (excludedTag === 'knowledge') return

    const stateEvent = new CustomEvent("state-updated", { detail: { selected_knowledge: null } });
    window.dispatchEvent(stateEvent);
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
  }

  updateKnowledges(data){
    if (!Object.keys(data).includes('Topics::Topics::Micro::Overview::KnowledgeListComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::Overview::KnowledgeListComponent'])
    this.element.remove();
  }
}