import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['leftPanel', 'rightPanel', 'tabPanel']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  tableUpdatedHandler = (event) => this.rebuildTable(event.detail.data);
  macroTopicClickedHandler = (event) => this.highlightRow(event.detail.macro_cluster_id);
  macroTopicUnclickedHandler = (event) => this.unhighlightRows();
  filterDataUpdatedHandler = (event) => this.rebuildTable(event.detail.data);

  connect(){
    // Show table if page_state ok
    if (this.pageState().display === 'table' && this.pageState().scale === 'macro') this.element.classList.remove('hidden')

    window.addEventListener('table-updated', this.tableUpdatedHandler)
    window.addEventListener('macro-topic-clicked', this.macroTopicClickedHandler)
    window.addEventListener('macro-topic-unclicked', this.macroTopicUnclickedHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('table-updated', this.tableUpdatedHandler)
    window.removeEventListener('macro-topic-clicked', this.macroTopicClickedHandler)
    window.removeEventListener('macro-topic-unclicked', this.macroTopicUnclickedHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  rebuildTable(data){
    if (this.pageState().scale !== 'macro' || this.pageState().display !== 'table') {
      this.element.classList.add('hidden')
    }
    
    if (this.pageState().scale !== 'macro') return;
    
    if (!Object.keys(data).includes('Topics::MacroTableComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['Topics::MacroTableComponent'])
    this.element.remove();
  }

  highlightRow(macroClusterId){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
    const selectedRow = this.element.querySelector(`tr[id='macro_clusters-${macroClusterId}']`)
    if (selectedRow === null) return;
    selectedRow.classList.add('bg-gray-200')
  } 
  
  unhighlightRows(){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
  }

  selectRow(){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
    event.currentTarget.closest('tr').classList.add('bg-gray-200')

    const macroClusterId = event.currentTarget.closest('tr').id.replace('macro_clusters-', "");
    const mapEvent = new CustomEvent("macro-topic-row-clicked", { detail: { macro_cluster_id: macroClusterId } });
    window.dispatchEvent(mapEvent);

    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: macroClusterId } });
    window.dispatchEvent(stateEvent);

    const topicPanelEvent = new CustomEvent("topic-panel-updated");
    window.dispatchEvent(topicPanelEvent);

    if (this.pageState().screen === 'small') return;

    // If the right panel is hidden because the graph size is 'big', then dispatch an event that will be captured by the left_panel_controller.js in order to decrease the graph size
    const toggleScreenEvent = new CustomEvent("toggle-screen-size");
    window.dispatchEvent(toggleScreenEvent);
  }
}
