import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['fromSlider', 'toSlider', 'fromInput', 'toInput']

  connect(){
    this.fillSlider(this.fromSliderTarget, this.toSliderTarget, '#D0D5DD', '#344054', this.toSliderTarget);
    this.setToggleAccessible(this.toSliderTarget);
  }

  fromSliderUpdate(){
    this.controlFromSlider(this.fromSliderTarget, this.toSliderTarget, this.fromInputTarget)
    this.fromInputTarget.dispatchEvent(new Event('input'));
  }

  toSliderUpdate(){
    this.controlToSlider(this.fromSliderTarget, this.toSliderTarget, this.toInputTarget)
    this.toInputTarget.dispatchEvent(new Event('input'));
  }

  fromInputUpdate(){
    this.controlFromInput(this.fromSliderTarget, this.fromInputTarget, this.toInputTarget, this.toSliderTarget)
  }

  toInputUpdate(){
    this.controlToInput(this.toSliderTarget, this.fromInputTarget, this.toInputTarget, this.toSliderTarget)
  }

  controlFromInput(fromSlider, fromInput, toInput, controlSlider) {
      const [from, to] = this.getParsed(fromInput, toInput);
      this.fillSlider(fromInput, toInput, '#D0D5DD', '#344054', controlSlider);
      if (from > to) {
          fromSlider.value = to;
          fromInput.value = to;
      } else {
          fromSlider.value = from;
      }
  }

  controlToInput(toSlider, fromInput, toInput, controlSlider) {
      const [from, to] = this.getParsed(fromInput, toInput);
      this.fillSlider(fromInput, toInput, '#D0D5DD', '#344054', controlSlider);
      this.setToggleAccessible(toInput);
      if (from <= to) {
          toSlider.value = to;
          toInput.value = to;
      } else {
          toInput.value = from;
      }
  }

  controlFromSlider(fromSlider, toSlider, fromInput) {
    const [from, to] = this.getParsed(fromSlider, toSlider);
    this.fillSlider(fromSlider, toSlider, '#D0D5DD', '#344054', toSlider);
    if (from > to) {
      fromSlider.value = to;
      fromInput.value = to;
    } else {
      fromInput.value = from;
    }
  }

  controlToSlider(fromSlider, toSlider, toInput) {
    const [from, to] = this.getParsed(fromSlider, toSlider);
    this.fillSlider(fromSlider, toSlider, '#D0D5DD', '#344054', toSlider);
    this.setToggleAccessible(toSlider);
    if (from <= to) {
      toSlider.value = to;
      toInput.value = to;
    } else {
      toInput.value = from;
      toSlider.value = from;
    }
  }

  getParsed(currentFrom, currentTo) {
    const from = parseFloat(currentFrom.value);
    const to = parseFloat(currentTo.value);

    const min = parseFloat(currentFrom.min)
    const max = parseFloat(currentFrom.max)

    // Save current range into the controller
    this.element.dataset.range = JSON.stringify([from, to])
    this.element.dataset.activated = (from > min || to < max)

    return [from, to];
  }

  fillSlider(from, to, sliderColor, rangeColor, controlSlider) {
      const rangeDistance = to.max-to.min;
      const fromPosition = from.value - to.min;
      const toPosition = to.value - to.min;
      controlSlider.style.background = `linear-gradient(
        to right,
        ${sliderColor} 0%,
        ${sliderColor} ${(fromPosition)/(rangeDistance)*100}%,
        ${rangeColor} ${((fromPosition)/(rangeDistance))*100}%,
        ${rangeColor} ${(toPosition)/(rangeDistance)*100}%,
        ${sliderColor} ${(toPosition)/(rangeDistance)*100}%,
        ${sliderColor} 100%)`;
  }

  setToggleAccessible(currentTarget) {
    const toSlider = document.querySelector('#toSlider');
    if (Number(currentTarget.value) <= 0 ) {
      toSlider.style.zIndex = 2;
    } else {
      toSlider.style.zIndex = 0;
    }
  }
}