import { Controller } from "@hotwired/stimulus"
import initializeTooltips from '../../../components/app_tooltips';


export default class extends Controller {
  static targets = ['languageBtn']

  connect(){
    initializeTooltips(this.element)
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);
  }

  changeLanguage(){
    // Remove the selected class from all the buttons
    this.languageBtnTargets.forEach(target => target.classList.replace('secondary-action-btn-selected', 'secondary-action-btn'))

    // Then add the selected class to the current button
    event.currentTarget.classList.add('secondary-action-btn-selected')

    const locale = event.currentTarget.dataset.locale

    const stateEvent = new CustomEvent("state-updated", { detail: { locale: locale } });
    window.dispatchEvent(stateEvent);

    const articlePanelEvent = new CustomEvent("article-panel-updated");
    window.dispatchEvent(articlePanelEvent);    
  }
}
