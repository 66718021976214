import { Controller } from "@hotwired/stimulus";
import { projectName } from '../../components/utilities';
import initializeTooltips from '../../components/app_tooltips';


export default class extends Controller {
  static targets = ['answerBloc', 'star', 'starWrapper', 'answer', 'copyLabel']

  connect(){
    this.answerBlocTargets.forEach((bloc, idx) => {
      setTimeout(() => {
        bloc.classList.remove('opacity-0')
      }, idx*500)
    })

    initializeTooltips(this.element)
	}

  color(){
    const starIdx = parseInt(event.currentTarget.dataset.starIdx, 10)

    this.starTargets.forEach((star, idx) => {
      star.classList.remove('text-gray-400')
      if (idx + 1 <= starIdx){
        star.classList.add('text-yellow-500')
        star.classList.remove('text-gray-500')
      } else {
        star.classList.remove('text-yellow-500')
        star.classList.add('text-gray-500')
      }
    })
  }

  uncolor(){
    const rating = parseInt(this.starWrapperTarget.dataset.currentRating, 10)

    this.starTargets.forEach((star, idx) => {
      if (isNaN(rating)) {
        star.classList.add('text-gray-400')
        star.classList.remove('text-yellow-500')
        star.classList.remove('text-gray-500')
      } else if (idx + 1 <= rating){
        star.classList.remove('text-gray-400')
        star.classList.add('text-yellow-500')
        star.classList.remove('text-gray-500')
      } else {
        star.classList.remove('text-gray-400')
        star.classList.remove('text-yellow-500')
        star.classList.add('text-gray-500')
      }
    })
  }

  resetColor(){
    this.starTargets.forEach((star, idx) => {
      star.classList.add('text-gray-400')
      star.classList.remove('text-yellow-500')
      star.classList.remove('text-gray-500')
    })
  }

  rate(){
    const oldRating = parseInt(this.starWrapperTarget.dataset.currentRating, 10)
    let newRating = parseInt(event.currentTarget.dataset.starIdx, 10);

    if (oldRating === newRating) newRating = null

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/copilot_items/${this.element.dataset.id}/update_rating`
    const body = { rating: newRating }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Update rating dataset
      this.starWrapperTarget.dataset.currentRating = newRating
      if (newRating === null) this.resetColor()

      // Map display
      const historyEvent = new CustomEvent("history-panel-updated", { detail: { data: data } });
      window.dispatchEvent(historyEvent);
    });
  }

  copy(){
    const copiedBtn = event.currentTarget
    // Copy the text inside the text field
    navigator.clipboard.writeText(this.answerTarget.innerText.trim());

    // Initial text
    const initialText = this.copyLabelTarget.innerText
    const copiedText = this.copyLabelTarget.dataset.copied

    this.copyLabelTarget.innerHTML = copiedText
    copiedBtn.classList.remove('display-btn')
    copiedBtn.classList.add('display-btn-selected', 'pointer-events-none')
    setTimeout(() => {
      this.copyLabelTarget.innerHTML = initialText
      copiedBtn.classList.add('display-btn')
      copiedBtn.classList.remove('display-btn-selected', 'pointer-events-none')
    }, 500)
  }
}