import { Controller } from "@hotwired/stimulus"
import { removeContent } from '../../components/utilities';

export default class extends Controller {
  connect(){
    const projectId = this.element.dataset.projectId
    const url = `${window.location.origin}/projects/${projectId}/indicators`;

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'GET',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin"
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.element)
      this.element.insertAdjacentHTML('afterbegin', data["component"] );
    })
  }
}