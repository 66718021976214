import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['bar', 'searchLens', 'searchSpin', 'closeBtn']

  // Event handlers
  hideFilterLoaderHandler = (event) => this.hideLoader()

  connect(){
    window.addEventListener('hide-filter-loader', this.hideFilterLoaderHandler)
  }

  diconnect(){
    window.removeEventListener('hide-filter-loader', this.hideFilterLoaderHandler)
  }

  launchSearch(){
    if (event.currentTarget.value === '' || event.currentTarget.value === undefined){
      this.closeBtnTarget.classList.add('hidden')
    } else {
      this.closeBtnTarget.classList.remove('hidden')
    }

    if (!(event.key === 'Enter' || event.type === "click")) return

    // Show the loader
    this.showLoader()

    const filterBar = document.querySelector('[data-controller="filter-bar"]')

    // Remove any former free_search
    let filters = JSON.parse(filterBar.dataset.filters)
    filters = filters.filter(el => el['operator'] !== 'free_search')

    if (event.currentTarget.value === '' || event.currentTarget.value === undefined) {
      // Look for the search panel (only in insights) and remove it
      const closeSearchEvent = new CustomEvent("close-search-panel");
      window.dispatchEvent(closeSearchEvent);
    } else {
      // Prepare new free_search (if search terms are present)
      const freeSearch = {
        table_id: this.element.dataset.tableId,
        operator: 'free_search',
        values: event.currentTarget.value
      }
  
      // Save the new filters
      filters.push(freeSearch)
    }

    filterBar.dataset.filters = JSON.stringify(filters)

    const filterEvent = new CustomEvent("filters-updated", { detail: { filters: filters } });
    window.dispatchEvent(filterEvent);
  }

  showLoader(){
    this.element.querySelectorAll('[data-icon]').forEach(el => el.classList.add('hidden'))
    this.element.querySelector('[data-loading]').classList.remove('hidden')
  }

  hideLoader(){
    this.element.querySelectorAll('[data-loading]').forEach(el => el.classList.add('hidden'))
    this.element.querySelectorAll('[data-icon]').forEach(el => el.classList.remove('hidden'))
  }

  closeSearch(){
    this.resetSelection()
    this.barTarget.value = "";
    this.launchSearch();
  }

  addOperator(){
    const operator = event.currentTarget.dataset.value

    if (operator === 'LIKE'){
      this.barTarget.value = 'LIKE '
      const pos = this.barTarget.value.length
      this.barTarget.focus()
      this.barTarget.setSelectionRange(pos, pos)
    } else if (operator === 'QUOTE') {
      this.barTarget.value = `${this.barTarget.value.trimRight()} ""`.trimLeft()
      const pos = this.barTarget.value.length - 1
      this.barTarget.focus()
      this.barTarget.setSelectionRange(pos, pos)
    } else if (operator === 'WHOLE_WORD') {
      this.barTarget.value = `${this.barTarget.value.trimRight()} WHOLE_WORD()`.trimLeft()
      const pos = this.barTarget.value.length - 1
      this.barTarget.focus()
      this.barTarget.setSelectionRange(pos, pos)
    } else if (operator === 'WITHOUT') {
      this.barTarget.value = `${this.barTarget.value.trimRight()} WITHOUT()`.trimLeft()
      const pos = this.barTarget.value.length - 1
      this.barTarget.focus()
      this.barTarget.setSelectionRange(pos, pos)
    } else {
      this.barTarget.value = `${this.barTarget.value.trimRight()} ${operator} `.trimLeft()
      const pos = this.barTarget.value.length
      this.barTarget.focus()
      this.barTarget.setSelectionRange(pos, pos)
    }
  }

  resetSelection(){
    const list = document.querySelector('#clusters_index_search_list')
    let controller = this.application.getControllerForElementAndIdentifier(list, 'list');
    if (controller) controller.resetList()
  }
}
