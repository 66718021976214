import Chart from 'chart.js/auto';
import { Colors } from 'chart.js';
import 'chartjs-adapter-date-fns';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(ChartjsPluginStacked100);
Chart.register(Colors);
Chart.register(annotationPlugin);

class ChartJs {
  constructor(selector, graphData){
    this.selector = selector;
    this.graphData = graphData;
    this.type = this.graphData["type"];
    this.data = this.graphData["data"];
    this.options = this.graphData["options"];
    this.config = {
      type: this.type,
      data: this.data,
      options: this.options
    };
  }

  drawChart(){
    let chart = Chart.getChart(this.selector)

    if (chart){
      this.update(this.config)
      chart.resize()
    } else {
      new Chart( this.selector, this.config );
    }
  }

  drawStatic(){
    // Draw the chart in static mode for dashboard edit
    let myChart = Chart.getChart(this.selector)

    if (myChart){
      this.destroy()
    } else {
      myChart = new Chart( this.selector, this.config );
    }

    // Get the chart's base64 image string
    let image = myChart.toBase64Image();
    myChart.destroy();

    this.selector.style.backgroundImage = `url('${image}')`
    this.selector.style.backgroundSize = 'cover';
    this.selector.style.backgroundPosition = 'center';
  }

  // Function to restyle
  update(graphData){
    let chart = Chart.getChart(this.selector)

    chart.options = graphData["options"];
    chart.data = graphData["data"];
    chart.type = graphData["type"];

    chart.update();

  }

  // For now this method does the same as the update one above, further investigation to be done on how to make it work for it's original purpose
  react(graphData){
    let chart = Chart.getChart(this.selector)

    chart.options = graphData["options"];
    chart.data = graphData["data"];
    chart.type = graphData["type"];

    chart.update();
  }

  toggleSeries(datasetIndex){
    let chart = Chart.getChart(this.selector)

    const meta = chart.getDatasetMeta(datasetIndex);
    // Inverse la valeur de hidden
    meta.hidden = meta.hidden === null ? !chart.data.datasets[datasetIndex].hidden : null;
    chart.options.scales.y2.display = meta.hidden === null;
    chart.options.plugins.legend.display = meta.hidden === null;
    chart.update();
  }
}

export default ChartJs;
