import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";
import { projectName } from "../components/utilities";
import initializeFlatpickr from "../components/flatpickr_date";

export default class extends Controller {
  static targets = ['input'];

  connect(){
    switch (this.inputTarget.type) {
      case 'select-one':
      this.selectOptions = {
        selector: this.inputTarget,
        otherSettings: {
          items: JSON.parse(this.inputTarget.dataset.items),
          options: JSON.parse(this.inputTarget.dataset.options)
        }
      };
      initializeSelect(this.selectOptions);
      break;
    case 'date':
      initializeFlatpickr(this.inputTarget);
      break;
    }
    this.updateFilter()
  }

  updateFilter(){
    // Remove former evolution comparison
    const filterBar = document.querySelector('[data-controller="filter-bar"]')
    let filters = JSON.parse(filterBar.dataset.filters)
    filters = filters.filter(el => el['operator'] !== 'evolution')

    const timeUnit = document.querySelector('#time_unit_select').value
    const endDate = document.querySelector('#end_date_select').value

    // Prepare new evolution parameters
    const evolutionParameters = [
      {
        table_id: 'time_unit',
        operator: 'evolution',
        values: timeUnit
      },
      {
        table_id: 'end_date',
        operator: 'evolution',
        values: endDate
      }
    ]

    // Save the new filters
    filters.push(...evolutionParameters)
    filterBar.dataset.filters = JSON.stringify(filters)

    const filterEvent = new CustomEvent("filters-updated", { detail: { filters: filters } });
    window.dispatchEvent(filterEvent);
  }
}
