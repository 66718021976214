import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";
import { removeContent, projectName } from '../components/utilities';
import initializeFlatpickr from "../components/flatpickr_date";
import { DateTime } from "luxon";


export default class extends Controller {
  static targets = ['showFilterBtn', 'instruction', 'filterBtn', 'addFilterBtn', 'editFilterBtn', 'resetFiltersBtn', 'tag', 'filterTagsWrapper', 'filterTagsTpl',
                    'dropdown', 'list', 'level1Tpl', 'level2Tpl', 'level3Tpl', 'level3TimeTpl', 'level3NumberTpl']

  displayMode(){
    return JSON.parse(this.dropdownTarget.dataset.mode)
  }

  filters(){
    // Get the filters from the filterbar without the filters specific to each page (perimeter, free_search...)
    return JSON.parse(this.filterBar.dataset.filters).filter(filter => !['evolution', 'perimeter', 'free_search'].includes(filter.operator))
  }

  connect(){
    // Only used when the filters is combined with a dropdown
    // This variable helps knowing when to refresh the dropdown: the first time the dropdown is mounted or each time a filter tag is removed
    this.refreshDropdown = true

    this.filterBar = this.element.closest('[data-controller*="filter-bar"]');
    let filters = this.filters()
    if (!filters?.length) return;

    // Set buttons (add/reset)
    this.changeShowFilterBtnStyle(filters)
    this.changeResetFiltersBtnVisibility(filters)

    // Add tags
    this.updateFilterTags(filters)

    // Initialize filter input (if needed)
    this.initializeFilterInputs(filters)
  }

  // FILTER INITIALIZATION (WHEN FILTERS ARE ALREADY SAVED)_____________________________________________________________________________________

  // Only when the filter_dropdown_component is inside a dropdown
  initializeInputsOnDropdownMount(){
    const filters = this.filters()

    if (filters?.length) {
      if (!this.refreshDropdown) return;

      removeContent(this.listTarget)
      this.initializeFilterInputs(filters)
      this.refreshDropdown = false
    } else {
      removeContent(this.listTarget)
      this.filterBtnTarget.classList.add('hidden');
      this.instructionTarget.classList.remove('hidden');
    }
  }

  // if there are filters inside the dataset of the filterBar, initialize the dropdown
  initializeFilterInputs(filters){
    if (!this.hasDropdownTarget) return;

    setTimeout(() => {
      filters.forEach(filter => {
        this.addFilterRow(filter)
      })
    }, 100)
  }

  // FILTER INPUT MANIPULATION__________________________________________________________________________________________________________________

  // Triggered by click on add filter button in the dropdown
  addFilter(){
    this.addFilterRow()
  }

  // Add a filter row
  addFilterRow(filter=undefined){
    // Count how many filter items are present. If 0 --> WHERE otherwise AND
    const filterItemCount = this.listTarget.childElementCount;
    const logicOperator = filterItemCount === 0 ? this.dropdownTarget.dataset.where : this.dropdownTarget.dataset.and;

    // Set template
    let contentLv1 = this.level1TplTarget.innerHTML;
    contentLv1 = contentLv1.replace(/LOGIC_OPERATOR/g, logicOperator);
    this.listTarget.insertAdjacentHTML('beforeend', contentLv1);

    // Initialize table_id select
    const selectorLevel1 = this.listTarget.lastElementChild.querySelector('[data-type="table_id"]')
    const possibleFilters = JSON.parse(this.dropdownTarget.dataset.possibleFilters)
    // Add selected items (favorite filter)
    const selectedItem = filter === undefined ? possibleFilters[0]['value'] : filter['table_id'];
    initializeSelect({
      selector: selectorLevel1,
      otherSettings: {
        options: possibleFilters,
        // controlInput: null,
        allowEmptyOption: false,
        items: selectedItem,
        render: {
          option: (data, escape) => {
            return `<div class="flex justify-start items-center">
                      <i class="${this.tableIdIcon(data['data_type'])} mr-2"></i>
                      <span>${escape(data.text)}</span>
                    </div>`;
          },
          item: (data, escape) => {
            return `<div class="flex justify-start items-center">
                      <i class="${this.tableIdIcon(data['data_type'])} mr-2"></i>
                      <span>${escape(data.text)}</span>
                    </div>`;
          },
        }
      }
    });

    // Initialize operator select
    this.updateOperatorSelect(this.listTarget.lastElementChild, filter)

    // Show filter button
    if (!this.displayMode().includes('no_filter_btn')) this.filterBtnTarget.classList.remove('hidden');
    this.instructionTarget.classList.add('hidden');
  }

  tableIdIcon(dataType){
    if (dataType === 'category'){
      return 'far fa-list-alt'
    } else if (dataType === 'date'){
      return 'far fa-clock'
    } else if (dataType === 'array'){
      return 'fa-solid fa-tags'
    } else {
      return 'fas fa-hashtag'
    }
  }

  updateOperatorOnChange(){
    const itemWrapper = event.currentTarget.closest('[data-filter-item]')
    this.updateOperatorSelect(itemWrapper)
  }

  updateValuesOnChange(){
    const itemWrapper = event.currentTarget.closest('[data-filter-item]')
    this.updateValuesSelect(itemWrapper)
  }

  updateDateOnChange(){
    const itemWrapper = event.currentTarget.closest('[data-filter-item]')
    const tomInstance = event.currentTarget.tomselect;
    const value = tomInstance.getValue();
    const dateSelect = itemWrapper.querySelector('[data-type="date-select"]');

    if (['exact_date', 'specific_range'].includes(value)){
      dateSelect.nextElementSibling.classList.remove('hidden')
    } else {
      dateSelect.nextElementSibling.classList.add('hidden')
    }
  }

  updateOperatorSelect(itemWrapper, filter=undefined){
    // Remove Former Operator wrapper
    const operatorWrapper = itemWrapper.querySelector('[data-operator]')
    removeContent(operatorWrapper);

    // Initialize Operator Select
    const tableIdSelect = itemWrapper.querySelector('[data-type="table_id"]')
    const tomInstance = tableIdSelect.tomselect;
    if (tomInstance.items.length === 0) return; // If no item is selected in filters, stop the insertion of other selects

    // Insert Operator Wrapper
    let contentLv2 = this.level2TplTarget.innerHTML;
    operatorWrapper.insertAdjacentHTML('beforeend', contentLv2);

    const dataType = tomInstance.options[tomInstance.items]['data_type']

    const filterTree = JSON.parse(this.dropdownTarget.dataset.filterTree);
    const options = filterTree[dataType];
    // Add selected items (favorite filter)
    const selectedItem = filter === undefined ? options[0]['value'] : filter['operator'];

    // Initialize operator select
    const operatorSelect = itemWrapper.querySelector('[data-type="operator"]')
    initializeSelect({
      selector: operatorSelect,
      otherSettings: {
        options: options,
        controlInput: null,
        allowEmptyOption: false,
        items: selectedItem
      }
    });

    // Initialize values select
    this.updateValuesSelect(itemWrapper, filter)
  }

  updateValuesSelect(itemWrapper, filter=undefined){
    // Remove Former Values wrapper
    const valuesWrapper = itemWrapper.querySelector('[data-values]')
    removeContent(valuesWrapper);

    const tableIdSelect = itemWrapper.querySelector('[data-type="table_id"]')
    const tomInstanceCategory = tableIdSelect.tomselect;
    const tableId = tomInstanceCategory.items[0];
    const dataType = tomInstanceCategory.options[tableId]['data_type']

    const operatorSelect = itemWrapper.querySelector('[data-type="operator"]')
    const tomInstanceOperator = operatorSelect.tomselect;
    const operator = tomInstanceOperator.getValue();

    if (['is_empty', 'not_empty'].includes(operator)){
      let contentLv3 = this.level3TplTarget.innerHTML;
      valuesWrapper.insertAdjacentHTML('beforeend', contentLv3);
      itemWrapper.querySelector('[data-type="values"]').classList.add('hidden')
      itemWrapper.querySelector('[data-type="values"]').disabled = true
    } else if (dataType === 'number'){
      let contentLv3 = this.level3NumberTplTarget.innerHTML;
      valuesWrapper.insertAdjacentHTML('beforeend', contentLv3);

      const valuesSelect = itemWrapper.querySelector('[data-type="values"]');
      if (filter) valuesSelect.value = filter['values']
    } else if (['contains', 'not_contains', 'not_contains_any', 'contains_all', 'contains_one_of'].includes(operator)){
      let contentLv3 = this.level3TplTarget.innerHTML;
      valuesWrapper.insertAdjacentHTML('beforeend', contentLv3);
      const valuesSelect = itemWrapper.querySelector('[data-type="values"]');

      // Initialize value Select
      let ajaxParams = {}
      valuesSelect.setAttribute('multiple', '');
      if (!this.displayMode().includes('no_search')){
        let [table, column] = tableId.split('.')
        if (table === 'connector_histories') { table = 'super_admin/connector_histories' }
        const url = `${window.location.origin}/${projectName(window.location.pathname)}/${table}/search`;

        ajaxParams = {
          url: url,
          input: {filter_type: column}
        }
      }

      // Add selected items (favorite filter)
      let otherSettings = {}
      if (filter){
        otherSettings = {
          options: filter['values'].map(value => ({text: value, value: value})),
          items: filter['values']
        }
      }

      const addable = this.displayMode().includes('addable')

      initializeSelect({
        selector: valuesSelect,
        config: { remove_button: true, addable: addable, closeAfterSelect: false, ajax_params: ajaxParams },
        otherSettings: otherSettings
      });
    } else if (dataType === 'date'){
      let contentLv3 = this.level3TimeTplTarget.innerHTML;
      valuesWrapper.insertAdjacentHTML('beforeend', contentLv3);

      const valuesSelect = itemWrapper.querySelector('[data-type="values"]');
      const dateSelect = itemWrapper.querySelector('[data-type="date-select"]');

      if (operator === 'is_within'){
        // Initialize value select
        const options = JSON.parse(this.dropdownTarget.dataset.dateSelect)['range'];
        // Add selected items (favorite filter)
        const selectedItem = filter === undefined ? 'last_week' : filter['values']['time_frame'];
        initializeSelect({
          selector: valuesSelect,
          otherSettings: {
            options: options,
            controlInput: null,
            allowEmptyOption: false,
            items: selectedItem
          }
        });
        // Add selected items (favorite filter)
        if (filter && filter['values']['time_frame'] === 'specific_range') dateSelect.value = filter['values']['period']
        // Initialize date select
        initializeFlatpickr(dateSelect);

        if (selectedItem !== 'specific_range') dateSelect.nextElementSibling.classList.add('hidden')
      } else {
        // Initialize value select
        const options = JSON.parse(this.dropdownTarget.dataset.dateSelect)['exact_date'];
        // Add selected items (favorite filter)
        const selectedItem = filter === undefined ? 'exact_date' : filter['values']['time_frame'];
        initializeSelect({
          selector: valuesSelect,
          otherSettings: {
            options: options,
            controlInput: null,
            allowEmptyOption: false,
            items: selectedItem
          }
        });
        // Add selected items (favorite filter)
        if (filter && filter['values']['time_frame'] === 'exact_date') dateSelect.value = filter['values']['period']
        // Initialize date select
        initializeFlatpickr(dateSelect, {mode: 'single'});

        if (selectedItem !== 'exact_date') dateSelect.nextElementSibling.classList.add('hidden')
      }
    }
  }

  deleteInputRow(){
    event.stopPropagation();

    event.currentTarget.closest('[data-filter-item]').remove();

    const filterItemCount = this.listTarget.childElementCount;

    if (filterItemCount === 1){
      // If only one condition left --> Ensure that the first logic operator is a WHERE
      this.listTarget.firstElementChild.querySelector('[data-logic-operator]').innerText = this.dropdownTarget.dataset.where;
    } else if (filterItemCount === 0){
      this.validateFilter();
      this.filterBtnTarget.classList.add('hidden');
      if (!this.displayMode().includes('no_instruction')) this.instructionTarget.classList.remove('hidden');
    }
  }

  deleteFilter(){
    const existingFilters = this.filters()
    const delFilter = JSON.parse(event.currentTarget.dataset.tagValue)

    const newFilters = existingFilters.filter(el => (el.table_id + el.operator) !== (delFilter.table_id + delFilter.operator))

    this.updateFilterBar(newFilters)

    this.refreshDropdown = true
  }

  resetFilters(){
    event.stopPropagation();

    const filters = []
    this.addSpecialFilters(filters)

    this.updateFilterBar(filters)
  }



  // FILTER VALIDATION___________________________________________________________________________________________________________________________

  // TEMPORARY SOLUTION: only for automation card -> update the automation parameters when changing the absolute filters
  liveUpdate(){
    if (this.element.dataset.mode !== 'automation') return;

    const updateEvent = new CustomEvent("automation-filters-updated", { detail: { automationId: this.element.dataset.automationId } });
    window.dispatchEvent(updateEvent);
  }

  validateFilter(){
    this.updateFilterBar(this.parsedFilters())
  }

  parsedFilters(){
    const filters = [] // For filtering elements

    // Parse selected filters
    Array.from(this.listTarget.children).forEach(filterRow => {
      const filter = this.formattedFilter(filterRow)
      if (filter) filters.push(filter)
    })

    // Integrate the special filters (perimeters, evolution, free search)
    this.addSpecialFilters(filters)
    
    return filters;
  }

  // Format special filters for perimeters, evolution, free search
  addSpecialFilters(filters){
    const savedFilters = JSON.parse(this.filterBar.dataset.filters)

    // Perimeters
    const perimeterFilter = savedFilters.find(el => el['operator'] === 'perimeter')
    if (perimeterFilter !== undefined) filters.push(perimeterFilter)

    // Evolution
    const evolutionFilter = savedFilters.filter(el => el['operator'] === 'evolution') // Watch out: different from the other -> several filter to keep
    if (evolutionFilter.length !== 0) filters.push(...evolutionFilter)
      
    // Free search
    const freeSearch = savedFilters.find(el => el['operator'] === 'free_search')
    if (freeSearch !== undefined) filters.push(freeSearch)
  }

  addFavoriteFilters(){
    let filters = this.filters()
    this.updateFilterBar(filters)
  }

  updateFilterBar(filters){
    // Member settings
    this.updateSettings(filters)

    // Change style of the filter button (active = filters.count > 0 without considering free_search)
    this.changeShowFilterBtnStyle(filters)

    // Change visibility of the reset filter button (visible if filters.count > 0 without considering free_search)
    this.changeResetFiltersBtnVisibility(filters)

    // Update tags
    this.updateFilterTags(filters)

    // Show the loader
    if (this.filterBar.dataset.filterState === 'active') this.showLoader()

    // Update filter input (only for KPI creator)
    this.updateKPICreatorInput(filters)

    // Hide the dropdown
    if (this.hasShowFilterBtnTarget) this.showFilterBtnTarget._tippy.hide()

    // Save them into filter_bar
    this.filterBar.dataset.filters = JSON.stringify(filters)
  
    const filterEvent = new CustomEvent("filters-updated", { detail: { filters: filters } });
    window.dispatchEvent(filterEvent);
  }

  formattedFilter(filterRow){
    // Table id
    const tableIdSelect = filterRow.querySelector('[data-type="table_id"]')
    const tomTableIdInstance = tableIdSelect.tomselect;
    const tableId = tomTableIdInstance.getValue();
    const dataType = tomTableIdInstance.options[tomTableIdInstance.items]['data_type']

    // Operator
    const operatorSelect = filterRow.querySelector('[data-type="operator"]')
    const tomOperatorInstance = operatorSelect.tomselect;
    const operator = tomOperatorInstance.getValue();

    // Values
    const valuesSelect = filterRow.querySelector('[data-type="values"]')
    const dateSelect = filterRow.querySelector('[data-type="date-select"]');
    let values;

    if (dataType === 'number'){
      values = valuesSelect.value;
    } else if (['is_empty', 'not_empty'].includes(operator)){
      values = '';
    } else if (dateSelect){
      const tomDateSelectInstance = valuesSelect.tomselect;
      const text = tomDateSelectInstance.options[tomDateSelectInstance.items]['text']

      let userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let localeDate = DateTime.fromFormat(dateSelect.value, "yyyy-MM-dd", { zone: userTimeZone })
      let utcDate;
      if (dateSelect.value.includes(' to ')){
        const [minDate, maxDate] = dateSelect.value.split(' to ')
        const utcMinDate = DateTime.fromFormat(minDate, "yyyy-MM-dd", { zone: userTimeZone }).toISO()
        const utcMaxDate = DateTime.fromFormat(maxDate, "yyyy-MM-dd", { zone: userTimeZone }).toISO()
        utcDate = [utcMinDate, utcMaxDate].join(' to ')
      } else {
        utcDate = DateTime.fromFormat(dateSelect.value, "yyyy-MM-dd", { zone: userTimeZone }).toISO()
      }

      values = {time_frame: valuesSelect.tomselect.getValue(), period: utcDate, text: text, time_zone: userTimeZone}
    } else {
      values = valuesSelect.tomselect.getValue();
    }

    // Reject any empty value
    if (
          ((values === '' || values.length === 0) && !['is_empty', 'not_empty'].includes(operator)) ||
          (dateSelect != undefined && ['exact_date', 'specific_range'].includes(values['time_frame']) && dateSelect.value === '')
       ) return;

    // Add the filter into the filters table
    return {
      table_id: tableId,
      operator: operator,
      values: values
    }
  }

  namedFilter(filter){
    // Get information for naming
    let container;
    if (this.hasDropdownTarget){
      container = this.dropdownTarget
    } else {
      container = this.element.querySelector('template#filter_dropdown').content.querySelector('[data-filter-target="dropdown"]')
    }

    const possibleFilters = JSON.parse(container.dataset.possibleFilters)
    const filterTree = Object.values(JSON.parse(container.dataset.filterTree)).flat()

    // Table id name
    const tableIdName = possibleFilters.filter(el => el.value === filter.table_id)[0].text
    // operator name
    const operatorName = filterTree.filter(el => el.value === filter.operator)[0].text

    // Add the filter for displaying tags
    return {
      table_id: tableIdName,
      operator: operatorName,
      values: filter.values
    }
  }

  updateSettings(filters){
    if(this.filterBar.dataset.updateSettings === 'false') return;

    const normalFilters = filters.filter(filter => !['perimeter', 'evolution', 'free_search'].includes(filter.operator))

    // Update member settings
    const memberSettings = new CustomEvent("member-settings-updated", { detail: { filters: normalFilters } });
    window.dispatchEvent(memberSettings);
  }

  changeShowFilterBtnStyle(filters){
    if (!this.hasShowFilterBtnTarget) return;

    if (filters.length > 0) {
      this.addFilterBtnTarget.classList.add('hidden');
      this.editFilterBtnTarget.classList.remove('hidden');
    } else {
      this.addFilterBtnTarget.classList.remove('hidden');
      this.editFilterBtnTarget.classList.add('hidden');
    }
  }

  changeResetFiltersBtnVisibility(filters){
    if (!this.hasResetFiltersBtnTarget) return; // For KPI creator, no button

    // NB: special filters (perimeter, evolution, free_search) are removed from the count
    const normalFilters = filters.filter(filter => !['perimeter', 'evolution', 'free_search'].includes(filter.operator))
    if (normalFilters.length > 0) {
      this.resetFiltersBtnTarget.classList.remove('hidden')
    } else {
      this.resetFiltersBtnTarget.classList.add('hidden')
    }
  }

  updateFilterTags(filters){
    if (!this.hasFilterTagsWrapperTarget) return; // For KPI creator, no button

    this.tagTargets.forEach(tag => tag.remove())

    filters.forEach(filter => {
      // Perimeters, free search and evolution filters does not need a tag
      if (['perimeter', 'evolution', 'free_search'].includes(filter.operator)) return

      const namedFilter = this.namedFilter(filter)

      let values;
      if (!isNaN(namedFilter['values'])){
        values = namedFilter['values']
      } else if (typeof namedFilter['values'] === 'string') {
        values = ""
      } else if (namedFilter['values'].constructor === Array) {
        values = namedFilter['values'].join(', ')
      } else {
        if (['exact_date', 'specific_range'].includes(namedFilter['values']['time_frame'])) {
          values = namedFilter['values']['period']
        } else {
          values = namedFilter['values']['text'].toLowerCase()
        }
      }
      const filterText = `${namedFilter['table_id']} ${namedFilter['operator'].toLowerCase()} ${values}`;

      let filterTagHTML = this.filterTagsTplTarget.innerHTML
                                                  .replace(/FILTER_TEXT/g, filterText)
                                                  
      this.filterTagsWrapperTarget.insertAdjacentHTML('afterbegin', filterTagHTML);

      // Add the filter to the tag (so that when deleting the tag, it can be removed from the filters)
      const removeIcon = this.tagTargets[0].querySelector('i')
      if (!removeIcon) return;

      removeIcon.setAttribute('data-tag-value', JSON.stringify(filter))
    })

    if (!this.hasFilterTagsWrapperTarget) return;

    if (filters.length === 0){
      this.filterTagsWrapperTarget.classList.add('hidden')
    } else {
      this.filterTagsWrapperTarget.classList.remove('hidden')
    }
  }

  showLoader(){
    if (!this.hasShowFilterBtnTarget) return; // For KPI creator, no loader needed

    this.element.querySelectorAll('[data-icon]').forEach(icon => icon.classList.add('hidden'))
    this.element.querySelector('[data-loading]').classList.remove('hidden')
  }

  hideFilterLoader(){
    if (!this.hasShowFilterBtnTarget) return;

    let filters = this.filters()
    this.element.querySelector('[data-loading]').classList.add('hidden')
    this.changeShowFilterBtnStyle(filters)
  }

  updateKPICreatorInput(filters){
    const chartCreaContainer = document.querySelector('[data-controller*="chart-crea"]')
    if (chartCreaContainer === null) return; // Only for KPI creator filters: we need to copy filters to a hidden input

    const KPIInput = this.dropdownTarget.parentElement.querySelector('input');
    KPIInput.value = JSON.stringify(filters)

    // Trigger the updatePreview function of chart_crea_controller
    const chartController = this.application.getControllerForElementAndIdentifier(chartCreaContainer, 'chart-crea');
    chartController.updatePreview()
  }
}
