import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Event handlers
  filterDataUpdateHandler = (event) => this.launchFiltering(event.detail?.filters);

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]')?.dataset?.pageState || '{}')
  }

  connect(){
    window.addEventListener('filters-updated', this.filterDataUpdateHandler)

    // Filter bar is inactive at the connection
    // This behavior is used when user has already an active filter saved into its settings
    // That way the filter action is not triggered when the page is displayed
    setTimeout(() => {
      this.element.dataset.filterState = 'active'
    }, 500)
  }

  disconnect(){
    window.removeEventListener('filters-updated', this.filterDataUpdateHandler)
  }

  launchFiltering(filters){
    if (this.element.dataset.filterState === 'inactive') return;
    if (!filters) filters = this.pageState().filters || JSON.parse(this.element.dataset.filters) // Last part of the condition is needed for perimeter page which uses the old architecture

    const url = this.element.dataset.url;
    if (url.length === 0) return;
    
    const body = { data: { filters: filters }, page_state: this.pageState() }
    const verb = (this.element.dataset.verb) ? this.element.dataset.verb : 'POST'

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: verb,
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Hide loaders
      const loaderEvent = new CustomEvent("hide-filter-loader");
      window.dispatchEvent(loaderEvent);

      // Update page state with new filters
      const stateEvent = new CustomEvent("state-updated", { detail: data.page_state });
      window.dispatchEvent(stateEvent);

      // Send data to component for update
      const updateEvent = new CustomEvent("filtered-data-updated", { detail: { data: data } });
      window.dispatchEvent(updateEvent);

      // Show flash if needed
      if (Object.keys(data).includes('flash')) {
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
      }
    })
  }
}
