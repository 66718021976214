import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../../components/utilities';

export default class extends Controller {
  static targets = []

  updateGuidelines(){
    const type = this.element.dataset.type;
    const id = this.element.dataset.id
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/${type}/${id}/update_guidelines`;
    const body = { guidelines: event.currentTarget.value }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    fetch(url, {
        method: "POST",
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }
}
