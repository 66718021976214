import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ['operatorSelect', 'columnSelect', 'directionSelect'];

  connect(){
    this.initializeSelects();
  }

  initializeSelects(){
    let tomInstance;

    // Operator
    tomInstance = this.operatorSelectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    initializeSelect({
      selector: this.operatorSelectTarget,
      config: JSON.parse(this.operatorSelectTarget.dataset.config),
      otherSettings: {
        options: JSON.parse(this.operatorSelectTarget.dataset.options),
        items: JSON.parse(this.operatorSelectTarget.dataset.items),
        onItemAdd: (value, item) => {
          if (value === 'count'){
            this.columnSelectTarget.tomselect.disable()
            this.columnSelectTarget.tomselect.clear()
          } else {
            this.columnSelectTarget.tomselect.enable()
          }
        },
        render: {
          option: function(data, escape) {
            return `<div>
                      <div class="">${escape(data.text)}</div>
                      <div class='text-gray-500'>${escape(data.explanation)}</div>
                    </div>`
          },
        }
      }
    });

    // Column
    tomInstance = this.columnSelectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    initializeSelect({
      selector: this.columnSelectTarget,
      config: JSON.parse(this.columnSelectTarget.dataset.config),
      otherSettings: {
        options: JSON.parse(this.columnSelectTarget.dataset.options),
        items: JSON.parse(this.columnSelectTarget.dataset.items),
        onInitialize: () => {
          if (this.operatorSelectTarget.tomselect.getValue() === 'count'){
            this.columnSelectTarget.tomselect.disable()
          }
        },
      }
    });

    // Maximize
    tomInstance = this.directionSelectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    initializeSelect({
      selector: this.directionSelectTarget,
      config: JSON.parse(this.directionSelectTarget.dataset.config),
      otherSettings: {
        options: JSON.parse(this.directionSelectTarget.dataset.options),
        items: JSON.parse(this.directionSelectTarget.dataset.items)
      }
    });
  }
}

