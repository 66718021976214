import { Controller } from "@hotwired/stimulus"
import ChartJs from '../../components/initialize_graph';
import { projectName } from "../../components/utilities";
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["chart", 'saving', 'saved'];
  
  // Events Handlers
  indicatorLoadedHandler = (event) => this.activateDragAndDrop();
  
  connect() {
    const initModalNew = document.querySelector('#init-modal-new');

    if (initModalNew) {
      MicroModal.show(initModalNew.dataset.micromodalTrigger);
    }

    // Events
    window.addEventListener('indicator-loaded', this.indicatorLoadedHandler)
  }

  disconnect(){
    // Event coming from indicator-placeholder js controller
    window.removeEventListener('indicator-loaded', this.indicatorLoadedHandler)
  }

  activateDragAndDrop(){
    // Check how many indicator placeholders are left in the DOM
    const placeholdersCount = document.querySelectorAll('#indicator-placeholder').length
    if (placeholdersCount !== 0) return;

    // If no placeholders are present in the DOM that means that all indicators have been loaded, therefore we can initialize the drag and drop 
    this.initializeDragAndDrop();
  }

  // Used when all indicators are loaded in the page
  initializeDragAndDrop() {
    const sortableIndicators = document.querySelector('#sortable-indicators');
    if (sortableIndicators){
      new Sortable(sortableIndicators, {
        onUpdate: () => {
          this.updateLayout();
          }
      });
    }
  }

  // on click on "Select Indicators"
  redrawChartsFromModal() {
    setTimeout(() => {
      this.chartTargets.forEach(chart => {
        this.drawAChart(chart);
      });
    }, 10)
  }

  // In edit view to enlarge or reduce the width of indicators 
  enlargeWidth(event) {
    const indicatorContainer = event.currentTarget.closest('.sortable-indicator');
    const indicator = indicatorContainer.querySelector('[data-width]');

    if (indicator.dataset.width == "normal") {

      indicator.dataset.width = "big";
      this.updateLayout();

      indicatorContainer.querySelector('.enlarge-width-btn').classList.add('hidden');
      indicatorContainer.querySelector('.reduce-width-btn').classList.remove('hidden');
      indicatorContainer.classList.add('col-span-2');
    }
  }

  // In edit view to enlarge or reduce the width of indicators 
  reduceWidth(event) {
    const indicatorContainer = event.currentTarget.closest('.sortable-indicator');
    const indicator = indicatorContainer.querySelector('[data-width]');

    if (indicator.dataset.width == "big") {

      indicator.dataset.width = "normal";
      this.updateLayout();

      indicatorContainer.querySelector('.enlarge-width-btn').classList.remove('hidden');
      indicatorContainer.querySelector('.reduce-width-btn').classList.add('hidden');
      indicatorContainer.classList.remove('col-span-2');
    }
  }

  // In edit view to enlarge or reduce the height of indicators 
  enlargeHeight(event) {
    const indicatorContainer = event.currentTarget.closest('.sortable-indicator');
    const indicator = indicatorContainer.querySelector('[data-height]');

    if (indicator.dataset.height == "normal") {

      indicator.dataset.height = "big";
      this.updateLayout();

      indicatorContainer.querySelector('.enlarge-height-btn').classList.add('hidden');
      indicatorContainer.querySelector('.reduce-height-btn').classList.remove('hidden');
      indicatorContainer.classList.add('row-span-2');
      indicator.classList.replace('h-[350px]', 'h-[800px]');
      indicator.style.height = '800px';
      if (indicator.chartInstance) {
        indicator.chartInstance.resize();
      }
    }
  }

  // In edit view to enlarge or reduce the height of indicators 
  reduceHeight(event) {
    const indicatorContainer = event.currentTarget.closest('.sortable-indicator');
    const indicator = indicatorContainer.querySelector('[data-height]');

    if (indicator.dataset.height == "big") {

      indicator.dataset.height = "normal";
      this.updateLayout();

      indicatorContainer.querySelector('.enlarge-height-btn').classList.remove('hidden');
      indicatorContainer.querySelector('.reduce-height-btn').classList.add('hidden');
      indicatorContainer.classList.remove('row-span-2');
      indicator.classList.replace('h-[800px]', 'h-[350px]');
      indicator.style.height = '350px';
      if (indicator.chartInstance) {
        indicator.chartInstance.resize();
      }
    }
  }

  //used by Connect, Enlarge and Reduce, everytime refreshing the graph is needed
  drawAChart(chart) {
    // Skip this logic if the graph dataset is not present, this means that the component is not a chart
    if (!chart.dataset.graph) return;
    
    const graphData = JSON.parse(chart.dataset.graph);
    if (Array.from(chart.classList).includes('grid-chart')){
      if (graphData.options?.plugins?.legend){
        graphData['options']['plugins']['legend']['display'] = false;
      }
      if (graphData.options?.scales?.y?.title){
        graphData['options']['scales']['y']['title']['display'] = false;
      }
      if (graphData.options?.scales?.x?.title){
        graphData['options']['scales']['x']['title']['display'] = false;
      }
      graphData['options']['animation'] = false;
      // graphData['options']['hover'] = {};
      // graphData['options']['plugins']['tooltip']['enabled'] = false;
    }
    const newChart = new ChartJs(chart, graphData);
    // Static chart for charts in the charts gallery modal
    if (Array.from(chart.classList).includes('grid-chart')) {
      newChart.drawStatic();
      // Static chart for sortable charts in edit view
    } else if (chart.closest('.sortable-indicator')) {
      newChart.drawChart();
    } else {
      // Default Dynamic chart for charts in dashboard show view
      newChart.drawChart();
    }
  }

  //Used by enlarge, reduce, and initializeDragAndDrop
  updateLayout() {
    const indicatorsChildren = Array.from(document.querySelector('#sortable-indicators').children);
    const layoutInput = document.querySelector('#dashboard-layout-input');

    const result = {};
    let index = 1;

    this.savedTarget.classList.add('hidden');
    this.savingTarget.classList.toggle('hidden');

    indicatorsChildren.forEach(indicatorContainer => {
      const indicator = indicatorContainer.querySelector('[data-position]');
      const id = indicator.id.replace('indicator-', "");
      const position = index;
      const width = indicator.dataset.width;
      const height = indicator.dataset.height;

      indicator.dataset.position = position;
      index ++;
      result[id] = {position: position, width: width, height: height};
    })

    layoutInput.dataset.layout = JSON.stringify(result);

    const id = document.querySelector('[data-dashboard-id]').dataset.dashboardId;
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/dashboards/${id}/update_layout`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value;

    fetch(url, {
      method: "PATCH",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(layoutInput.dataset.layout)
    })
      .then(response => response.json())
      .then(data => {          
        setTimeout(() => {
          this.savingTarget.classList.toggle('hidden');
          this.savedTarget.classList.toggle('hidden');
          // If there are no indicators left in the dashboard, display the proper info text
          if(data.no_indicators) {
            // Trigger the event re-render the edit page of the dashboard if there are no charts left on the page
            const customEvent = new CustomEvent('all-indicators-removed');
            window.dispatchEvent(customEvent); 
          }
        }, 1000);
        setTimeout(() => {
          this.savedTarget.classList.add('hidden');           
        }, 2000);
      });
  }

  // In Modal, data about selecting charts in the gallery is stored in a hidden field in a form, output is an array of chart_id
  graphListUpdate() {
    const graphListInput = document.querySelector('#graph-list-input');
    let selectedValue = JSON.parse(graphListInput.value);

    // If the graph is selected or unselected either by clicking on it update the class of the chart container and also the array of chart_ids
    if (event.currentTarget.classList.contains('dashboard-selected-indicator')) {
      event.currentTarget.classList.remove('dashboard-selected-indicator');
      const title = event.currentTarget.querySelector('[data-dashboards--editor-target="indicatorTitle"]')
      title.classList.replace('text-dark-400', 'text-gray-500');
      selectedValue = selectedValue.filter(value => value != event.currentTarget.id);
    } else {
      event.currentTarget.classList.add('dashboard-selected-indicator');
      const title = event.currentTarget.querySelector('[data-dashboards--editor-target="indicatorTitle"]')
      title.classList.replace('text-gray-500', 'text-dark-400');
      selectedValue.push(event.currentTarget.id);   
    }
  
    // Remove blank
    selectedValue = selectedValue.filter(value => value != "");

    graphListInput.value = JSON.stringify(selectedValue);
  }   

  removeIndicator(){
    // Remove the sortable indicator 
    const indicator = event.currentTarget.closest('.sortable-indicator');

    const indicatorId = indicator.querySelector('[data-position]').id.replace('indicator-', "");
    indicator.remove();

    // Mark the indicator as not selected in the Indicator Gallery
    const gallery = this.element.querySelector('.gallery-indicator');
    Array.from(gallery.children).forEach(galleryIndicator => {
      if(galleryIndicator.id === indicatorId){ 
        galleryIndicator.classList.remove('dashboard-selected-indicator');
        // Remove also the indicator id from the hidden field from the add indicator modal form
        const graphListInput = document.querySelector('#graph-list-input');
        let selectedValue = JSON.parse(graphListInput.value);

        let index = selectedValue.indexOf(indicatorId); 
        if (index > -1) { 
          selectedValue.splice(index, 1); 
          graphListInput.value = JSON.stringify(selectedValue);
        }
      }
    })

    // Update the layout
    this.updateLayout();
  }

  // Called by the 'Back' button
  backToDashboardShow(){
    // Redirrects to the show page to include the id of the selected dashboard
    let dashboard_id =  this.element.querySelector('#dashboard_select').value;
    // In the exceptional case of clicking the button when the dashboard has not been saved yet we will redirrect to dashboard_index
    if(dashboard_id === ''){
      window.location = `${window.location.origin}/${projectName(window.location.pathname)}/dashboards`;
    } else {
      window.location = `${window.location.origin}/${projectName(window.location.pathname)}/dashboards/${dashboard_id}`;
    }      
  }
}
