import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";
import {projectName} from "../components/utilities";

export default class extends Controller {
  static targets = ['selectPlaceholders','bodyPreview', 'testResponse', 'urlInput']

  connect(){
    // Initialize placeholders select
    if (!this.hasSelectPlaceholdersTarget) return;

    const tomInstance = this.selectPlaceholdersTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    const options = JSON.parse(this.selectPlaceholdersTarget.dataset.options)

    this.placeholders = initializeSelect({
      selector: this.selectPlaceholdersTarget,
      config: { remove_button: true, addable: false, closeAfterSelect: false },
      multiple: true,
      otherSettings: {
        options: options,
        items: JSON.parse(this.selectPlaceholdersTarget.dataset.items)
      }
    });

    this.updatePreview()
  }

  updatePreview(){
    const entries = this.placeholders.getValue()
    const options = JSON.parse(this.selectPlaceholdersTarget.dataset.options)
    const example = JSON.parse(this.bodyPreviewTarget.dataset.examples)

    let body = '{\n'
    body += `  event_time:${new Date().toUTCString()},\n`
    body += `  item: {\n`

    entries.forEach(entry => {
      const name = options.filter(el => el['value'] === entry)[0]['text']

      let value;
      if (entry === 'clusters') {
        let stringArray = []
        example[entry].forEach(el => {
          stringArray.push(`{ cluster: "${el['cluster']}", satisfaction: "${el['satisfaction']}" }`)
        })
        value = '[' + stringArray.join(',') + ']'
      } else {
        value = `"${example[entry]}"`
      }

      body += `    ${entry}: <span class="whitespace-normal">${value}</span>, <span class="text-orange-500">// ${name}</span>\n`
    })

    body += '  }\n'
    body += '}'

    this.bodyPreviewTarget.innerHTML = body
  }

  testConnection(){
    // Remove former data-connection
    this.testResponseTarget.innerHTML = ''

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    const exportId = event.currentTarget.dataset.id;
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/connectors/exports/${exportId}/webhook_connection`;

    const webhookUrl = this.urlInputTarget.value

    const body = {
      url: webhookUrl
    }

    fetch(url,{
      method: "POST",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
    .then(response => response.text())
    .then(data => {
      this.testResponseTarget.insertAdjacentHTML('beforeend', data)
    });
  }
}
