import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';

export default class extends Controller {
  static targets = ['overview', 'agentPanel'];

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event Handlers
  agentPanelUpdatedHandler = (event) => this.toggleAgentPanel();
  filteredDataUpdatedHandler = (event) => this.updateAfterFilter(event.detail.data);

  connect(){
    // Events
    window.addEventListener('agent-panel-updated', this.agentPanelUpdatedHandler)
    window.addEventListener('agent-panel-closed', this.agentPanelUpdatedHandler)
    window.addEventListener('filtered-data-updated', this.filteredDataUpdatedHandler)
  }

  disconnect(){
    // Events
    window.removeEventListener('agent-panel-updated', this.agentPanelUpdatedHandler)
    window.removeEventListener('agent-panel-closed', this.agentPanelUpdatedHandler)
    window.removeEventListener('filtered-data-updated', this.filteredDataUpdatedHandler)
  }

  toggleAgentPanel(){
    const rightPanel = this.pageState().right_panel

    if (rightPanel === null){
      this.hideAgentPanel()
    } else {
      this.updateAgentPanel()
    }
  }
 
  updateAgentPanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/agents/right_panel`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.refreshAgentPanel(data);
    });
  }

  refreshAgentPanel(data){
    if (this.pageState().right_panel === null) this.hideAgentPanel()
    if (!Object.keys(data).includes('AgentTracker::AgentPanel::PanelComponent')) return;

    this.agentPanelTarget.innerHTML = data['AgentTracker::AgentPanel::PanelComponent']
    this.agentPanelTarget.classList.remove('hidden')
  }

  updateAfterFilter(data){
    // Overview
    this.updateOverview(data)
    // Agent Panel
    this.refreshAgentPanel(data)
  }

  updateOverview(data){
    if (!Object.keys(data).includes('AgentTracker::Overview::PanelComponent')) return;

    this.overviewTarget.innerHTML = data['AgentTracker::Overview::PanelComponent']
  }

  hideAgentPanel(){
    this.agentPanelTarget.classList.add('hidden')
  }
}