import { Controller } from "@hotwired/stimulus"
import { removeContent } from '../../components/utilities';

export default class extends Controller {
  static targets = ['card'];

  changeAgentMetric(){
    this.cardTargets.forEach ((card)=> {
      card.classList.replace('display-btn-selected', 'display-btn');
    })

    event.currentTarget.classList.replace('display-btn', 'display-btn-selected')

    // Update State
    const stateEvent = new CustomEvent("state-updated", { detail: { agent_metric_id: event.currentTarget.dataset.agentMetricId } });
    window.dispatchEvent(stateEvent);

    // Update metric evolution & top contributors 
    const agentMetricUpdatedEvent = new CustomEvent("agent-metric-updated");
    window.dispatchEvent(agentMetricUpdatedEvent);
  }
}
