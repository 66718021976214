import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ['select', 'createBtn', 'creationForm', 'selectWrapper', 'createBtnWrapper'];

  connect(){
    document.addEventListener('connector-credentials-updated', () => {
      this.updateSelect()
    })

    this.select = initializeSelect({
      selector: this.selectTarget,
      otherSettings: {
        options: JSON.parse(this.selectTarget.dataset.options),
        items: JSON.parse(this.selectTarget.dataset.items),
        render: {
          item: function(data, escape) {
            return `<div class="flex justify-start items-center gap-6">
                      <div class="flex p-2 rounded bg-gray-100 border shadow">
                        <img class="w-6" src="${data.icon}">
                      </div>
                      <div class="">${escape(data.text)}</div>
                    </div>`
          },
          option: function(data, escape) {
            return `<div class="flex justify-start items-center gap-6">
                      <div class="flex p-2 rounded bg-gray-100 border shadow">
                        <img class="w-6" src="${data.icon}">
                      </div>
                      <div class="">${escape(data.text)}</div>
                    </div>`
          },
        }
      }
    });
  }

  openCredentialWindow(){
    event.preventDefault(); // Empêche le comportement de clic par défaut

    // Définissez la taille et d'autres propriétés de la nouvelle fenêtre
    var width = 600;
    var height = 600;
    var leftPosition = (screen.availWidth - width) / 2;
    var topPosition = (screen.availHeight - height) / 2;

    // Construisez la chaîne des propriétés de la fenêtre
    var windowFeatures = 'width=' + width + ',height=' + height + ',scrollbars=yes';
    windowFeatures += ',left=' + leftPosition + ',top=' + topPosition;

    // Ouvrez une nouvelle fenêtre avec les propriétés définies
    window.open(event.currentTarget.dataset.href, '_blank', windowFeatures);
  }

  updateSelect(){
    const credentials = event.detail
    
    // Refresh options
    this.select.clear();
    this.select.clearOptions();
    this.select.addOptions(credentials)

    // Show right part of the component
    if (credentials.length === 0){
      this.selectWrapperTarget.classList.add('hidden');
      this.createBtnWrapperTarget.classList.remove('hidden')
      this.select.disable()
    } else {
      this.selectWrapperTarget.classList.remove('hidden');
      this.createBtnWrapperTarget.classList.add('hidden');
      this.select.enable()
    }
  }
}