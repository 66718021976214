import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tabBtn', 'codeWrapper', 'code', 'copyLabel']

  selectTab(){
    const button = event.currentTarget;
    const selectedTab = button.innerText;
    this.element.dataset.selectedTab = selectedTab;

    // Update tab button
    this.tabBtnTargets.forEach(btn => {
      btn.classList.remove('display-btn-selected')
      btn.classList.add('display-btn')
    })
    button.classList.add('display-btn-selected')
    button.classList.remove('display-btn')

    // Show selected code wrapper
    this.codeWrapperTargets.forEach(wrapper =>{
      if (wrapper.dataset.tab === selectedTab){
        wrapper.classList.remove('hidden')
      } else {
        wrapper.classList.add('hidden')
      }
    })
  }

  copy(){
    const copiedBtn = event.currentTarget
    // Copy the text inside the text field
    const code = this.codeWrapperTargets.find(wrapper => wrapper.dataset.tab === this.element.dataset.selectedTab)
                                        .querySelector('code')
    navigator.clipboard.writeText(code.innerText.trim());

    // Initial text
    const initialText = this.copyLabelTarget.innerText
    const copiedText = this.copyLabelTarget.dataset.copied

    this.copyLabelTarget.innerHTML = copiedText
    copiedBtn.classList.remove('display-btn')
    copiedBtn.classList.add('display-btn-selected', 'pointer-events-none')
    setTimeout(() => {
      this.copyLabelTarget.innerHTML = initialText
      copiedBtn.classList.add('display-btn')
      copiedBtn.classList.remove('display-btn-selected', 'pointer-events-none')
    }, 500)
  }
}