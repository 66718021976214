import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ['metricSelect', 'loader', 'titleOptions', 'metric', 'metricRange', 'reverseScale'];

  // Event handlers
  hideFilterLoaderHandler = (event) => this.hideLoader()
  initializeMetricOptionsHandler = (event) => this.initializeMetricOptions()
  resetMetricRangeHandler = (event) => this.resetMetricRange()

  connect(){
    this.refreshRange = true;

    window.addEventListener('hide-filter-loader', this.hideFilterLoaderHandler)
    window.addEventListener('initialize-metric-options', this.initializeMetricOptionsHandler)
    window.addEventListener('reset-metric-range', this.resetMetricRangeHandler)
  }

  disconnect(){
    window.removeEventListener('hide-filter-loader', this.hideFilterLoaderHandler)
    window.removeEventListener('initialize-metric-options', this.initializeMetricOptionsHandler)
    window.removeEventListener('reset-metric-range', this.resetMetricRangeHandler)
  }

  initializeMetricOptions(){
    this.initializeMetricSelect()
    this.setMetricRange()
  }

  initializeMetricSelect(){
    if (this.metricSelectTarget.tomselect !== undefined) return;

    initializeSelect({
      selector: this.metricSelectTarget,
      otherSettings: {
        options: JSON.parse(this.metricSelectTarget.dataset.options),
        items: this.metricSelectTarget.dataset.items,
        onItemAdd: (value, item) => this.updateMetricValue(value),
        render: {
          option: function(data, escape) {
            return `<div><div class="cursor-pointer font-bold">${escape(data.text)}</div></div>`
          },
          item: function(data, escape) {
            return `<div class="flex justify-between items-center gap-4">
                      <div class="flex justify-between items-center gap-4">
                        <i class="fa-solid fa-magnifying-glass-chart text-lg"></i>
                        <div class="font-bold">${escape(data.text)}</div>
                      </div>
                      <i class="fas fa-circle-notch fa-spin hidden" data-metric-options-target="loader"></i>
                    </div>`
          }
        }
      }
    });
  }

  updateMetricValue(value){
    this.loaderTarget.classList.remove('hidden')

    // Update page state
    const stateEvent = new CustomEvent("state-updated", { detail: { metric_id: this.metricSelectTarget.tomselect.getValue() } });
    window.dispatchEvent(stateEvent);

    // Refresh filters
    setTimeout(() => {
      const filterEvent = new CustomEvent("filters-updated");
      window.dispatchEvent(filterEvent);
    }, 200)
  }

  hideLoader(){
    if (!this.hasLoaderTarget) return
    
    this.loaderTarget.classList.add('hidden')
  }

  updateTitle(){
    let newOptions;

    // Metric
    const selectedItem = this.metricSelectTarget.tomselect.items
    if (selectedItem.length === 0) return;
    const metric = this.metricSelectTarget.tomselect.options[selectedItem].text
    newOptions = metric

    // Metric Range
    if (this.hasMetricRangeTarget){
      const rangeSlider = this.metricRangeTargets.filter(el => !el.classList.contains('hidden'))[0].querySelector('[data-range]')
      const activated = rangeSlider.dataset.activated === 'true'
      const range = JSON.parse(rangeSlider.dataset.range)
      if (activated) newOptions += ` - ${range.join('-')}`
    }

    // Reversed scale
    if (this.hasReverseScaleTarget){
      if (this.reverseScaleTarget.checked) newOptions += ` - Reversed`
    }

    this.titleOptionsTarget.innerHTML = newOptions
  }

  changeRange(){
    const rangeSlider = this.metricRangeTargets.filter(el => !el.classList.contains('hidden'))[0].querySelector('[data-range]')
    const range = JSON.parse(rangeSlider.dataset.range)
    const customEvent = new CustomEvent("metric-range-updated", { detail: { range: range }});
    window.dispatchEvent(customEvent);
  }

  reverseScale(){
    const customEvent = new CustomEvent("reverse-scale-updated", { detail: { reversed: event.currentTarget.checked }});
    window.dispatchEvent(customEvent);
  }


  resetMetricRange(){
    this.refreshRange = true

    this.element.dataset.metricRange = JSON.stringify(event.detail)
  }

  setMetricRange(){
    if (!this.element.dataset.metricRange) return;

    const range = JSON.parse(this.element.dataset.metricRange)
    let minRange, maxRange
    [minRange, maxRange] = range

    this.metricRangeTarget.classList.remove('hidden')

    const fromSlider = this.metricRangeTarget.querySelector('#fromSlider')
    const toSlider = this.metricRangeTarget.querySelector('#toSlider')
    const fromInput = this.metricRangeTarget.querySelector('#fromInput')
    const toInput = this.metricRangeTarget.querySelector('#toInput')

    // Slider from
    fromSlider.min = minRange
    fromSlider.max = maxRange
    fromSlider.value = minRange
    // Slider to
    toSlider.min = minRange
    toSlider.max = maxRange
    toSlider.value = maxRange
    // Input from
    fromInput.min = minRange
    fromInput.max = maxRange
    fromInput.value = minRange
    // Input to
    toInput.min = minRange
    toInput.max = maxRange
    toInput.value = maxRange
    // Range
    this.metricRange = range
    this.metricRangeTarget.querySelector('[data-range]').dataset.range = JSON.stringify(this.metricRange)

    const rangeContainer = this.metricRangeTarget.querySelector('[data-controller="dual-slider"]')
    const controller = this.application.getControllerForElementAndIdentifier(rangeContainer, 'dual-slider');
    controller.connect()

    this.refreshRange = false
  }
}
