import flatpickr from "flatpickr";
import "flatpickr/dist/themes/light.css"

function initializeFlatpickr(selector, addOptions={}){

	const range = Boolean(selector.dataset.range)

	let options = {
		altInput: true,
		altFormat: "F j, Y",
		dateFormat: "Y-m-d"
	}

	if (selector.dataset.type === 'single'){
		Object.assign(options, {
			mode: 'single'
		})
	} else {
		Object.assign(options, {
			mode: 'range',
	    onOpen:  function(selectedDates, dateStr, instance){
	      instance.element.dataset.selectedRange = '';

				// Check if the pickr is inside a dropdown and freeze the dropdown to prevent it to close
				const dropdown = selector.closest('[data-tippy-root]')
				if (dropdown === null) return;
	
				const button = Array.from(dropdown.parentElement.children).filter(el => el.dataset.tooltip === 'dropdown')[0]
				this.tippyInstance = button._tippy;
				this.tippyInstance.setProps({ hideOnClick: false});
	    },
	    onClose: function(selectedDates, dateStr, instance){
	      instance.element.dataset.selectedRange = dateStr;

				this.tippyInstance.setProps({ hideOnClick: true});
	    }
		})
	}

	Object.assign(options, addOptions)

	// We can add limits to the range of possible dates
	// For that add to the html element a min-date and/or max-date attribute
	if (selector.hasAttribute('data-min-date')) {Object.assign(options, {minDate: selector.dataset.minDate})}
	if (selector.hasAttribute('data-max-date')) {Object.assign(options, {maxDate: selector.dataset.maxDate})}

	flatpickr(selector, options);
}

export default initializeFlatpickr;