import { Controller } from "@hotwired/stimulus"
import MicroModal from 'micromodal';
import { removeContent } from '../components/utilities';
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ['fieldTypeSelect', 'options', 'optionsList', 'optionItem', 'template'];

  connect(){
    let tomInstance;
    this.maxId = parseInt(this.element.dataset.optionId, 10);

    tomInstance = this.fieldTypeSelectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();


    this.select = initializeSelect({
      selector: this.fieldTypeSelectTarget,
      otherSettings: {
        options: JSON.parse(this.fieldTypeSelectTarget.dataset.options),
        items: JSON.parse(this.fieldTypeSelectTarget.dataset.items),
        onItemAdd: (value, item) => {
          if (value === 'dropdown'){
            this.showFieldValues()
          } else {
            this.destroyFieldValues()
          }
        },
        render: {
          option: function(data, escape) {
            return `<div>
                      <div class="">${escape(data.text)}</div>
                    </div>`
          },
        }
      }
    });
  }

  showFieldValues(){
    this.optionsTarget.classList.remove('invisible');
    if (this.optionsListTarget.childNodes.length === 0) this.insertNewOptionListItem();
  }

  addOptionItem(){
    const lastOptionItem = this.optionItemTargets.pop();

    if (lastOptionItem.value.length > 0) {
      this.insertNewOptionListItem();
      this.toggleDeleteButton(lastOptionItem);
    }
  }

  toggleDeleteButton(lastOptionItem){
    const deleteButton = lastOptionItem.parentNode.lastElementChild
    if(deleteButton.classList.contains('invisible')) deleteButton.classList.remove('invisible');
  }

  insertNewOptionListItem(){
    this.maxId += 1;
    const newOptionItemHTMLTag = this.templateTarget.innerHTML.replace('PLACEHOLDER_OPTION_ID', this.maxId);
    this.optionsListTarget.insertAdjacentHTML("beforeend", newOptionItemHTMLTag);
  }

  destroyOptionItem (event){
    const optionItem = event.currentTarget.closest('li');
    optionItem.remove();
  }

  destroyFieldValues(){
    this.optionsTarget.classList.add("invisible");
    removeContent(this.optionsListTarget);
  }

  closeModal(){
    const modalId = event.currentTarget.closest('[data-cluster-field-target="modal"]').id;
    MicroModal.close(modalId);
  }
}
