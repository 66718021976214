import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../components/utilities';
import ChartJs from "../../components/initialize_graph";

export default class extends Controller {
  static targets = ['wrapper', 'card'];


  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    this.displayGraph()
  }

  changeMetric(){
    this.cardTargets.forEach ((card)=> {
      card.classList.replace('display-btn-selected', 'display-btn');
    })

    event.currentTarget.classList.replace('display-btn', 'display-btn-selected')

    // Update State
    const stateEvent = new CustomEvent("state-updated", { detail: { metric_id: event.currentTarget.dataset.metricId } });
    window.dispatchEvent(stateEvent);

    // Update top contributors
    const metricUpdatedEvent = new CustomEvent("metric-updated");
    window.dispatchEvent(metricUpdatedEvent);

    // Reload Panel by sending an event that is captured by js controller indicator
    const loadIndicatorEvent = new CustomEvent("load-indicator", { detail: { indicator_id: 'metrics_indicator' } });
    window.dispatchEvent(loadIndicatorEvent);  
  }

  // Initialize the chart of evolution of metrics 
  displayGraph() {
    const graphContainer = document.querySelector('#metric-evolution-chart');
    // If the graph container is not found, this means there is no data so the chart should not be drawn
    if (!graphContainer) return;
    const graphData = JSON.parse(graphContainer.dataset.graph);
    const metricEvolutionChart = new ChartJs(graphContainer, graphData);
    metricEvolutionChart.drawChart();
  }
}
