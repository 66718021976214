import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['leftPanel', 'rightPanel']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  tableUpdatedHandler = (event) => this.rebuildTable(event.detail.data);
  validatedOnlySwitchToggleHandler = (event) => this.toggleValidatedRows()
  topicClickedHandler = (event) => this.highlightRow(event.detail.cluster_id);
  topicUnclickedHandler = (event) => this.unhighlightRows();
  filterDataUpdatedHandler = (event) => this.rebuildTable(event.detail.data);

  connect(){
    // Show table if page_state ok
    if (this.pageState().display === 'table') this.element.classList.remove('hidden')
    
    // Hide validated rows when activated
    if (this.pageState().validated_only) this.toggleValidatedRows()

    window.addEventListener('validated-only-switch-toggled', this.validatedOnlySwitchToggleHandler)
    window.addEventListener('table-updated', this.tableUpdatedHandler)
    window.addEventListener('topic-clicked', this.topicClickedHandler)
    window.addEventListener('topic-unclicked', this.topicUnclickedHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('validated-only-switch-toggled', this.validatedOnlySwitchToggleHandler)
    window.removeEventListener('table-updated', this.tableUpdatedHandler)
    window.removeEventListener('topic-clicked', this.topicClickedHandler)
    window.removeEventListener('topic-unclicked', this.topicUnclickedHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  toggleValidatedRows(){
    let hiddenRowCount = 0
    const rows = this.element.querySelectorAll(`tr[id^=clusters-]`)

    rows.forEach(row => {
      const isValidated = row.querySelector('[data-cluster-state]').dataset.clusterState === 'validated'
      const metric = row.querySelector('[data-metric]').dataset.metric
      const isInMetricRange = (metric >= this.pageState().metric_range[0] && metric <= this.pageState().metric_range[1])

      if ((this.pageState().validated_only && !isValidated) || (!isInMetricRange)){
        row.classList.add('hidden')
        hiddenRowCount += 1
      } else {
        row.classList.remove('hidden')
      }
    })

    // Update list count in table
    const clusterList = document.querySelector('#sentiments_index_clusters_list')
    const totalCount = JSON.parse(clusterList.dataset.data).selection.results_count
    const listBanner = clusterList.querySelector('[data-list-target="selectionBanner"] span')
    listBanner.innerHTML = listBanner.innerText.replace(/^[0-9]*/g, totalCount - hiddenRowCount)
  }

  rebuildTable(data){
    if (this.pageState().display !== 'table') {
      this.element.classList.add('hidden')
    }  
   
    if (!Object.keys(data).includes('Sentiments::TableComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['Sentiments::TableComponent'])
    this.element.remove();
  }

  highlightRow(clusterId){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
    const selectedRow = this.element.querySelector(`tr[id='clusters-${clusterId}']`)
    if (selectedRow === null) return;
    selectedRow.classList.add('bg-gray-200')
  } 
  
  unhighlightRows(){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
  }

  selectRow(){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
    event.currentTarget.closest('tr').classList.add('bg-gray-200')

    const clusterId = event.currentTarget.closest('tr').id.replace('clusters-', "");
    const mapEvent = new CustomEvent("topic-row-clicked", { detail: { cluster_id: clusterId } });
    window.dispatchEvent(mapEvent);

    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: clusterId } });
    window.dispatchEvent(stateEvent);

    const topicPanelEvent = new CustomEvent("topic-panel-updated");
    window.dispatchEvent(topicPanelEvent);

    if (this.pageState().screen === 'small') return
     
    // If the right panel is hidden because the graph size is 'big', then dispatch an event that will be captured by the left_panel_controller.js in order to decrease the graph size
    const toggleScreenEvent = new CustomEvent("toggle-screen-size");
    window.dispatchEvent(toggleScreenEvent);
  }
}
