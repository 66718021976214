import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['btn']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  toggleTimeFilter(){
    const previouslySelectedBtn = this.element.querySelector('.display-btn-selected')

    event.currentTarget.classList.replace('display-btn', 'display-btn-selected')

    if (previouslySelectedBtn) {
      previouslySelectedBtn.classList.replace('display-btn-selected', 'display-btn')
    }

    this.addTimeFilterToFilterBar();
  }

  addTimeFilterToFilterBar(){
    const selectedBtn = this.element.querySelector('.display-btn-selected')

    // Remove former time filter
    const filterBar = document.querySelector('[data-controller="filter-bar"]')
    let filters = JSON.parse(filterBar.dataset.filters)
    filters = filters.filter(el => el['table_id'] !== 'items.created_at')

    if (selectedBtn) {
      // Prepare new time filter
      const timeFilter = {
        table_id: 'items.created_at',
        operator: 'is_within',
        values: JSON.parse(selectedBtn.dataset.filterValue)
      }

      // Save the new filters
      filters.push(timeFilter)
    }

    filterBar.dataset.filters = JSON.stringify(filters)

    const filterEvent = new CustomEvent("filters-updated", { detail: { filters: filters } });
    window.dispatchEvent(filterEvent);
  }
}
