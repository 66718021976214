import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['wrapper', 'tpl', 'error', 'input']

  addQuestion(){
    const newPosition = parseInt(event.currentTarget.dataset.index, 10) + 1
    const newQuestion = this.tplTarget.innerHTML
                                      .replace(/NUMBER/g, newPosition)

    event.currentTarget.dataset.index  = newPosition;

    this.wrapperTarget.insertAdjacentHTML('beforeend', newQuestion)
  }

  removeQuestion(){
    const question = event.currentTarget.closest('[data-question]')
    question.remove()
  }

  checkLimit(){
    let maxLimit = this.element.closest('[data-controller="question-selector"]').dataset.remainingItems;

    if (maxLimit == 'Infinity') return 

    maxLimit = parseInt(maxLimit, 10)

    let inputSum = 0

    this.inputTargets.forEach(input => {
      if (!isNaN(parseInt(input.value, 10))) {
           inputSum += parseInt(input.value, 10)
      }      
    })

    let submitBtn = document.querySelector('button[type="submit"]')

    if (inputSum <= maxLimit) {
      this.inputTargets.forEach( input => input.classList.remove("border-orange-400"));
      this.errorTargets.forEach( error => error.classList.add("hidden"));
      submitBtn.classList.remove('btn-disabled');
      submitBtn.disabled = false
    } else {
      this.inputTargets.forEach( input => input.classList.add("border-orange-400"));
      this.errorTargets.forEach( error => error.classList.remove("hidden"));
      submitBtn.classList.add('btn-disabled');
      submitBtn.disabled = true
    }
  }
}
