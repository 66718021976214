import { Controller } from "@hotwired/stimulus"
import { newInstance } from "@jsplumb/browser-ui"
import { removeContent, projectName } from '../components/utilities';

export default class extends Controller {
  static targets = ['evolutionGraph', 'clusterEvolution', 'noDataMessage']

  // Event handlers
  filterDataUpdateHandler = (event) => this.updateEvolution(event.detail.data)

  connect(){
    // Events
    window.addEventListener('filtered-data-updated', this.filterDataUpdateHandler)

    this.PlumbInstance = newInstance({
      container: this.evolutionGraphTarget,
      connectionsDetachable:false,
      elementsDraggable: false
    })

    // Links between the current period's cluster and the next period's cluster
    this.linkClusterPeriods()
  }

  disconnect(){
    window.removeEventListener('filtered-data-updated', this.filterDataUpdateHandler)
  }

  updateEvolution(data){
    if (Object.keys(data).includes('no_data')){
      this.showNoDataMessage();
    } else {
      this.recreateEvolutionGraph(data.evolution_graph_data);
    }
  }

  highlightCell(){
    this.highlightFlow();
  }

  unhighlightCell(){
    this.unhighlightFlow();
  }

  cleanFront(){
    this.noDataMessageTarget.classList.add('hidden');
    this.evolutionGraphTarget.classList.remove('hidden');
  }

  recreateEvolutionGraph(evolutionGraphHTML) {
    this.cleanFront();
    if (evolutionGraphHTML === "") return;

    // Remove former content
    removeContent(this.evolutionGraphTarget);
    this.evolutionGraphTarget.insertAdjacentHTML('beforeend', evolutionGraphHTML);

    this.linkClusterPeriods();
  }

  showEvolutionPercentage(element){
    let arrow = element.querySelector('#evolution-arrow');
    let percentage = element.querySelector('#evolution-percentage');
    percentage.classList.remove('hidden');
    if (arrow) arrow.classList.add('hidden');
  }

  showEvolutionArrow(element){
    let arrow = element.querySelector('#evolution-arrow');
    let percentage = element.querySelector('#evolution-percentage');
    percentage.classList.add('hidden');
    if (arrow) arrow.classList.remove('hidden');
  }


  linkClusterPeriods(){
    this.clusterEvolutionTargets.forEach(tag => {
      let colIndex = parseInt(tag.dataset.colIndex, 10);
      let clusterId = tag.dataset.clusterId;

      const nextTag = document.querySelector(`[data-cluster-id="${clusterId}"][data-col-index="${colIndex+1}"]`);
      if (nextTag) {
        const connection = this.PlumbInstance.connect({
          source: tag,
          target: nextTag,
          connector: {
            type: 'Straight',
            options: {
              stub: 25,
              gap: -5
            }
          },
          paintStyle: { stroke: "#D0D5DD", strokeWidth: 2, dashstyle: "1 3" },
          endpoint: 'Blank',
          anchors: [[ 1, 0.5, 1, 0, 4, 0 ], [ 0, 0.5, -1, 0, -4, 0 ]]
        })
        connection.addClass(`cluster-id-${clusterId}`);
      }
    })
  }

  // Redraw the diagram each time the window is resized
  redraw(){
    this.PlumbInstance.repaintEverything();
  }

  toggleClusterSelection(){
    const clusterDiv = event.currentTarget.querySelector("[data-evolution-target='clusterEvolution']")
    const clusterId = clusterDiv.dataset.clusterId;
    this.clusterEvolutionTargets.filter(tag => tag.dataset.clusterId == clusterId).forEach(tag => {
      if (tag.dataset.selected === 'false'){
        tag.dataset.selected='true';
      }else{
        tag.dataset.selected='false';
        this.unhighlightFlow();
      }
    });
  }

  highlightFlow() {
    // Get the cluster id of the mouseover cell
    const clusterDiv = event.currentTarget.querySelector("[data-evolution-target='clusterEvolution']")
    if (!clusterDiv) return; // Means that there is no cluster inside the cell
    
    const clusterId = clusterDiv.dataset.clusterId
    const cellHeight = clusterDiv.clientHeight
    // Loop through the other evolution cells and identify the ones that are corresponding to the same cluster id
    this.clusterEvolutionTargets.filter( tag => tag.dataset.selected === 'false' ).forEach(tag => {
      tag.classList.remove('opacity-25');
      let colIndex = parseInt(tag.dataset.colIndex, 10);

      // Increase to maximum the opacity of cells containing the same cluster in the graph while decreasing to minimum for the other cells
      if(tag.dataset.clusterId === clusterId) {
        tag.style.backgroundColor = "rgba(208, 213, 221, 1)";

        // Replace the arrow icon with the percentage of metric evolution for the entire flow but not for the first column on the graph
        if(colIndex > 1) this.showEvolutionPercentage(tag);

        let nextTag = document.querySelector(`[data-cluster-id="${clusterId}"][data-col-index="${colIndex+1}"]`);
        if(nextTag){
          let connection = this.PlumbInstance.select({
            source: tag,
            target: nextTag
          });
          connection.setPaintStyle({stroke: "#EAECF0", strokeWidth: cellHeight, dashstyle: "solid"});
          connection.repaint();
        }
      } else {
        tag.classList.add('opacity-25');
      }
    })
  }

  unhighlightFlow(){
    const selectedFlowCount = this.clusterEvolutionTargets.filter( tag => tag.dataset.selected === 'true' ).length

    this.clusterEvolutionTargets.filter( tag => tag.dataset.selected === 'false' ).forEach(tag => {
      let colIndex = parseInt(tag.dataset.colIndex, 10);
      let clusterId = tag.dataset.clusterId;

      // When the elements are not mouse overed, we set the style to the initial value and we remove the dataset initialStyle
      if (selectedFlowCount === 0) {
        tag.classList.remove('opacity-25')
      } else {
        tag.classList.add('opacity-25')
      }

      tag.style.backgroundColor = `rgba(208, 213, 221, ${tag.dataset.cellTransparency})`;

      // Replace the percentage with the arrow icon representing the evolution of metric for the entire flow but not for the first column on the graph
      if(colIndex > 1) this.showEvolutionArrow(tag);

      let nextTag = document.querySelector(`[data-cluster-id="${clusterId}"][data-col-index="${colIndex+1}"]`);
      if(nextTag){
        let connection = this.PlumbInstance.select({
          source: tag,
          target: nextTag
        });
        connection.setPaintStyle({stroke: "#D0D5DD", strokeWidth: 2, dashstyle: "1 3"});
        connection.repaint();
      };
    })
  }

  showNoDataMessage(){
    this.evolutionGraphTarget.classList.add('hidden');
    this.noDataMessageTarget.classList.remove('hidden');
  }
}
