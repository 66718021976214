import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["placeholder"];

	updateColumnMatching(){
		const position = event.currentTarget.tomselect.getValue()
		const url = event.currentTarget.dataset.url + '?' + new URLSearchParams({position: position})

		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
		    .content.value;
    fetch(url, {
      method: 'get',
      headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      
    })
    .then(response => response.text())
    .then(data => {
      this.placeholderTarget.innerHTML = data;
    });
	}
}
