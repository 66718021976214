import MicroModal from 'micromodal';

const initializeModals = ()=> {
  MicroModal.init();
  // Open modal at startup if `automatic-modal-opening` attribute set to true
  document.querySelectorAll("[automatic-modal-opening=true]").forEach(value => {
    MicroModal.show(value.id);
  });
}


// MODAL MANAGEMENT________________________________________________________________________________________________________


const openModal = (modal, securityCheckLevel, actionBtn) => {
	// Configure the security check
	setSecurityCheck(modal, securityCheckLevel)

  const data = JSON.parse(actionBtn.dataset.data)

	// Add the selection properties to the modal
	modal.dataset.requestData = JSON.stringify({
		list_id: actionBtn.dataset.listId,
		data: data
	})

	// Hide extend selection list (for add_to_cluster and create)
	const selectionList = modal.querySelector('[data-extend-selection-target="list"]')
	const selectionInstruction = modal.querySelector('[data-extend-selection-target="instruction"]')
	const selectionBtn = modal.querySelector('[data-extend-selection-target="button"]')
	if (selectionList) {
		selectionList.classList.add('hidden')
		selectionInstruction.classList.add('hidden')
		selectionBtn.classList.remove('hidden')
	}
	modal.querySelector('.modal__container').style.width = '' // Reinitialize size of the modal (modified by the extend selection)

	// Unable validate buttons
	const button = modal.querySelector('[data-validate-button]')
	if (button) button.classList.remove('btn-disabled');

	// Open modal
	MicroModal.show(modal.id);
}

// SECURITY CHECK__________________________________________________________________________________________________________

// Set the security check
const setSecurityCheck = (modal, securityCheckLevel) =>{
	// Configure the security check
	const safetyMode = document.querySelector('body').dataset.safetyMode;

	const selectedCount = JSON.parse(event.currentTarget.dataset.data).selection.selected_count;
	if (selectedCount >= securityCheckLevel && safetyMode === 'true'){
		// Update security select
		const securitySelect = modal.querySelector("[data-select-count]");
		securitySelect.placeholder = selectedCount;
		securitySelect.dataset.selectCount = selectedCount;
		securitySelect.value = "";
		// Update security instruction
		const securityInstruction = modal.querySelector("[data-instruction] b")
		securityInstruction.innerHTML = selectedCount;
		// Show the security check container
		modal.querySelector("[data-security]").classList.remove('hidden')
		// Set the submit button accordingly
		const submit = modal.querySelector("#submit");
		submit.dataset.allowed = 'false'
		submit.classList.add('btn-disabled');
	} else {
		modal.querySelector("[data-security]").classList.add('hidden')
		// Set the submit button accordingly
		const submit = modal.querySelector("#submit");
		submit.dataset.allowed = 'true';
		submit.classList.remove('btn-disabled');
	}
}

const checkConsistency = (input, submit) =>{
	if (input.value === input.dataset.selectCount){
		submit.classList.remove('btn-disabled');
		submit.dataset.allowed = 'true'
	} else {
		submit.classList.add('btn-disabled');
		submit.dataset.allowed = 'false'
	}
}


export {initializeModals, openModal, checkConsistency};
