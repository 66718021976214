import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['chevron', 'panel']

  togglePanel(){
	  /*Note on the accordions: for the animation to work, we need to set a max-width before and after the transition
	  However, we need to remove the max-width at the end of the expension phase because otherwise, 
	  it will come into conflict with the increase of the panel. For instance, it will hide the new lines of the filters.
	  We also need to remove the overflow hidden. Otherwise the dropdowns of the select will be hidden*/

	  if (this.panelTarget.style.maxHeight) {
	    this.chevronTarget.classList.remove('fa-rotate-180')
	    this.panelTarget.style.maxHeight = null;
	  } else {
	    this.chevronTarget.classList.add('fa-rotate-180')
	    this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px"
	  } 
	}

}