import { Controller } from "@hotwired/stimulus"
import ChartJs from '../../../../components/initialize_graph';
import { projectName } from '../../../../components/utilities';
import initializeTooltips from '../../../../components/app_tooltips';

export default class extends Controller {
  static targets = ['chart', 'overviewPanel', 'aiActionPanel']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    initializeTooltips(this.element)

    // Initialize Chart
    if (!this.hasChartTarget) return;
    
    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    const historyChart = new ChartJs(this.chartTarget, graphData);
    historyChart.drawChart();
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);

    // Unselect extracts from the verbatim list to hide the action bar
    const listSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_cluster_panel_clustered_extracts_list', type: 'extracts', selected_ids: [] } });
    window.dispatchEvent(listSelectionEvent);
    // Unselect extracts from the ai verbatim list to hide the action bar
    const aiListSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_ai_list', type: 'extracts', selected_ids: [] } });
    window.dispatchEvent(aiListSelectionEvent);
  }

  closeAiPanel(){
    this.aiActionPanelTarget.classList.add('hidden')
    this.overviewPanelTarget.classList.remove('hidden')

    // Unselect extracts from the ai verbatim list to hide the action bar
    const aiListSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_ai_list', type: 'extracts', selected_ids: [] } });
    window.dispatchEvent(aiListSelectionEvent);
  }

  showAiActionPanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/ai_panel`
    const body = { type: event.currentTarget.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      const aiPanelName = Object.keys(data).find(key => key.includes('Topics::Ai::TopicPanel'))
      const aiPanel = data[aiPanelName]
      if (aiPanel) {
        this.aiActionPanelTarget.innerHTML = aiPanel
        this.aiActionPanelTarget.classList.remove('hidden')
        this.overviewPanelTarget.classList.add('hidden')
      }
    });
  }
}