import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchInput"]

  launchSearch(){
    if (event.key === 'Enter' || event.type === "click")  {
      let searchTerm = this.searchInputTarget.value == '' ? '' : `?search=${this.searchInputTarget.value}`
      window.location = window.location.origin + window.location.pathname + searchTerm;
    }    
  }

  closeSearch(){
    this.searchInputTarget.value = '';
    this.launchSearch();
  }
}
