import { Controller } from "@hotwired/stimulus"
import { removeContent } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {

  // Event handlers
  filterDataUpdatedHandler = (event) => this.rebuildTable(event.detail.data);

  connect(){
    // Event Listeners
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    setTooltips(this.element);
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  rebuildTable(data){
    if (!Object.keys(data).includes('refreshed_list')) return;

    const listWrapper = document.querySelector('#workflow_automation_histories_index_list');
    removeContent(listWrapper)

    listWrapper.insertAdjacentHTML('afterbegin', data['refreshed_list'] );
  }
}