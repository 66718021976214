import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {
  static targets = ['overview', 'unresolvedPanel', 'articlePanel', 'articleInfoPanel', 'verbatimPanel', 'unresolvedIcon', 'unresolvedLoadingIcon']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  articlePanelUpdatedHandler = (event) => this.toggleArticlePanel();
  unresolvedPanelUpdatedHandler = (event) => this.updateUnresolvedPanel();
  filteredDataUpdatedHandler = (event) => this.updateAfterFilter(event.detail.data);

  connect(){
    // Events
    window.addEventListener('article-panel-updated', this.articlePanelUpdatedHandler)
    window.addEventListener('unresolved-panel-updated', this.unresolvedPanelUpdatedHandler)
    window.addEventListener('filtered-data-updated', this.filteredDataUpdatedHandler)
  }

  disconnect(){
    // Events
    window.removeEventListener('article-panel-updated', this.articlePanelUpdatedHandler)
    window.removeEventListener('unresolved-panel-updated', this.unresolvedPanelUpdatedHandler)
    window.removeEventListener('filtered-data-updated', this.filteredDataUpdatedHandler)
  }

  toggleArticlePanel(){
    const rightPanel = this.pageState().right_panel

    if (rightPanel === null){
      this.hideArticlePanel()
    } else {
      this.updateArticlePanel()
    }
  }
 
  updateArticlePanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/articles/right_panel`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.refreshArticlePanel(data);
      this.refreshUnresolvedPanel(data);
    });
  }

  updateUnresolvedPanel(){
    this.showUnresolvedLoader();
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/articles/unresolved_panel`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.refreshUnresolvedPanel(data);
      this.hideUnresolvedLoader();
    });
  }

  toggleVerbatimPanel(){
    this.articleInfoPanelTarget.classList.toggle('hidden')
    this.verbatimPanelTarget.classList.toggle('hidden')
    event.currentTarget.classList.toggle('display-btn')
    event.currentTarget.classList.toggle('display-btn-selected')

    const stateEvent = new CustomEvent("state-updated", { detail: { solved_verbatim_tab: !this.pageState().solved_verbatim_tab } });
    window.dispatchEvent(stateEvent);
  }

  // When updating the filters
  updateOverview(data){
    const overviewName = Object.keys(data).find(key => key.includes('Overview'))
    const overviewPanel = data[overviewName]
    if (overviewPanel) this.overviewTarget.innerHTML = overviewPanel
    setTooltips(this.element)
  }

  updateAfterFilter(data){
    // Overview
    this.updateOverview(data)
    // Article Panel
    this.refreshArticlePanel(data)
    // Unresolved Questions Panel
    this.refreshUnresolvedPanel(data)

    setTooltips(this.element)
  }

  refreshArticlePanel(data){
    if (this.pageState().right_panel === null) this.hideArticlePanel()

    const articlePanelName = Object.keys(data).find(key => key.includes('ArticlePanel'))
    const articlePanel = data[articlePanelName]
    if (!articlePanel) return;

    this.articlePanelTarget.innerHTML = articlePanel
    this.articlePanelTarget.classList.remove('hidden')
  }

  refreshUnresolvedPanel(data){
    if (!this.pageState().unresolved_panel_visible) this.hideUnresolvedPanel()

    const unresolvedPanelName = Object.keys(data).find(key => key.includes('UnresolvedPanel'))
    const unresolvedPanel = data[unresolvedPanelName]
    if (!unresolvedPanel) return;

    this.unresolvedPanelTarget.innerHTML = unresolvedPanel
    this.unresolvedPanelTarget.classList.remove('hidden')
  }

  closeUnresolvedPanel(){
    this.hideUnresolvedPanel();
    // Update page-state
    const stateEvent = new CustomEvent("state-updated", { detail: { unresolved_panel_visible: false } });
    window.dispatchEvent(stateEvent);
  }

  hideArticlePanel(){
    this.articlePanelTarget.classList.add('hidden')
  }

  hideUnresolvedPanel(){
    this.unresolvedPanelTarget.classList.add('hidden')
  }

  showUnresolvedLoader(){
    this.unresolvedIconTarget.classList.add('hidden')
    this.unresolvedLoadingIconTarget.classList.remove('hidden')
  }

  hideUnresolvedLoader(){
    this.unresolvedLoadingIconTarget.classList.add('hidden')
    this.unresolvedIconTarget.classList.remove('hidden')
  }
}
