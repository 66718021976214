import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["potential", "confirmed", "tplPotential", "tplConfirmed", "added", "removed", "loadMoreConfirmed", "loadMorePotentials"]

	choose(){
		const newWord = event.currentTarget.querySelectorAll('span')[0].innerText;
		const newOccurrence = event.currentTarget.querySelectorAll('span')[1].innerText;

		// Add the new item to the confirmed list
		const content = this.tplConfirmedTarget.innerHTML
											   .replace(/CONFIRMED_WORD/g, newWord)
											   .replace(/CONFIRMED_OCC/g, newOccurrence);
		this.loadMoreConfirmedTarget.insertAdjacentHTML('beforebegin', content);

		// Add the new item to the hidden added input
		let addedValues = JSON.parse(this.addedTarget.value);
		addedValues.push(newWord);
		this.addedTarget.value = JSON.stringify(addedValues);

		// Remove the item from the hidden removed input
		let removedValues = JSON.parse(this.removedTarget.value);
		for( var i = 0; i < removedValues.length; i++){ 
		   if ( removedValues[i] === newWord) {
		     removedValues.splice(i, 1); 
		   }
		}
		this.removedTarget.value = JSON.stringify(removedValues);

		// Remove the new item from the potential list
		event.currentTarget.remove();
	}

	remove(){
		const removedWord = event.currentTarget.parentElement.querySelectorAll('span')[0].innerText;
		const removedOcc = event.currentTarget.parentElement.querySelectorAll('span')[1].innerText;

		// Add the new item to the potential list
		const content = this.tplPotentialTarget.innerHTML
											   .replace(/POTENTIAL_WORD/g, removedWord)
											   .replace(/POTENTIAL_OCC/g, removedOcc);
		this.loadMorePotentialsTarget.insertAdjacentHTML('beforebegin', content);

		// Add the new item to the hidden removed input
		let removedValues = JSON.parse(this.removedTarget.value);
		removedValues.push(removedWord);
		this.removedTarget.value = JSON.stringify(removedValues);

		// Remove the item from the hidden removed input
		let addedValues = JSON.parse(this.addedTarget.value);
		for( var i = 0; i < addedValues.length; i++){ 
		   if ( addedValues[i] === removedWord) {
		     addedValues.splice(i, 1); 
		   }
		}
		this.addedTarget.value = JSON.stringify(addedValues);

		// Remove the new item from the confirmed list
		event.currentTarget.parentElement.remove();
	}

	loadMoreWords() {
		let target = event.currentTarget;
		let url = target.dataset.url;

		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
			.content.value;
		fetch(url, {
			method: "POST",
			headers: {
				Accept: "application/js",
				"Content-Type": "application/json",
				"X-CSRF-Token": csrfToken
			},
			credentials: "same-origin",
			body: JSON.stringify({offset: target.dataset.offset, panel: target.dataset.panel, type: target.dataset.type})
		})
			.then(response => response.json())
			.then(data => {
				target.insertAdjacentHTML('beforebegin', data['html']);
				target.dataset.offset = data['offset'];
				if (data['offset'] % 20 > 0) target.classList.add('hidden');
			});
	}
}
