import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  tableUpdatedHandler = (event) => this.rebuildTable(event.detail.data);
  filterDataUpdatedHandler = (event) => this.rebuildTable(event.detail.data);
  resetPageHandler = (event) => this.resetPage()

  connect(){
    // Event Listeners
    window.addEventListener('table-updated', this.tableUpdatedHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    window.addEventListener('reset-page', this.resetPageHandler)
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('table-updated', this.tableUpdatedHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    window.removeEventListener('reset-page', this.resetPageHandler)
  }

  rebuildTable(data){
    if (!Object.keys(data).includes('KnowledgeTracker::TableComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['KnowledgeTracker::TableComponent'])
    this.element.remove();

    // Reselect the list based on the article loaded in the right panel. Add a timeout to let the new list being integrated into the DOM
    const articleId = this.pageState().right_panel
    if (articleId === null) return;

    setTimeout(() => {
      this.highlightRow(articleId)
    }, 300)
  }

  resetPage(){
    this.resetSelection();
    this.unhighlightRows();
    this.toggleRightPanel();
  }

  resetSelection(){
    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: null, locale: null, solved_verbatim_tab: false } });
    window.dispatchEvent(stateEvent);
  }

  toggleRightPanel(){
    const articlePanelEvent = new CustomEvent("article-panel-updated");
    window.dispatchEvent(articlePanelEvent);
  }

  highlightRow(articleId){
    const selectedRow = this.element.querySelector(`tr[id='articles-${articleId}']`)
    if (selectedRow === null) return;
    selectedRow.classList.add('bg-gray-200')
  } 

  selectRow(){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
    event.currentTarget.closest('tr').classList.add('bg-gray-200')

    const id = parseInt(event.currentTarget.closest('tr').id.replace('articles-', ''), 10)

    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: id, locale: null, unresolved_panel_visible: false } });
    window.dispatchEvent(stateEvent);

    const articlePanelEvent = new CustomEvent("article-panel-updated");
    window.dispatchEvent(articlePanelEvent);
  }

  unhighlightRows(){
    this.element.querySelectorAll('tr.bg-gray-200').forEach(tr => tr.classList.remove('bg-gray-200'))
  }
}
