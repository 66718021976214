import { Controller } from "@hotwired/stimulus"
import { projectName } from '../components/utilities';

export default class extends Controller {

  // Event handlers
  updateSettingsHandler = (event) => this.updateSettings(event.detail)

  connect(){
    window.addEventListener('member-settings-updated', this.updateSettingsHandler)
  }

  disconnect(){
    window.removeEventListener('member-settings-updated', this.updateSettingsHandler)
  }

  updateSettings(newSettings){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/members/update_settings`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    const body = { settings: newSettings }

    fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
  }
}
