import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  updateRole(){
    const checkbox = event.currentTarget;
    const checked = checkbox.checked;
    const userId = checkbox.dataset.userId;

    const url = `${window.location.origin}/users/${userId}/update_user_role`;

    const body = { checked: checked }

    // AJAX call to update the user role
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data);
    })
  }
}
