import { Controller } from "@hotwired/stimulus"
import { projectName } from '../components/utilities';
import Rails from '@rails/ujs';
import ChartJs from '../components/initialize_graph';


export default class extends Controller {
  static targets = ["chartWrapper", "preview" ,"addSeriesBtn", "addAxisBtn", "sndAxisInput", "remAxisBtn", "sndAxisOption"]

  connect(){
    this.formOrder = ["series", "y_options", "operators", "properties", "final_params"];

    this.refreshPreviewGraph();   
  }

  // Listen to data-graph attribute and update graph when new data are available
  refreshPreviewGraph(){
    const graphData = JSON.parse(this.chartWrapperTarget.dataset.graph);
    const prevChart = new ChartJs(this.chartWrapperTarget, graphData);
    prevChart.drawChart();

    var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

    // Options for the observer (which mutations to observe)
    var config = {attributes: true};

    // Callback function to execute when mutations are observed
    var redrawChart = (mutationList, observer) => {
      if (mutationList[0].type == 'attributes' && mutationList[0]["attributeName"] == "data-graph"){
        const graphData = JSON.parse(this.chartWrapperTarget.dataset.graph);
        prevChart.update(graphData);
      }
    }

    // Create an observer instance linked to the callback function
    var observer = new MutationObserver(redrawChart);

    // Start observing the target node for configured mutations
    observer.observe(this.chartWrapperTarget, config);
  }

  // Update the creation form each time the user fill an input
  getNextForm(){
    const target = event.currentTarget;
    const formPart = target.closest('[data-form-part]');
    const formPartName = formPart.dataset.formPart;
    const seriesContainer = target.closest("[data-form-part='series']");
    const seriesNum = seriesContainer.dataset.seriesNum;

    // Update series params (kpi-type, quantity)
    const kpiTypeSelect = seriesContainer.querySelector("[data-kpi-type]")
    const quantitySelect = seriesContainer.querySelector("[data-quantity]")
    if (kpiTypeSelect) { seriesContainer.dataset.kpiType = kpiTypeSelect.value }
    if (quantitySelect) { seriesContainer.dataset.quantity = quantitySelect.value }

    // Check if the second axis option is activated
    const sndAxis = this.element.dataset.sndAxis;

    // Find the position in formOrder table
    let position, nextPartial;
    if (kpiTypeSelect.value !== ""){
      position = this.formOrder.indexOf(formPartName);
      nextPartial = this.formOrder[position + 1];
    } else {
      // If select of kpi select is empty, we just need to remove all the next inputs
      // No need to define nextPartial because it will stop before AJAX query
      position = 0;
    }

    // Remove all the next partials
    const positionDeletion = this.formOrder.indexOf(formPartName);
    this.formOrder.slice(positionDeletion + 1).forEach(name => {
      const partial = seriesContainer.querySelector(`[data-form-part='${name}']`);
      if (partial) {partial.remove()}
    })

    // Return if the select is empty (= we stop the AJAX query)
    if (target.value === "" || nextPartial === undefined) {return}

    // Particular case
      let operatorSelect
      if (formPartName === "operators"){
        operatorSelect = formPart.querySelector(`#chart_${seriesNum}_operator`)
        // y_options: quantity is filled and operator = count --> nextPartial = final_params (and not properties)
        if (quantitySelect.value !== "" && operatorSelect.value === "count") { nextPartial = "final_params"}
      }

    // Body parameters
    const body = {
      kpi_type: seriesContainer.dataset.kpiType,
      quantity: seriesContainer.dataset.quantity,
      series_num: seriesContainer.dataset.seriesNum,
      next_partial: nextPartial,
      snd_axis: sndAxis
    }

    // Set the url
    let url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/charts/display_next_form`;

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.text())
    .then(data => {
      if (formPartName === "y_options" || (formPartName === "operators" && operatorSelect.value !== "count")){
        const dataContainer = seriesContainer.querySelector("div[data-y-options]")
        dataContainer.insertAdjacentHTML('beforeend', data)
      } else {
        const dataContainer = seriesContainer.querySelector("div[data-series]>div")
        dataContainer.insertAdjacentHTML('beforeend', data)
      }
    });
  }

  // Add the HTML bloc to create a new series
  addSeries(){

    // Define the new series number
    const seriesNum = parseInt(this.addSeriesBtnTarget.dataset.count) + 1;

    // Body parameters
    const body = {
      series_num: seriesNum,
      next_partial: "series"
    }

    // Set the url
    let url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/charts/display_next_form`;

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.text())
    .then(data => {
        this.addSeriesBtnTarget.insertAdjacentHTML('beforebegin', data)

        // Update series count
        this.addSeriesBtnTarget.dataset.count = seriesNum;
    });
  }

  // Remove the HTML bloc of the concerned series and submit the form to update the preview chart
  deleteSeries(){
    const seriesBloc = event.currentTarget.closest('[data-form-part="series"]');
    seriesBloc.remove();  // Remove the series bloc
    Rails.fire(this.element, 'submit')
  }

  // Update the preview graph when an input is changed
  updatePreview(){
    Rails.fire(this.element, 'submit')
  }

  updateChartData(){
    const [_data, _status, xhr] = event.detail;
    if (xhr.response.startsWith('Turbolinks')) return; // Means the chart was created and the preview should not be refreshed

    const data = JSON.parse(xhr.response)

    this.chartWrapperTarget.dataset.graph = data['chart_data']
  }

  // Create the graph when clicking on the submit button
  createChart(){
    event.preventDefault();
    this.previewTarget.value = false;
    Rails.fire(this.element, 'submit')
  }

  // Show the second axis
  addAxis(){
    // NB: using disabled=true prevent params to be sent
    // Show Axis input
    this.sndAxisInputTarget.classList.remove('hidden');
    this.sndAxisInputTarget.querySelector('input').disabled = false;
    // Show Rem Btn
    this.remAxisBtnTarget.classList.remove('hidden');
    // Hide add Btn
    this.addAxisBtnTarget.classList.add('hidden');
    // Update the snd-axis option
    this.element.dataset.sndAxis = true;
    // Show for each series, the second axis option
    this.sndAxisOptionTargets.forEach(option => {
      option.classList.remove('hidden')
      option.querySelector('input').disabled = false;
    });
  }

  // Hide the second axis
  remAxis(){
    // NB: using disabled=true prevent params to be sent
    // Hide Axis input
    this.sndAxisInputTarget.classList.add('hidden');
    this.sndAxisInputTarget.querySelector('input').disabled = true;
    // Hide Rem Btn
    this.remAxisBtnTarget.classList.add('hidden');
    // Show add Btn
    this.addAxisBtnTarget.classList.remove('hidden');
    // Update the snd-axis option
    this.element.dataset.sndAxis = false;
    // Show for each series, the second axis option
    this.sndAxisOptionTargets.forEach(option => {
      option.classList.add('hidden')
      option.querySelector('input').disabled = true;
    })
  }
}
