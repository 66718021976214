import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'message', 'deleteBtn']

  updateFileSelection(){
    let filesCount = this.inputTarget.files.length;

    if (filesCount === 1) {
      let fileName = this.inputTarget.value.split('\\').pop();
      this.messageTarget.innerText = fileName;
      this.deleteBtnTarget.style.display = 'inline-block';
    } else if (filesCount === 0) {
      this.messageTarget.innerText = this.messageTarget.dataset.initTransl;
      this.deleteBtnTarget.style.display = 'none';
    } else {
      this.messageTarget.innerText = this.messageTarget.dataset.selectionTransl.replace('X', filesCount);
      this.deleteBtnTarget.style.display = 'inline-block';
    }
  }

  deleteFiles(){
    event.preventDefault()
    this.inputTarget.value = null;
    this.messageTarget.innerText = this.messageTarget.dataset.initTransl;
    this.deleteBtnTarget.style.display = 'none';
  }
}