import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['card']

  highlightCard(){
    this.cardTargets.forEach(card => {
      card.querySelector('[data-image]').classList.remove('bg-dark-200')
      card.querySelector('[data-image]').classList.add('bg-gray-400')
    })

    event.currentTarget.querySelector('[data-image]').classList.add('bg-dark-200')
    event.currentTarget.querySelector('[data-image]').classList.remove('bg-gray-400')
  }

  unhighlightCards(){
    this.cardTargets.forEach(card => {
      card.querySelector('[data-image]').classList.remove('bg-dark-200')
      card.querySelector('[data-image]').classList.add('bg-gray-400')
    })

    // Find current selected plan
    const currentPlanId = this.element.dataset.selectedPlanId;
    if (!currentPlanId) return;

    const selectedCard = this.cardTargets.find(card => card.dataset.id === currentPlanId)
    selectedCard.querySelector('[data-image]').classList.add('bg-dark-200')
    selectedCard.querySelector('[data-image]').classList.remove('bg-gray-400')
  }
}
