import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';


export default class extends Controller {
  static targets = ['historyPanel', 'chevronButton', 'showLabel', 'hideLabel', 'inputWrapper', 'input', 'gear', 'greenDot']

  // Event handlers
  historyPanelUpdatedHandler = (event) => this.updateHistoryPanel(event.detail.data)
  updateParameterIconHandler = (event) => this.updateParamIcon(event.detail)

  connect(){
    this.focusInput()

    window.addEventListener('history-panel-updated', this.historyPanelUpdatedHandler)
    window.addEventListener('update-parameter-icon', this.updateParameterIconHandler)
  }

  disconnect(){
    window.removeEventListener('history-panel-updated', this.historyPanelUpdatedHandler)
    window.removeEventListener('update-parameter-icon', this.updateParameterIconHandler)
  }

  updateHistoryPanel(data){
    this.historyPanelTarget.innerHTML = data.history;
  }

  toggleHistoryPanel(){
    this.historyPanelTarget.classList.toggle('w-0')
    this.historyPanelTarget.classList.toggle('w-1/4')
    this.historyPanelTarget.classList.toggle('opacity-0')
    this.historyPanelTarget.classList.toggle('opacity-100')

    this.chevronButtonTarget.classList.toggle('rotate-180')
    this.showLabelTarget.classList.toggle('hidden')
    this.hideLabelTarget.classList.toggle('hidden')
  }

  updateParamIcon(parameterActive){
    if (parameterActive){
      this.greenDotTarget.classList.remove('hidden')
      this.gearTarget.classList.replace('text-gray-500', 'text-dark-200')
    } else {
      this.greenDotTarget.classList.add('hidden')
      this.gearTarget.classList.replace('text-dark-200', 'text-gray-500')
    }
  }

  autoresizeInput(){
    const textInput = event.currentTarget

    textInput.style.height = 'auto'; // Réinitialise la hauteur à 'auto' pour obtenir la hauteur naturelle du contenu
    textInput.style.height = textInput.scrollHeight + 'px'; // Ajuste la hauteur en fonction de la hauteur du contenu

    if (textInput.value === '') textInput.style.height = '40px'
  }

  focusInput(){
    this.inputWrapperTarget.classList.add('border', 'border-gray-500')
  }

  unfocusInput(){
    this.inputWrapperTarget.classList.remove('border', 'border-gray-500')
  }

  keyUp(){
    if ((event.ctrlKey || event.metaKey) && event.key === 'Enter'){
      this.sendRequest()
    }
  }

  sendRequest(){
    const question = this.inputTarget.value
    if (question === '') return;

    const displayEvent = new CustomEvent("display-question", { detail: { question: question } });
    window.dispatchEvent(displayEvent);

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/copilot_items`
    const body = { question: question }
    
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Redirect to new show page
      window.location.href = data["url"];
    });
  }
}