import { Controller } from "@hotwired/stimulus"
import ChartJs from '../components/initialize_graph';

export default class extends Controller {
  static targets = ["chart"]

  connect(){
    this.chartJsInstances = []
    this.chartTargets.forEach(chart => {
      const chartId = chart.id.split('-')[1]
      this.chartJsInstances[chartId] = this.drawAChart(chart);
    })
  }

  drawAChart(chart) {

    const graphData = JSON.parse(chart.dataset.graph);
    if (chart.closest('.origin-chart_crea')){
      if (graphData.options?.plugins?.legend){
        graphData['options']['plugins']['legend']['display'] = false;
      }
      if (graphData.options?.scales?.y?.title){
        graphData['options']['scales']['y']['title']['display'] = false;
      }
      if (graphData.options?.scales?.x?.title){
        graphData['options']['scales']['x']['title']['display'] = false;
      }
      graphData['options']['animation'] = false;
    }
    const newChart = new ChartJs(chart, graphData);
     // Static chart for charts in the charts gallery modal
    newChart.drawStatic();
    // Static chart for sortable charts in edit view

    return newChart
  }
}
