import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // Event handlers
  updatePageStateHandler = (event) => this.updateState(event.detail);

  connect(){
    window.addEventListener('state-updated', this.updatePageStateHandler)
  }

  disconnect(){
    window.removeEventListener('state-updated', this.updatePageStateHandler)
  }

  updateState(detail){
    const currentState = JSON.parse(this.element.dataset.pageState)
    const newState = Object.assign(currentState, detail)

    this.element.dataset.pageState = JSON.stringify(newState)

    // Update member settings
    const memberSettings = new CustomEvent("member-settings-updated", { detail: { topics_settings: newState } });
    window.dispatchEvent(memberSettings);
  }
}
