
function entireRowLink(container){
  const rows = container.querySelectorAll("td[data-href]:not([data-href=''])");

  rows.forEach(row => {
    const addLink = () => {
        if (row.hasAttribute('target')){
          window.open(row.dataset.href);
        } else {
          window.location.href = row.dataset.href;
        }
    }
    
    row.removeEventListener('dblclick', addLink); // Remove any former listener
    row.addEventListener("dblclick", addLink);
  });
}

export default entireRowLink;
