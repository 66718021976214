import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect(){
    const permanent = this.element.dataset.permanent;
    if (permanent !== "true"){
      setTimeout(() => {
        this.element.remove();
      }, 10000)
    }
  }

  close(){
    this.element.remove();
  }
}
