import { Controller } from "@hotwired/stimulus";
import { removeContent, projectName } from '../components/utilities';

export default class extends Controller {
  updateComponent(){
    const componentId = event.currentTarget.id
    const selectedAttributes = []
    Array.from(event.currentTarget.selectedOptions).forEach ((option, index) => {
      selectedAttributes.push({ field_name: option.value, position: index+1 })
    })

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/custom_components/${componentId}`;

    const body = { attributes: selectedAttributes }

    // AJAX call to update the user role
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      const componentId = JSON.parse(data.component_id)
      const preview = document.querySelector(`#preview-${componentId}`)
      const previewHtml = JSON.parse(data.html)
      removeContent(preview)
      preview.insertAdjacentHTML('beforeend', previewHtml);
      document.querySelector('[data-controller="flash-init"]').dataset.content = data.flash;
    })
  } 
}
