import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from "../components/utilities"

export default class extends Controller {
  static targets = ["frame", "input", "copyLabel", "nextBtn"];

  connect(){
    this.updateNextBtn()
  }

  // Create an API key
  create(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/api/create_key`;
    const verb = 'post';
    const body = { import_id: this.element.dataset.importId }
    this.callAPInfo(url, verb, body);
  }

  // Rotate key
  rotate(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/api/rotate_key`;
    const verb = 'post';
    const body = { import_id: this.element.dataset.importId }
    this.callAPInfo(url, verb, body);
  }

  // Destroy key
  delete(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/api/destroy_key`;
    const verb = 'delete';
    const body = { import_id: this.element.dataset.importId }
    this.callAPInfo(url, verb, body);
  }

  // Copy the key
  copy(){
    const copiedBtn = event.currentTarget
    // Copy the text inside the text field
    navigator.clipboard.writeText(this.inputTarget.value);

    // Initial text
    const initialText = this.copyLabelTarget.innerText
    const copiedText = this.copyLabelTarget.dataset.copied

    this.copyLabelTarget.innerHTML = copiedText
    copiedBtn.classList.remove('display-btn')
    copiedBtn.classList.add('display-btn-selected', 'pointer-events-none')
    setTimeout(() => {
      this.copyLabelTarget.innerHTML = initialText
      copiedBtn.classList.add('display-btn')
      copiedBtn.classList.remove('display-btn-selected', 'pointer-events-none')
    }, 500)
  }

  // AJAX call for API
  callAPInfo(url, verb, body = undefined){
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    const parameters = {
        method: verb,
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin"
      }

    if (body) parameters.body = JSON.stringify(body)

    fetch(url, parameters)
    .then(response => response.json())
    .then(data => {
      if (data['flash']){
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
      }

      if (data['key_frame']){
        removeContent(this.frameTarget);
        this.frameTarget.insertAdjacentHTML('beforeend', data['key_frame']);
      }

      this.updateNextBtn()
    })
  }

  updateNextBtn(){
    if (!this.hasNextBtnTarget) return;

    if (this.inputTarget.value.length > 0){
      this.nextBtnTarget.classList.remove('btn-disabled')
    } else {
      this.nextBtnTarget.classList.add('btn-disabled')
    }
  }
}
