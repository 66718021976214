import { Controller } from "@hotwired/stimulus"
import initializeTooltips from '../../components/app_tooltips';
import { projectName } from '../../components/utilities';

export default class extends Controller {
  static targets = ['minimizeBtn', 'maximizeBtn', 'minimizeIcon']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    initializeTooltips(this.element)
  }

  updateName(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.element.dataset.id}/update_name`
    const body = { name: event.currentTarget.value, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }

  toggleMinimize(){
    // Show loader
    event.currentTarget.querySelector('[data-icon]').classList.add('hidden')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.element.dataset.id}/minimize`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

      // Update icons
      this.minimizeBtnTarget.classList.toggle('hidden')
      this.maximizeBtnTarget.classList.toggle('hidden')
      this.minimizeBtnTarget.querySelector('[data-icon]').classList.remove('hidden')
      this.maximizeBtnTarget.querySelector('[data-icon]').classList.remove('hidden')
      this.minimizeBtnTarget.querySelector('[data-loading]').classList.add('hidden')
      this.maximizeBtnTarget.querySelector('[data-loading]').classList.add('hidden')
      this.minimizeIconTargets.forEach(el => el.classList.toggle('hidden'))
    });
  }

  delete(){
    // Show loader
    event.currentTarget.querySelector('[data-icon]').classList.add('hidden')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.element.dataset.id}`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Hide Right panel
      const topicPanelEvent = new CustomEvent("topic-panel-updated");
      window.dispatchEvent(topicPanelEvent);

      // Macro list display
      const macroListEvent = new CustomEvent("macro-topic-tags-updated", { detail: { data: data } });
      window.dispatchEvent(macroListEvent);

      // Tag list display
      const tagListEvent = new CustomEvent("tags-updated", { detail: { data: data } });
      window.dispatchEvent(tagListEvent);

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }
}
