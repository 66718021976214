import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updateState(){
    const button = event.currentTarget;
    const url = button.dataset.route;

    // AJAX call to get the loading partial
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: '{}'
      })
    .then(response => response.json())
    .then(data => {
      this.element.innerHTML = data['state_component']
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    })
  }
}