import { Controller } from "@hotwired/stimulus"
import initializeTooltips from '../../components/app_tooltips';
import { removeContent, projectName } from '../../components/utilities';
import initializeSelect from "../../components/initialize_select";

export default class extends Controller {
  static targets = ['topTypeSelect'];

  // Event handlers
  metricUpdateHandler = (event) => this.reloadPanel()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    this.initializeTopTypeSelect();
    initializeTooltips(this.element);
    window.addEventListener('metric-updated', this.metricUpdateHandler)
  }

  disconnect(){
    window.removeEventListener('metric-updated', this.metricUpdateHandler)
  }

  initializeTopTypeSelect(){
    const select = this.topTypeSelectTarget;

    initializeSelect({
      selector: select,
      otherSettings: {
        options: JSON.parse(select.dataset.options),
        items: JSON.parse(select.dataset.items),
        onItemAdd: (value, item) => this.changeTopType(value),
        render: {
          option: function(data, escape) {
            return `<div>
                      <div class="my-1 font-bold text-left text-lg">${escape(data.text)}</div>
                    </div>`
          },
          item: function(data, escape) {
            return `<div class='h-8 flex'>
                      <div class="m-auto font-bold text-center text-lg">${escape(data.text)}</div>
                    </div>`
          }
        }
      }
    })
  }

  changeTopType(value){
    // Update State
    const stateEvent = new CustomEvent("state-updated", { detail: { top_type: value } });
    window.dispatchEvent(stateEvent);

    this.reloadPanel();
  }

  reloadPanel(){
    // Reload Panel by sending an event that is captured by js controller indicator
    const loadIndicatorEvent = new CustomEvent("load-indicator", { detail: { indicator_id: 'top_contributors_indicator' } });
    window.dispatchEvent(loadIndicatorEvent);
  }
}
