import { Controller } from "@hotwired/stimulus"
import { projectName, removeContent } from "../components/utilities"
import setTooltips from '../components/app_tooltips';
import initializeFlatpickr from "../components/flatpickr_date";

export default class extends Controller {
  static targets = ['loaderTpl', 'content']

  // Triggered by the custom event "fetch-modal-content"
  fetchModalContent(){
    let modalType = event.detail.type
    
    this.reinitializeModal()

    let url;
    switch (modalType){
    case 'create':
      url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/charts/new`;
      this.requestContent(url)
      break;
    case 'edit':
      const chartId = event.detail.id;
      url = `${window.location.origin}/${projectName(window.location.pathname)}/admin/charts/${chartId}/edit`;
      this.requestContent(url)
      break;
    }
  }

  requestContent(url){
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin"
    })
    .then(response => response.text())
    .then(data => {
      this.updateModal(data)
    });
  }

  reinitializeModal(){
    removeContent(this.contentTarget);
    this.contentTarget.insertAdjacentHTML('beforeend', this.loaderTplTarget.innerHTML)
  }

  updateModal(data){
    removeContent(this.contentTarget);
    this.contentTarget.insertAdjacentHTML('afterbegin', data)
  }
}