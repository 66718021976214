import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../../components/utilities';

export default class extends Controller {

  toggleWorkflowActivation(){
    console.log('hello')
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/activate`
    const activated = event.currentTarget.checked
    const body = { 
      cluster_id: this.element.dataset.clusterId,
      workflow_id: this.element.dataset.workflowId,
      activated: activated
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    })
  }
}