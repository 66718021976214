import { Controller } from "@hotwired/stimulus";
import { projectName } from '../components/utilities';
import initializeTooltips from '../components/app_tooltips';

export default class extends Controller {
  static targets = ['dropdown', 'emoji'];

  connect(){
    initializeTooltips(this.element)
  }

  validateChange(){
    event.stopPropagation()
    // Check if the selected sentiment is equal with the the current one
    // If equal, hide the emoji dropdown component
    // Else then call updateSentiment function

    let currentSentiment = this.element.dataset.sentiment;
    let newSentiment = event.currentTarget.dataset.sentiment;

    if (currentSentiment === newSentiment) {
      // Close the dropdown by initiating a click event on the emoji icon
      this.clickEmoji();
    } else {
      this.updateSentiment(newSentiment);
    }
  }

  updateSentiment(newSentiment){
    const pageState = JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/extracts/${this.element.dataset.extractId}/update_sentiment`;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;

    const body = {
      page_state: pageState,
      sentiment: newSentiment,
      row_id: event.currentTarget.closest('tr').id,
      list_id: event.currentTarget.closest('[data-controller="list"]').id
    };

    fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
      .then(response => response.json())
      .then(data => {
        // Display flash message
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
        const listRow = data['list_row'];

        if (!listRow) return;
        
        // Find row
        const row = document.querySelector(`#${listRow['row_id']}`)

        // Add new row under the one (if present)
        if (listRow['list_row']) row.insertAdjacentHTML('afterend', listRow['list_row'])

        // Delete former row
        row.remove();
      });
  }

  clickEmoji(){
    // Create a new click event
    const clickEvent = new Event('click');

    // Dispatch the click event for the emoji icon
    this.emojiTarget.dispatchEvent(clickEvent);
  }

}
