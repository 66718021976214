import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../../components/utilities';
import ChartJs from "../../../components/initialize_graph";

export default class extends Controller {
  static targets = ['chart'];

  // Event handlers
  agentMetricUpdateHandler = (event) => this.updateAgentPanelEvolution()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    window.addEventListener('agent-metric-updated', this.agentMetricUpdateHandler)
    this.displayGraph()
  }

  disconnect() {
    window.removeEventListener('agent-metric-updated', this.agentMetricUpdateHandler)
  }

  // Initialize the chart of evolution of metrics 
  displayGraph() {
    const graphContainer = document.querySelector('#agent-evolution-chart');
    // If the graph container is not found, this means there is no data so the chart should not be drawn
    if (!graphContainer) return;
    const graphData = JSON.parse(graphContainer.dataset.chart);
    const agentEvolutionChart = new ChartJs(graphContainer, graphData);
    agentEvolutionChart.drawChart();
  } 

  updateAgentPanelEvolution(){
    if (this.pageState().right_panel === null) return;

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/agents/agent_panel_evolution`;
    const body = { type: 'metric_evolution', page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.updatePanel(data)
    })
  }

  updatePanel(data){
    this.element.insertAdjacentHTML('afterend', data['AgentTracker::AgentPanel::EvolutionComponent'] );
    this.element.remove()
  }  
}