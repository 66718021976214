import Sortable from 'sortablejs'

export default function DragAndDropPlugin() {
  if (this.settings.mode !== 'multi') {
    return
  }

  // stop propagation of re-rendered items
  this.hook('after', 'render', () => {
    if (this.control) {
      const items = this.control.querySelectorAll('[data-value]')

      items.forEach((item) => {
        item.addEventListener('mousedown', (e) => {
          e.stopPropagation()
        })
      })
    }
  })

  this.on('initialize', () => {
    new Sortable(this.control, {
      draggable: '[data-value]',
      filter: 'input',
      // animation: 150,
      // direction: 'horizontal', // to restrict to horizontal movement
      onEnd: () => {
        const values = Array.from(this.control.querySelectorAll('[data-value]')).map(
          (item) => item.dataset.value,
        )
        this.setValue(values)
      },
    })
  })
}