import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {
  static targets = ['panel'];

  // Event handlers
  unselectPanelHandler = (event) => this.unselectPanel()
  timeFrameUpdatedHandler = (event) => this.initializePanel()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    window.addEventListener('unselect-panel', this.unselectPanelHandler)
    window.addEventListener('time-frame-updated', this.timeFrameUpdatedHandler)

    this.initializePanel()
  }

  disconnect() {
    window.removeEventListener('filtered-data-updated', this.filterDataUpdateHandler)
    window.removeEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
  }

  initializePanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/highlights/panel`;

    const body = { type: this.element.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      const leftPanelName = Object.keys(data).find(key => key.includes('LeftPanel'))
      const leftPanelCategory = data[leftPanelName]
      if (!leftPanelCategory) return;

      removeContent(this.element)
      this.element.insertAdjacentHTML('afterbegin', leftPanelCategory );
      setTooltips(this.element)
    })
  }

  unselectPanel(){
    if (this.pageState().selected_panel === this.element.dataset.type) return;

    this.panelTarget.classList.remove('highlights-btn-selected')
  }

  updatePanel(){
    if (this.pageState().selected_panel === this.element.dataset.type) return;

    const stateEvent = new CustomEvent("state-updated", { detail: { selected_panel: this.element.dataset.type, selected_automation_template_id: null } });
    window.dispatchEvent(stateEvent);

    const rightPanelEvent = new CustomEvent("left-panel-updated");
    window.dispatchEvent(rightPanelEvent);

    this.panelTarget.classList.add('highlights-btn-selected')

    const unselectBtnEvent = new CustomEvent("unselect-panel");
    window.dispatchEvent(unselectBtnEvent);
  }
}