import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['questionTpl', 'loader']

  // Event handler
  answerReceivedHandler = (event) => this.displayAnswer(event.detail.data);

  connect(){
    window.addEventListener('display-question', this.displayQuestionHandler)
    window.addEventListener('answer-received', this.answerReceivedHandler)
  }

  disconnect(){
    window.removeEventListener('display-question', this.displayQuestionHandler)
    window.removeEventListener('answer-received', this.answerReceivedHandler)
  }
  
  displayAnswer(data){
    this.loaderTarget.classList.add('hidden')
    console.log('answer displayed')
  }
}