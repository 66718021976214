import { Controller } from "@hotwired/stimulus"
import { removeContent } from '../../../components/utilities';

export default class extends Controller {

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  filteredDataUpdatedHandler = (event) => this.refreshPanel(event.detail.data);

  connect(){
    // Events
    window.addEventListener('filtered-data-updated', this.filteredDataUpdatedHandler)
  }

  disconnect(){
    // Events
    window.removeEventListener('filtered-data-updated', this.filteredDataUpdatedHandler)
  }

  refreshPanel(data){
    if (!this.pageState().tickets_visible) this.hidePanel()

    const ticketsPanelName = Object.keys(data).find(key => key.includes('AgentTickets'))
    const ticketsPanel = data[ticketsPanelName]
    if (!ticketsPanel) return;

    removeContent(this.element)
    this.element.insertAdjacentHTML('afterbegin', data['AgentTracker::AgentPanel::TicketsComponent'] );
    this.element.classList.remove('hidden');
  }

  closePanel(){
    this.hidePanel()

    const stateEvent = new CustomEvent("state-updated", { detail: { tickets_visible: false } });
    window.dispatchEvent(stateEvent);
  }

  hidePanel(){
    this.element.classList.add('hidden')
  }
}
