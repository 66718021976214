import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../../components/utilities';
import ChartJs from "../../../components/initialize_graph";

export default class extends Controller {
  static targets = ['chart'];

  // Event handlers
  timeFrameUpdatedHandler = (event) => this.initializePanel()
  automationUpdatedHandler = (event) => this.initializePanel()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    window.addEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
    window.addEventListener('automation-updated', this.automationUpdatedHandler)

    this.initializePanel()
  }

  disconnect() {
    window.removeEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
    window.removeEventListener('automation-updated', this.automationUpdatedHandler)
  }

  initializePanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/results_panel`;

    const body = { type: this.element.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.element)
      this.element.insertAdjacentHTML('afterbegin', data["Automations::ScoreChartComponent"] );

      this.initializeChart();
    })
  }

  initializeChart(){  
    if (!this.hasChartTarget) return;
    
    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    this.historyChart = new ChartJs(this.chartTarget, graphData);
    this.historyChart.drawChart();

    // For now we are explicitly calling the toggleSeries to hide the lines between bars, probably this could be refacto in the future to have a default state without these series
    this.historyChart.toggleSeries(1);
  }
}