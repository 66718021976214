import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  filterDataUpdatedHandler = (event) => this.rebuildTable(event.detail.data);
  agentPanelClosedHandler = (event) => this.unhighlightRows()

  connect(){
    // Event Listeners
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    window.addEventListener('agent-panel-closed', this.agentPanelClosedHandler)

    // Highlight row if needed
    const agentId = this.pageState().right_panel
    if (!agentId) return
    this.highlightRow(agentId)
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    window.removeEventListener('agent-panel-closed', this.agentPanelClosedHandler)
  }

  rebuildTable(data){
    if (!Object.keys(data).includes('AgentTracker::TableComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['AgentTracker::TableComponent'])
    this.element.remove();
  }

  highlightRow(agentId){
    const selectedRow = this.element.querySelector(`tr[id='agents-${agentId}']`)
    if (selectedRow === null) return;
    selectedRow.classList.add('bg-gray-200')
  } 

  selectRow(){
    this.element.querySelectorAll('tr').forEach(tr => tr.classList.remove('bg-gray-200'))
    event.currentTarget.closest('tr').classList.add('bg-gray-200')

    const id = event.currentTarget.closest('tr').id.replace('agents-', '')

    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: id, tickets_visible: false } });
    window.dispatchEvent(stateEvent);

    const agentPanelEvent = new CustomEvent("agent-panel-updated");
    window.dispatchEvent(agentPanelEvent);
  }

  unhighlightRows(){
    this.element.querySelectorAll('tr.bg-gray-200').forEach(tr => tr.classList.remove('bg-gray-200'))
  }
}
