import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  tabUpdatedHandler = (event) => this.updatePanels();

  connect(){
    // Events
    window.addEventListener('tab-updated', this.tabUpdatedHandler)
    setTooltips(this.element);
  }

  disconnect(){
    // Events
    window.removeEventListener('tab-updated', this.tabUpdatedHandler)
  }

  updatePanels(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/right_panel`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      const panelName = Object.keys(data).find(key => key.includes('Component'))
      const panel = data[panelName]
      if (panel) this.element.innerHTML = panel
    });
  }




}
