import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {
  static targets = ['overview', 'topicPanel', 'searchPanel', 'topicInfoPanel', 'verbatimPanel']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  tabUpdatedHandler = (event) => this.updatePanels();
  overviewUpdatedHandler = (event) => this.updateOverview(event.detail.data);
  topicPanelUpdatedHandler = (event) => this.toggleTopicsPanel();
  topicPanelRefreshedHandler = (event) => this.refreshTopicPanel(event.detail.data);
  filterDataUpdateHandler = (event) => this.updateAfterFilter(event.detail.data);
  SearchPanelupdatedHandler = (event) => this.refreshSearchPanel(event.detail.data);
  closeSearchPanelHandler = (event) => this.closeSearchPanel();


  connect(){
    // Events
    window.addEventListener('tab-updated', this.tabUpdatedHandler)
    window.addEventListener('overview-updated', this.overviewUpdatedHandler)
    window.addEventListener('topic-panel-updated', this.topicPanelUpdatedHandler)
    window.addEventListener('topic-panel-refreshed', this.topicPanelRefreshedHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdateHandler)
    window.addEventListener('search-panel-updated', this.SearchPanelupdatedHandler)
    window.addEventListener('close-search-panel', this.closeSearchPanelHandler)

    
  }

  disconnect(){
    // Events
    window.removeEventListener('tab-updated', this.tabUpdatedHandler)
    window.removeEventListener('overview-updated', this.overviewUpdatedHandler)
    window.removeEventListener('topic-panel-updated', this.topicPanelUpdatedHandler)
    window.removeEventListener('topic-panel-refreshed', this.topicPanelRefreshedHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdateHandler)
    window.removeEventListener('search-panel-updated', this.SearchPanelupdatedHandler)
    window.removeEventListener('close-search-panel', this.closeSearchPanelHandler)
  }

  toggleTopicsPanel(){
    const rightPanel = this.pageState().right_panel

    if (rightPanel === null){
      this.hideTopicPanel()
    } else {
      this.updateTopicPanel()
    }
  }

  hideTopicPanel(){
    this.topicPanelTarget.classList.add('hidden')
  }
  
  updateTopicPanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/topic_panel`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.refreshTopicPanel(data)
    });
  }


  updatePanels(){
    const pageState = JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/right_panels`
    const body = { page_state: pageState }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      const overviewName = Object.keys(data).find(key => key.includes('Overview'))
      const overviewPanel = data[overviewName]
      if (overviewPanel) this.overviewTarget.innerHTML = overviewPanel

      this.refreshTopicPanel(data)
    });
  }

  // When switching between macro and micro
  updateOverview(data){
    const overviewName = Object.keys(data).find(key => key.includes('Overview'))
    const overviewPanel = data[overviewName]
    if (overviewPanel) this.overviewTarget.innerHTML = overviewPanel
    setTooltips(this.element)
  }

  toggleVerbatimPanel(){
    this.topicInfoPanelTarget.classList.toggle('hidden')
    this.verbatimPanelTarget.classList.toggle('hidden')
    event.currentTarget.classList.toggle('display-btn')
    event.currentTarget.classList.toggle('display-btn-selected')

    const stateEvent = new CustomEvent("state-updated", { detail: { verbatim_tab: !this.pageState().verbatim_tab } });
    window.dispatchEvent(stateEvent);

    // Unselect extracts from the verbatim list to hide the action bar
    const listSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_cluster_panel_clustered_extracts_list', type: 'extracts', selected_ids: [] } });
    window.dispatchEvent(listSelectionEvent);
    // Unselect extracts from the ai verbatim list to hide the action bar (when in AI panel and going to verbatim tab -> need to reset the action bar)
    const aiListSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_ai_list', type: 'extracts', selected_ids: [] } });
    window.dispatchEvent(aiListSelectionEvent);
  }

  updateAfterFilter(data){
    // Overview
    this.updateOverview(data)
    // Search Panel
    this.refreshSearchPanel(data)
    // Topic Panel
    this.refreshTopicPanel(data)
    setTooltips(this.element)
  }

  refreshTopicPanel(data){
    if (this.pageState().right_panel === null) this.hideTopicPanel()

    const topicPanelName = Object.keys(data).find(key => key.includes('TopicPanel'))
    const topicPanel = data[topicPanelName]
    if (!topicPanel) return;

    this.topicPanelTarget.innerHTML = topicPanel
    this.topicPanelTarget.classList.remove('hidden')
  }

  refreshSearchPanel(data){
    if (!Object.keys(data).includes('Topics::SearchPanelComponent')) return;

    this.searchPanelTarget.innerHTML = data['Topics::SearchPanelComponent']
    this.searchPanelTarget.classList.remove('hidden')
  }

  closeSearchPanel(){
    this.searchPanelTarget.classList.add('hidden')
  }
}
