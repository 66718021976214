import { Controller } from "@hotwired/stimulus"
import { projectName, capitalize } from '../../../../../components/utilities';
import setTooltips from '../../../../../components/app_tooltips';
import initializeSelect from "../../../../../components/initialize_select";

export default class extends Controller {
  static targets = ['macroSelect', 'existingForm', 'createForm', 'nameInput', 'colorInput']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  initializeMacroTopicDropdownHandler = (event) => this.initializeMacroTopicDropdown();

  connect(){
    // Event
    window.addEventListener('initialize-macro-topic-dropdown', this.initializeMacroTopicDropdownHandler)

    setTooltips(this.element)
  }

  disconnect(){
    window.removeEventListener('initialize-macro-topic-dropdown', this.initializeMacroTopicDropdownHandler)
  }

  initializeMacroTopicDropdown(){
    if (this.tomselectInstance !== undefined) {
      this.tomselectInstance.focus()
      this.existingFormTarget.classList.remove('hidden')
      this.createFormTarget.classList.add('hidden')
      return;
    }

    this.tomselectInstance = initializeSelect({
      selector: this.macroSelectTarget,
      config: JSON.parse(this.macroSelectTarget.dataset.config),
      otherSettings: {
        persist: false, // Do not save the new user entry as options (do it once the macro cluster is created)
        options: JSON.parse(this.macroSelectTarget.dataset.options),
        items: this.macroSelectTarget.dataset.items,
        searchField: ['name'],
        render: {
          option: (data, escape) => {
            const color = (data.color === undefined) ? '#667085' : data.color
  
            return `<div class="cursor-pointer flex justify-start items-center gap-1"
                      style="color:${color}">
                      <div class="flex-shrink-0 w-3 h-3 rounded-full" style="background-color:${color}"></div>
                      <span class="">${capitalize(escape(data.name))}</span>
                    </div>`
          },
          item: (data, escape) => {
            const color = (data.color === undefined) ? '#667085' : data.color
  
            return `<div class="cursor-pointer flex justify-start items-center gap-1"
                      style="color:${color}">
                      <div class="flex-shrink-0 w-3 h-3 rounded-full" style="background-color:${color}"></div>
                      <span class="">${capitalize(escape(data.name))}</span>
                    </div>`
          }
        },
        onItemAdd: (value, item) => {
          // Check if the value is a string or a num.
          // If num --> The record already exists
          // If not --> the user just created it and we need to add it the category list
          if (isNaN(item.dataset.value)){
            this.existingFormTarget.classList.add('hidden')
            this.createFormTarget.classList.remove('hidden')
            this.nameInputTarget.value = value
            this.nameInputTarget.focus()
  
            this.tomselectInstance.clear() // Reset select to avoid to add the created item but unformated
          } else {
            this.addCategory(value)
          }
        }
      }
    });

    this.tomselectInstance.focus()
  }

  addCategory(macroClusterId){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.element.dataset.id}/add_macro_cluster`
    const body = { macro_cluster_id: macroClusterId, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Macro topic list in overview panel
      const macroTopicListEvent = new CustomEvent("macro-topic-tags-updated", { detail: { data: data } });
      window.dispatchEvent(macroTopicListEvent);
      
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

      // Macro topic in topic panel
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::TopicPanel::MacroTopicComponent'])
      this.element.remove();
    });
  }

  createMacroCluster(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.element.dataset.id}/create_macro_cluster`
    const body = {
                   name: this.nameInputTarget.value,
                   color: this.colorInputTarget.value,
                   page_state: this.pageState()
                 }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Macro topic list in overview panel
      const macroTopicListEvent = new CustomEvent("macro-topic-tags-updated", { detail: { data: data } });
      window.dispatchEvent(macroTopicListEvent);
      
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

      // Macro topic in topic panel
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::TopicPanel::MacroTopicComponent'])
      this.element.remove();
    });
  }

  removeMacroTopic(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.element.dataset.id}/remove_macro_cluster`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Macro topic list in overview panel
      const macroTopicListEvent = new CustomEvent("macro-topic-tags-updated", { detail: { data: data } });
      window.dispatchEvent(macroTopicListEvent);
      
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

      // Macro topic in topic panel
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::TopicPanel::MacroTopicComponent'])
      this.element.remove();
    });
  }
}
