import { Controller } from "@hotwired/stimulus"

import cytoscape from 'cytoscape';

import coseBilkent from 'cytoscape-cose-bilkent';
cytoscape.use( coseBilkent );

export default class extends Controller {
  static targets = ['noDataPanel']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  screenSizeToggledHandler = (event) => this.updateMapAfterResizing(event.detail.screen)
  updateMapHandler = (event) => this.updateMapAfterScaleChange(event.detail.data);
  zoomInHandler = (event) => this.zoomIn()
  zoomOutHandler = (event) => this.zoomOut()
  macroTopicRowClickedHandler = (event) => this.highlightMacroTopic(event.detail.macro_cluster_id)
  resetPageHandler = (event) => this.resetPage()
  filterDataUpdatedHandler = (event) => this.filterMap()

  connect(){
    // Cancel stimulus connect event, if body contains `data-turbolinks-preview` attribute
    // Prevent errors and element flickering when turbolinks loading
    if (document.documentElement.hasAttribute("data-turbolinks-preview"))
      return;

    // Events
    window.addEventListener('screen-size-toggled', this.screenSizeToggledHandler)
    window.addEventListener('map-updated', this.updateMapHandler)
    window.addEventListener('zoom-in', this.zoomInHandler)
    window.addEventListener('zoom-out', this.zoomOutHandler)
    window.addEventListener('macro-topic-row-clicked', this.macroTopicRowClickedHandler)
    window.addEventListener('reset-page', this.resetPageHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)

    // Show table if page_state ok
    if (this.pageState().display === 'map') this.element.classList.remove('hidden')
    if (this.pageState().scale === 'micro') this.element.classList.add('hidden')      
    if (this.pageState().scale === 'macro') this.initializeMap()
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('screen-size-toggled', this.screenSizeToggledHandler)
    window.removeEventListener('map-updated', this.updateMapHandler)
    window.removeEventListener('zoom-in', this.zoomInHandler)
    window.removeEventListener('zoom-out', this.zoomOutHandler)
    window.removeEventListener('macro-topic-row-clicked', this.macroTopicRowClickedHandler)
    window.removeEventListener('reset-page', this.resetPageHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  initializeMap(){
    const mapData = JSON.parse(this.element.dataset.mapData)
    if (mapData === null) return

    this.cy = cytoscape({
      container: this.element,
      elements: mapData["elements"],
      wheelSensitivity: 0.05
    });

    // Define basic style
    this.cy.style([
      {
        selector: 'node',
        style: {
            'label': 'data(cluster_name)',
            'background-opacity': '0.8',
            'text-halign': 'center',
            'text-valign': 'center',
            'font-size': 0,
            'min-zoomed-font-size': 0,
            'font-family': 'urbanist'
        }
      },
      {
        selector: 'node.selected',
        style: {
            'background-opacity': '1',
            'border-width': '4%',
            'border-color': '#344054'
        }
      },
      {
          selector: 'node.semitransp',
          style:{ 'background-opacity': '0.2' }
      }
    ]);

    // Set the extremes to set then bubble size
    // NB: clusters that are minimized are excluded from the search of the extremes
    const metrics = this.cy.nodes().map(node => [node.data('metric'), node.data('minimized')])
    this.findExtremes(metrics, true)

    // Customize styling for the current graph
    this.cy.nodes().forEach((node) => {
      node.css('background-color', node.data('macro_cluster_color'));
      node.data('size', this.setNodeSize(node.data('metric'), node.data('validated'), node.data('minimized'), true));
      node.style({'width': node.data('size'), 'height': node.data('size')});
      node.css(this.fontStyle(node));
    });

    // Display the graph
    this.displayMap();
  }

  // Set the size of the bubbles based on a normalization
  setNodeSize(metric, initMode){
    let maxBubbleSize = 500;
    let minBubbleSize = 50;

    let size;

    // If the cluster metric is outside the defined metric range, set the size to 0
    if (!initMode && (metric < this.metricRange[0] || metric > this.metricRange[1])){
      return 0;
    }

    if (this.maxMetric === this.minMetric){
      // If min = max --> All nodes have the same impact, so arbitrary, we set a size to 100
      size = minBubbleSize;
    } else{
      if (this.pageState().reversed_scale){
        size = (this.maxMetric - metric)*(maxBubbleSize - minBubbleSize)/(this.maxMetric - this.minMetric) + minBubbleSize;
      } else {
        size = (metric - this.minMetric)*(maxBubbleSize - minBubbleSize)/(this.maxMetric - this.minMetric) + minBubbleSize;
      }
    }

    return size;
  }

  // Find min and max of the impact
  findExtremes(array, updateMetricRange){
    // Determine bubble size range (does not take into account the minimized nodes)
    const bubbleSizeRange = array.filter(el => !el[1]).map(el => el[0])
    this.maxMetric = Math.max(...bubbleSizeRange)
    this.minMetric = Math.min(...bubbleSizeRange)
    if (!updateMetricRange) return

    // Determine metric range (take into account all the nodes)
    const metricRange = array.map(el => el[0])
    this.minMetricRange = Math.floor(Math.min(...metricRange))
    this.maxMetricRange = Math.ceil(Math.max(...metricRange))
    this.metricRange = [this.minMetricRange, this.maxMetricRange]
  }

  // Set font style of node labels
  fontStyle(node){
    return {
      'font-size': node._private.style.width.value*0.15,
      'color': '#1D2939'
    }
  }

  // Set the layout of the graph and display it
  displayMap(){
    // Logic:
      // Step 1: display the map without filters to set the position of the nodes
      // Step 2: update the size of the node with the filters and adjust the position of nodes
      // Goal: keep a map as stable as possible

    // Define the layout COSE-BILKENT
    let layoutOptions = {
      name: "cose-bilkent",
      nodeDimensionsIncludeLabels: true,
      stop: () => {
        this.initialZoom = this.cy.zoom()*0.7;
        this.cy.minZoom(this.cy.zoom()*0.7);
        // Add interaction with the graph
        this.addMapListener();
      }
    }

    // Print the map without filters
    this.layout = this.cy.layout(layoutOptions);
    this.layout.run();

    // Define a one time event listener after the layout runs for the first time to adjust the node size based on filters
    // This will call the setNodesSizes method one time only for every load of the graph.
    this.layout.one('layoutstop', event => {
      if (this.pageState().filters.length === 0) return;
      this.filterMap() // Filtering of nodes is done through setNodesSizes. This method also filters by validated only if the flag is on.
    })
  }

  filterMap(){
    if (this.pageState().scale !== 'macro') return

    this.setNodesSizes();
    setTimeout(()=> this.resizeMap(), 300);
  }

  // Called by displayMap() when the graph is initialized
  // Triggered when a filter is applied or when the Validated Only flag is changed
  // Called by updateGraphAfterFilter from insights_update_front_controller once a filter is triggered
  // Called by setTrendMode() after the Validated Only flag is changed
  setNodesSizes(updateMetricRange = true){
    // Hide the no data window
    this.noDataPanelTarget.classList.add('hidden');

    let filteredNodes = this.pageState().filtered_nodes
    
    // Refresh extremes values (excludes minimized metrics from calculation)
    let nodes = Object.values(filteredNodes)
    const metrics = nodes.map(macroCluster => [macroCluster['metric'], macroCluster['minimized'] === 'true'])
    this.findExtremes(metrics, updateMetricRange)

    let noVisibleNodes = true;

    this.cy.nodes().forEach(node => {
      const macroClusterId = parseInt(node.id());
      const macroClusterMetric = (filteredNodes[macroClusterId] === undefined) ? undefined : filteredNodes[macroClusterId]['metric'];

      let size = this.setNodeSize(macroClusterMetric, false);
      this.resizeNode(node, size);

      if (size !==0) noVisibleNodes = false;
    })

    // When there is no data returned, a window on top of the graph is displayed
    if (noVisibleNodes) this.noDataPanelTarget.classList.remove('hidden');
  }

  resizeNode(node, size=0) {
    node.animate({
        style: { width: size, height: size },
        duration: 400,
        easing: 'ease-in-sine'
    })

    setTimeout(() => {
      node.style(this.fontStyle(node));
    }, 450);
  }

  resizeMap(){
    setTimeout(() => {
      this.cy.removeAllListeners()
      this.layout.run()
    }, 200)
  }

  addMapListener(){
    this.cy.on('tap', event => {
      var selectedElement = event.target;

      // If we click on the background of the graph
      if( selectedElement === this.cy ){
        // We reinitialize the graph
        this.resetMap()
        this.toggleRightPanel(null)
        this.resetMacroTable()
      // If we click on an object (node or edge)
      } else {
        if (selectedElement.group() !== 'nodes') return
        // We hightlight the macro topic
        this.highlightMacroTopic(selectedElement.id())
        // We send an AJAX request to get info to display on the right panel
        this.toggleRightPanel(selectedElement.id());
        // Once selection are made, update the cluster list selection
        this.updateMacroTable(selectedElement.id());
      }
    });
  }

  hightlightNode(selectedNode){
    this.resetMap()
    this.cy.nodes().not(selectedNode).addClass('semitransp')
    selectedNode.addClass('selected');
  }

  toggleRightPanel(macroClusterId){
    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: macroClusterId } });
    window.dispatchEvent(stateEvent);

    const topicPanelEvent = new CustomEvent("topic-panel-updated");
    window.dispatchEvent(topicPanelEvent);

    if (this.pageState().screen === 'small') return

    // If the right panel is hidden because the graph size is 'big', then dispatch an event that will be captured by the left_panel_controller.js in order to decrease the graph size
    const toggleScreenEvent = new CustomEvent("toggle-screen-size");
    window.dispatchEvent(toggleScreenEvent);
  }

  resetMap(){
    this.cy.nodes().removeClass('semitransp')
    this.cy.elements().removeClass('selected');
  }

  resetMacroTable(){
    const tableEvent = new CustomEvent("macro-topic-unclicked");
    window.dispatchEvent(tableEvent);
  }
  
  resetPage(){
    if (this.pageState().scale !== 'macro') return;

    this.resetMap()
    this.resetMacroTable()
    this.toggleRightPanel(null)
  }

  updateMacroTable(macroClusterId){
    const tableEvent = new CustomEvent("macro-topic-clicked", { detail: { macro_cluster_id: macroClusterId } });
    window.dispatchEvent(tableEvent);
  }

  highlightMacroTopic(macroClusterId){
    const selectedNode = this.cy.nodes().filter(node => node.data('id') === macroClusterId)[0]
    this.hightlightNode(selectedNode)
  }

  updateMapAfterResizing(){
    if (this.pageState().scale !== 'macro') return;
    setTimeout(() => this.resizeMap(), 500)
  }


  updateMapAfterScaleChange(data){
    if (this.pageState().scale !== 'macro' || this.pageState().display !== 'map') {
      this.element.classList.add('hidden')
    }

    if (this.pageState().scale !== 'macro') return;

    if (!Object.keys(data).includes('Topics::MacroMapComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['Topics::MacroMapComponent'])
    this.element.remove();
  }

  zoomIn(){
    if (this.pageState().scale !== 'macro') return;
    
    const currentZoom = this.cy.zoom();
    this.cy.zoom(currentZoom + 0.05);
  }

  zoomOut(){
    if (this.pageState().scale !== 'macro') return;
    
    const currentZoom = this.cy.zoom();
    this.cy.zoom(currentZoom - 0.05);
  }
}
