import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['content', 'uploadForm', 'colSep', 'colSepSelect', 'saveBtn', 'form',
  'colCorrInput', 'chevronShow', 'title', 'sentimentCkb', 'hiddenSentimentCkb',
  'existingOn', 'existingOff', 'catColSelect', 'clusterCharacteristics'];
  // "columnTpl" from init_db_conf_controller.js

  connect() {
  }

  showSpinner(){
    if (!this.uploadFormTarget.checkValidity()) return;

    const spinner = event.currentTarget.querySelector('i')
    spinner.classList.remove('hidden')
  }

  // If no file or file other than csv, xlsx, xlsm, xls  => "save" btn disabled
  checkFileExtension() {
    const fileInput = document.querySelector(`#file`)
    this.colSepTarget.classList.add('hidden')
    this.colSepSelectTarget.tomselect.disable()
    if (fileInput.files.length === 0) return;

    const extension = fileInput.files[0]['name'].split('.')[1]
    if (extension === 'csv'){
      this.colSepTarget.classList.remove('hidden')
      this.colSepSelectTarget.tomselect.enable()
    } else{
      if (['xlsx', 'xlsm', 'xls'].includes(extension)) {
        this.saveBtnTarget.classList.remove('btn-disabled')
      } else {
        this.saveBtnTarget.classList.add('btn-disabled')
      }
    }
  }

  // Called after form submission to prevent people from updating previous steps
  hideAndDisableOptions(div) {
    div.querySelectorAll('.btn').forEach(element => {
      element.remove()
    })
    div.querySelectorAll('input').forEach(element => {
      element.disabled = true
    })
    div.querySelectorAll('select').forEach(element => {
      element.disabled = true
      const tomInstance = element.tomselect // Disable tom-selects
      if (tomInstance) tomInstance.disable()
    })
  }

  toggleSaveBtn(){
    (event.currentTarget.value !== '') ? this.saveBtnTarget.classList.remove('btn-disabled') : this.saveBtnTarget.classList.add('btn-disabled')
  }
}


