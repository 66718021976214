import { Controller } from "@hotwired/stimulus"
import setTooltips from '../components/app_tooltips';

export default class extends Controller {
  static targets = ['guidelineWrapper', 'guidelineTpl', 'submitBtn',
                    'itemId', 'answerWrapper', 'guidelines', 'question', 'answer', 'errorMsg', 'loader']

  connect(){
    setTooltips(this.element)
  }

  addGuideline(){
    const position = parseInt(this.element.dataset.guidelineCount, 10) + 1

    const newGuideline = this.guidelineTplTarget.innerHTML
                             .replace(/POSITION/g, position)

    this.guidelineWrapperTarget.insertAdjacentHTML('beforeend', newGuideline)
    setTooltips(this.element)

    this.element.dataset.guidelineCount = position

    this.submitBtnTarget.classList.remove('hidden')
  }

  removeGuideline(){
    event.currentTarget.closest('[data-guideline]').remove()
  }

  showFlashMessage(){
    const [_data, _status, xhr] = event.detail;
    const data = JSON.parse(xhr.response)

    document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
  }

  testGuidelines(){
    this.loaderTarget.classList.remove('hidden')
    this.answerWrapperTarget.classList.add('hidden')
    this.errorMsgTarget.classList.add('hidden')

    const url = this.element.dataset.testPath;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    const itemId = this.element.querySelector('#item_id').value
    const guidelines = Array.from(this.element.querySelectorAll('[id^="hidden_field_"]')).map(input => input.value)
    const answerType = Array.from(this.element.querySelectorAll("[name='answer_type']")).find(el => el.checked).value 

    const body = {
      item_id: itemId,
      answer_type: answerType,
      guidelines: guidelines
    }

    fetch(url, {
      method: 'post',
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
  .then(response => response.json())
  .then(data => {
    this.loaderTarget.classList.add('hidden')

    if (data['error']){
      this.errorMsgTarget.innerHTML = data.error
      this.answerWrapperTarget.classList.add('hidden')
      this.errorMsgTarget.classList.remove('hidden')
    } else {
      let guidelineList = "<ul>";
      data.guidelines.forEach(guideline => {
        guidelineList += `<li>${guideline}</li>`
      })
      guidelineList += "</ul>"
      this.guidelinesTarget.innerHTML = guidelineList;
      this.questionTarget.innerHTML = data.question;
      this.answerTarget.innerHTML = data.answer;
      this.answerWrapperTarget.classList.remove('hidden')
      this.errorMsgTarget.classList.add('hidden')
    }
  })
  }
}