import { Controller } from "@hotwired/stimulus"
import { removeContent } from "../components/utilities";
import entireRowLink from "../components/table_link";
import setTooltips from '../components/app_tooltips';

export default class extends Controller {
    static targets = ["header", "selectionBanner", "selectAllMessage", "clearAllMessage", 'headCheckbox', 'checkbox',
                      "basicBannerTpl", "selectionBannerTpl", "allBannerTpl", "loader", "searchBar", 'closeBtn']

    // Event handlers
    listSelectionUpdatedHandler = (event) => this.updateSelectionBasedOnList(event.detail.list_id, event.detail.type, event.detail.selected_ids)
		searchTermsUpdatedHandler = (event) => this.updateSearch(event.detail.list_id, event.detail.terms, event.detail.mode)

		pageState(){
			return JSON.parse(document.querySelector('[data-page-state]')?.dataset?.pageState || '{}')
		}

    connect(){
			entireRowLink(this.element);
			setTooltips(this.element);
			
			this.listData = JSON.parse(this.element.dataset.data)

			// If the check option is not activated: no connect
			if (this.element.dataset.selectable === 'false') return

			// When the check option is selected, browse the rows and check the ones that are selected in selectionArray
			this.selectionArray = this.listData.selection.selected_ids
			this.exceptionArray = this.listData.selection.excepted_ids

			let rows = Array.from(this.element.querySelectorAll('tbody tr'));
			rows.shift(); // Needed to remove the first tr which is the selection message
			const ids = rows.map(tr => tr.id.split('-')[1]);

			// Deals with the all case
			if (this.selectionArray[0] === "all"){
				rows.forEach( tr => {
					const id = tr.id.split('-')[1];
					const checkbox = tr.querySelector('[data-checkbox]')
					if (!this.exceptionArray.includes(id)) checkbox.checked = true
				})
			} else {
				rows.forEach( tr => {
					const id = tr.id.split('-')[1];
					const checkbox = tr.querySelector('[data-checkbox]')
					if (this.selectionArray.includes(id)) checkbox.checked = true
				})
			}

			if (this.hasHeadCheckboxTarget) this.updateHeadCheckbox();

			// Event Listeners
			window.addEventListener('list-selection-updated', this.listSelectionUpdatedHandler)
			window.addEventListener('search-terms-updated', this.searchTermsUpdatedHandler)
    }

    disconnect(){
      window.removeEventListener('list-selection-updated', this.listSelectionUpdatedHandler)
			window.removeEventListener('search-terms-updated', this.searchTermsUpdatedHandler)
    }

    // When hovering header
    addHighlight(){
			const target = event.currentTarget;
			const arrowUp = target.querySelector("i.fa-caret-up");
			const arrowDown = target.querySelector("i.fa-caret-down");

			if (target.dataset.order === 'asc'){
				arrowUp.classList.add('text-gray-500');
			} else {
				arrowDown.classList.add('text-gray-500');
			}
    }

    // When removing hover from header
    removeHighlight(){
			const target = event.currentTarget;
			const arrowUp = target.querySelector("i.fa-caret-up");
			const arrowDown = target.querySelector("i.fa-caret-down");

			arrowUp.classList.remove('text-gray-500');
			arrowDown.classList.remove('text-gray-500');
    }

    sort(){
			// Update sort params
			this.listData.sort['order'] = event.currentTarget.dataset.order === 'desc' ? 'asc' : 'desc';
			this.listData.sort['sort_key'] = event.currentTarget.dataset.sortKey;

			// Call AJAX to get the requested sort
			setTimeout(() => {
				this.callFilterAction();
			}, 100)

			// Save sort options of the cluster table in the current member's settings
			const listIds = ['clusters_index_clusters_list', 'sentiments_index_clusters_list', 'knowledge_tracker_articles_index_list', , 'agent_tracker_agents_index_list']

			if (!listIds.includes(this.element.id)) return;
			const stateEvent = new CustomEvent("state-updated", { detail: { table_sorting: this.listData.sort } });
			window.dispatchEvent(stateEvent);
    }

    changePage(){
			event.preventDefault();
			const verb = event.currentTarget.dataset.verb;
			const page = event.currentTarget.getAttribute('href');
			this.callFilterAction(verb, page);
    }

    // send the filter form when writing characters in search field
    searchInList() {
			if (event.currentTarget.value === '' || event.currentTarget.value === undefined){
				this.closeBtnTarget.classList.add('hidden')
			} else {
				this.closeBtnTarget.classList.remove('hidden')
			}

			if (event.key === 'Enter' || event.type === "click") this.launchSearch()
    }

    closeSearch(){
			this.searchBarTarget.value = '';
			this.launchSearch()
    }

    launchSearch(){
			// Reinitialize action bar buttons
			if (this.element.dataset.selectable === 'true') this.resetList()

			this.listData.search_term = this.searchBarTarget.value
			event.stopPropagation();
			this.callFilterAction();
    }

    callFilterAction(verb = "post", page = 1){
			// Show the loader
			this.loaderTarget.classList.remove('hidden');

			let url = this.element.dataset.sortUrl;
			const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
					.content.value;

			let options = {
				method: verb,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"X-CSRF-Token": csrfToken
				},
				credentials: "same-origin"
			}

			if (verb === "get"){
				url += `?page=${page}`
			} else {
				const body = {
					list_id: this.element.id,
					page: page,
					data: this.listData,
					page_state: this.pageState()
				}

				Object.assign(options, {body: JSON.stringify(body)})
			}

			// Send AJAX request to get the page results
			fetch(url, options).then(response => response.json())
				.then(data => {
					if (data['refreshed_list']){
						this.element.insertAdjacentHTML('beforebegin', data['refreshed_list'])
						this.element.remove();
	
						setTooltips(this.element);
					}

					if (data['flash']){
						document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
					}

					// Hide the loader
					this.loaderTarget.classList.add('hidden');
				})
    }

    // Action triggered when clicking on a checkbox
    updateSelection(){
      const checkbox = event.currentTarget;
      this.updateListSelection(checkbox);
    }

    // Manage the selection of one element in the list
    // Call by action and when interacting with map (merge, link... clusters)
    updateListSelection(checkbox){
			// Check the state
			if (checkbox.checked){
				// Update the selected list
				const id = checkbox.closest('tr').id.split('-')[1];
				this.updateArraySelection('add', [id])
			} else {
				// Update the selected list
				const id = checkbox.closest('tr').id.split('-')[1];
				this.updateArraySelection('remove', [id])
			}

			// Update the head checkbox
			this.updateHeadCheckbox();

			// Update the selection banner
			this.updateSelectionBanner();
    }		

		// Call by other controller to update the selection of the list
    updateSelectionBasedOnList(listId, type, selectedIds){
			if (this.element.id !== listId) return;

			// Remove the old selection (reset list without updating the map)
			this.resetList();
			if (selectedIds.length === 0) return;

			// Add new selection
			selectedIds.forEach(id => {
				const checkbox = this.element.querySelector(`tbody tr[id='${type}-${id}'] [data-checkbox]`)
				if (checkbox === null) return;

				checkbox.checked = true
				this.updateListSelection(checkbox);
			})
    }

    // Activated when the clicking on the head checkbox
    selectAll(){
			const ids = Array.from(this.element.querySelectorAll('tbody tr')).map(tr => tr.id.split('-')[1]);
			ids.shift(); // Needed to remove the first tr which is the selection message

			// Check the state
			if (this.headCheckboxTarget.checked){
			// Special case: the list is included in one page, thus it is like selecting All results
			if (ids.length === JSON.parse(this.element.dataset.data).selection.results_count){
				this.selectAllResults();
				return
			}
			// Normal case:
			// Change the style of the checkbox of the page
			const checkboxes = this.element.querySelectorAll('tbody [data-checkbox]')
			checkboxes.forEach(checkbox => checkbox.checked = true)

			// Add all the units of the page in the selectionArray
			this.updateArraySelection('add', ids)
			} else {
				this.resetList(this.headCheckboxTarget, ids)
			}
			// Initialize the banner to select all the units of the database
			this.updateSelectionBanner();
    }

    // Reset list
    resetList(){
			// Change the style of the head select
			this.headCheckboxTarget.checked = false
			// Change the style of the checkboxes of the page
			const checkboxes = this.element.querySelectorAll('[data-checkbox]')
			checkboxes.forEach(checkbox => checkbox.checked = false)

			// Remove all the units of the page in the selectionArray
			this.updateArraySelection('remove_all')

			// Update the selection banner
			this.updateSelectionBanner();
    }

    // Activated when clicking in the banner, on 'Select all results'
    selectAllResults(){
			// Update page checkboxes
			const checkboxes = this.element.querySelectorAll('[data-checkbox]')
			checkboxes.forEach(checkbox => checkbox.checked = true)

			// Update arrays of selected elements
			this.updateArraySelection('add_all')

			this.updateSelectionBanner();
    }

    // Update the arrays responsible for transmitting the information to action bar or filter/pagination action
    updateArraySelection(action, ids=[]){
			if (action == 'remove_all'){
				this.selectionArray = [];
				this.exceptionArray = [];
			} else if (action == 'add_all'){
				this.selectionArray = ['all'];
				this.exceptionArray = [];
			}
			if (action === "add"){
				if (this.selectionArray[0] === "all"){
					this.exceptionArray = this.exceptionArray.filter(id => !ids.includes(id));
				} else {
					ids.forEach(id => this.selectionArray.push(id))
					this.selectionArray = Array.from(new Set(this.selectionArray)); // remove duplicated ids
				}
			} else{
					if (this.selectionArray[0] === "all"){
						ids.forEach(id => this.exceptionArray.push(id))
						this.exceptionArray = Array.from(new Set(this.exceptionArray)); // remove duplicated ids
					} else {
						this.selectionArray = this.selectionArray.filter(id => !ids.includes(id));
					}
			}

			this.listData.selection.selected_ids = this.selectionArray
			this.listData.selection.excepted_ids = this.exceptionArray
			this.element.dataset.data = JSON.stringify(this.listData)

			// Update action buttons with new arrays
			this.updateActionButtons();
    }

    // Function to update the head checkbox when interacting with a checkbox of the list
    updateHeadCheckbox(){
			const rowsCount = this.element.querySelectorAll('tbody tr').length - 1;

			// Head checkbox is empty: the selectionArray is empty = no selection
			if (this.selectionArray.length === 0){
				this.headCheckboxTarget.checked = false;
				this.headCheckboxTarget.indeterminate = false;
			// Head checkbox checked:
					// Possibility 1: All the rows of the current page are in selectionArray
					// Possibility 2: the select_all options is activated and no exception was registered in the exceptionArray
			} else if (this.selectionArray.length === rowsCount || this.selectionArray[0] === 'all' && this.exceptionArray.length === 0) {
				this.headCheckboxTarget.checked = true;
				this.headCheckboxTarget.indeterminate = false;
			// Head checkbox partially checked: all other cases
			} else {
				this.headCheckboxTarget.indeterminate = true;
			}
    }

    // Show the right banner depending on the checked elements
    updateSelectionBanner(){
			// Remove former content
			removeContent(this.selectionBannerTarget);

			// Configure the banner
			let content;
			if (this.selectionArray[0] === "all" && this.exceptionArray.length === 0){
				// Show the all selection message + Show the clear message
				content = this.allBannerTplTarget.innerHTML;
				this.listData.selection.selected_count = parseInt(this.listData.selection.results_count);
			} else if (this.selectionArray[0] === "all" && this.exceptionArray.length !== 0){
				// Show the number of selected elements (all - excepted elements) + the possibility to select all results
				content = this.selectionBannerTplTarget.innerHTML;
				this.listData.selection.selected_count = parseInt(this.listData.selection.results_count) - this.exceptionArray.length;
				content = content.replace(/SELECTION_COUNT/g, this.listData.selection.selected_count);
			} else if (this.selectionArray.length === 0){
				// Show the number of results
				this.listData.selection.selected_count = 0;
				content = this.basicBannerTplTarget.innerHTML;
			} else {
				// Show the number of selected elements + the possibility to select all results
				content = this.selectionBannerTplTarget.innerHTML;
				this.listData.selection.selected_count = this.selectionArray.length;
				content = content.replace(/SELECTION_COUNT/g, this.listData.selection.selected_count);
			}

			// Insert template
			this.selectionBannerTarget.insertAdjacentHTML('beforeend', content);

			// Update action buttons
			this.updateActionButtons(); // Update data of each action button linked to this list
			this.updateListButton(); // Update shortcut buttons linked to this list
			if (this.element.id === 'clusters_index_clusters_list') {
				this.updateMap(); // Update topic map
				this.toggleClusterActionsPanel() // Show/hide cluster actions panel
			}

			this.element.dataset.data = JSON.stringify(this.listData)
    }

    // Update compound
    updateMap(){
			// Get selection
			const ids = Array.from(this.element.querySelectorAll('tbody tr')).map(tr => tr.id.split('-')[1]);
			ids.shift(); // Needed to remove the first tr which is the selection message
			let selectedIds;

			if (this.selectionArray[0] === 'all'){
				selectedIds = ids.filter(id => !this.exceptionArray.includes(String(id))).map(id => parseInt(id, 10))
			} else {
				selectedIds = this.selectionArray.map(id => parseInt(id, 10));
			}

			// Update page state only if coming from inside the list (= when a user clicks on a checkbox)
			// If not (= when a user clicks on a node), the following custom events are not triggered to avoid unecessary loops
			if (event.type === 'list-selection-updated') return;
			
			const stateEvent = new CustomEvent("state-updated", { detail: { selected_ids: selectedIds } });
			window.dispatchEvent(stateEvent);

			// Update micro map
			const mapEvent = new CustomEvent("topic-row-clicked");
			window.dispatchEvent(mapEvent);
    }

    // ACTION BUTTONS MANAGEMENT________________________________________________________________________________________

    // Update action buttons attributes with new selection
    updateActionButtons(){
			if (this.element.dataset.listActionId === '') return;

			const data = { ...this.listData } // Duplicate the listData

			const actionBtnsEvent = new CustomEvent("list-action-button-updated", { detail: { 
				list_action_id: this.element.dataset.listActionId, 
				list_id: this.element.id, 
				data: data } 
			});
			window.dispatchEvent(actionBtnsEvent);
    }

    // Enable/Disabled button linked to a list (for instance the 'add_selection' button from classification panel)
    updateListButton(){
			const buttons = document.querySelectorAll(`[data-button="${this.element.id}"]`)
			if (buttons.length === 0) return;

			if (this.listData.selection.selected_count > 0) {
				buttons.forEach(btn => btn.classList.remove('btn-disabled'))
			} else {
				buttons.forEach(btn => btn.classList.add('btn-disabled'))
			}
    }

		toggleClusterActionsPanel(){
			// Show right panel (if in full screen and selection > 1)
			if (this.pageState().selected_ids.length > 1){
				const screenEvent = new CustomEvent("reduce-screen-size");
				window.dispatchEvent(screenEvent);
			}

			// Show topic actions panel
			const togglePanelEvent = new CustomEvent("toggle-topic-action-panel");
			window.dispatchEvent(togglePanelEvent);
		}

		updateSearch(listId, terms, mode){
			if (this.element.id !== listId) return;
			
			this.searchBarTarget.focus()

			if (mode === 'replace'){
				this.searchBarTarget.value = terms
			} else {
				let currentTerms = this.searchBarTarget.value;

				var regex = new RegExp(`\\b${terms}\\b`, "g");
				if(currentTerms.match(regex)) return;

				this.searchBarTarget.value = (currentTerms === '') ? terms : `${currentTerms} OR ${terms}`
			}

			// Launch search in list
			this.launchSearch()
		}
}
