import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static targets = ["checkBox", "box"];

	mouseOver(){
		if (!this.checkBoxTarget.disabled){
			this.boxTarget.classList.add('border-gray-400', 'shadow');
		}
	}

	mouseOut(){
		if (!this.checkBoxTarget.disabled){
			this.boxTarget.classList.remove('border-gray-400', 'shadow');
		}
	}
}
