import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {
  static targets = ['chartWrapper', 'automationBtn'];

  // Event handlers
  updateRightPanelHandler = (event) => this.initializePanel()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    window.addEventListener('time-frame-updated', this.updateRightPanelHandler)
    window.addEventListener('left-panel-updated', this.updateRightPanelHandler)

    this.initializePanel()
  }

  disconnect() {
    window.removeEventListener('time-frame-updated', this.updateRightPanelHandler)
    window.removeEventListener('left-panel-updated', this.updateRightPanelHandler)
  }

  initializePanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/highlights/panel`;

    const body = { type: this.element.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.element)
      this.element.insertAdjacentHTML('afterbegin', data["Highlights::RightPanel::PanelComponent"] );
      setTooltips(this.element)
    })
  }

  focusAutomation(){
    let automationTemplateId;
    if (this.pageState().selected_automation_template_id === event.currentTarget.dataset.automationTemplateId){
      automationTemplateId = null
    } else {
      automationTemplateId = event.currentTarget.dataset.automationTemplateId
    }

    // Update page state
    const stateEvent = new CustomEvent("state-updated", { detail: { selected_automation_template_id: automationTemplateId } });
    window.dispatchEvent(stateEvent);

    // Update button class
    this.automationBtnTargets.forEach(btn => btn.classList.remove('highlights-btn-selected'))
    if (automationTemplateId) event.currentTarget.classList.add('highlights-btn-selected')

    this.initializePanel()
  }
}