import { Controller } from "@hotwired/stimulus"
import initializeTooltips from '../../../../../components/app_tooltips';
import { projectName } from '../../../../../components/utilities';

export default class extends Controller {
  static targets = ['tag', 'dropdown']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Events Handlers
  resetTagsHandler = (event) => this.resetTags(event.detail.excluded_tag);
  tagsUpdatedHandler = (event) => this.updateTags(event.detail.data);

  connect(){
    initializeTooltips(this.element)
    
    // Events
    window.addEventListener('reset-tags', this.resetTagsHandler)
    window.addEventListener('tags-updated', this.tagsUpdatedHandler)
  }

  disconnect(){
    // Events
    window.removeEventListener('reset-tags', this.resetTagsHandler)
    window.removeEventListener('tags-updated', this.tagsUpdatedHandler)
  }

  highlightTag(){
    this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
    event.currentTarget.classList.remove('opacity-25')

    const mouseenterEvent = new CustomEvent("tag-mouseover", { detail: { 
      color: event.currentTarget.dataset.color,
      cluster_ids: JSON.parse(event.currentTarget.dataset.clusterIds )
    } });
    window.dispatchEvent(mouseenterEvent);
  }

  unhighlightTag(){
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
    if (this.pageState().selected_tag){
      this.tagTargets.forEach(tag => tag.classList.add('opacity-25'))
      this.tagTargets.find(tag => tag.dataset.tagId === this.pageState().selected_tag.id).classList.remove('opacity-25')
    }

    const mouseoutEvent = new CustomEvent("tag-mouseout");
    window.dispatchEvent(mouseoutEvent);
  }

  selectTag(){
    let stateEvent;
    const tag = event.currentTarget

    // If we click on the edit button or the edit dropdown, we do not consider the tag selected
    if (event.target.tagName === 'I' || (this.hasDropdownTarget && this.dropdownTarget.contains(event.target))) return;

    if (this.pageState().selected_tag?.id === tag.dataset.tagId){
      stateEvent = new CustomEvent("state-updated", { detail: { selected_tag: null } });
    } else {
      stateEvent = new CustomEvent("state-updated", { detail: { selected_tag: { 
        id: tag.dataset.tagId,
        color: tag.dataset.color,
        cluster_ids: JSON.parse(tag.dataset.clusterIds)
      } } });

      const resetTagEvent = new CustomEvent("reset-tags", { detail: { excluded_tag: 'tag' }});
      window.dispatchEvent(resetTagEvent);
    }

    window.dispatchEvent(stateEvent);
  }

  resetTags(excludedTag){
    if (excludedTag === 'tag') return

    const stateEvent = new CustomEvent("state-updated", { detail: { selected_tag: null } });
    window.dispatchEvent(stateEvent);
    this.tagTargets.forEach(tag => tag.classList.remove('opacity-25'))
  }

  createTag(){
    event.preventDefault()

    // Show loader and disable submit button
    event.currentTarget.classList.add('btn-disabled')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    let formData = new FormData(event.currentTarget.form)
    let body = {}

    formData.forEach((value, key) => {
      if (['authenticity_token', '_method'].includes(key)) return;
      body[key] = value
    })

    body['page_state'] = this.pageState()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
		fetch(event.currentTarget.form.action, {
		    method: 'POST',
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      // Update Tag List
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::Overview::TagListComponent'])
      this.element.remove();

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		});
  }

  updateTag(){
    event.preventDefault()

    // Show loader and disable submit button
    event.currentTarget.classList.add('btn-disabled')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    let formData = new FormData(event.currentTarget.form)
    let body = {}

    formData.forEach((value, key) => {
      if (['authenticity_token', '_method'].includes(key)) return;
      body[key] = value
    })

    body['page_state'] = this.pageState()

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
		fetch(event.currentTarget.form.action, {
		    method: 'PUT',
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Update map
      const mouseoutEvent = new CustomEvent("tag-mouseout");
      window.dispatchEvent(mouseoutEvent);

      // Update Tag List
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::Overview::TagListComponent'])
      this.element.remove();

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		});
  }

  deleteTag(){
    // Show loader and disable submit button
    event.currentTarget.classList.add('btn-disabled')
    event.currentTarget.querySelector('[data-icon]').classList.add('hidden')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    let body = {
      page_state: this.pageState()
    }

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/tags/${event.currentTarget.dataset.tagId}`

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
		fetch(url, {
		    method: 'DELETE',
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Update map
      this.unhighlightTag()

      // Update Tag List
      this.updateTags(data)

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		});
  }

  updateTags(data){
    if (!Object.keys(data).includes('Topics::Topics::Micro::Overview::TagListComponent')) return

    this.element.insertAdjacentHTML('afterend', data['Topics::Topics::Micro::Overview::TagListComponent'])
    this.element.remove();
  }
}
