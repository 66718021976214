import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  toggleTopicActionPanelHandler = (event) => this.togglePanel()

  connect(){
    window.addEventListener('toggle-topic-action-panel', this.toggleTopicActionPanelHandler)
  }

  disconnect(){
    window.removeEventListener('toggle-topic-action-panel', this.toggleTopicActionPanelHandler)
  }

  togglePanel(){
    if (this.pageState().selected_ids.length > 1){
      this.element.classList.remove('hidden')
    } else {
      this.element.classList.add('hidden')
    }
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);
  }
}
