import { Controller } from "@hotwired/stimulus"

import { removeContent, projectName } from '../components/utilities';

export default class extends Controller {
  static targets = ['list', 'button', 'instruction'];

  extendSelection(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/extend_selection`;
    const data = JSON.parse(event.currentTarget.closest('.modal').dataset.requestData)
    const modalType = this.element.dataset.modalType;
    let clusterName;
    if (modalType === 'add_to_cluster'){
      clusterName = event.currentTarget.closest('.modal__container').querySelector('select').value
    } else {
      clusterName = ''
    }
    const body = { ...data, cluster_name: clusterName }

    // Manage button state
    const buttonWrapper = this.buttonTarget;
    const button = event.currentTarget;
    const originIcon = button.querySelector('[data-icon]');
    const loadIcon = button.querySelector('[data-loading]');

    originIcon.classList.add('hidden');
    loadIcon.classList.remove('hidden');
    button.classList.add('btn-disabled');

    // Send request
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.listTarget);
      this.listTarget.insertAdjacentHTML('beforeend', data['extended_list']);
      this.listTarget.classList.remove('hidden');
      this.instructionTarget.classList.remove('hidden');

      // Manage button state
      originIcon.classList.remove('hidden');
      loadIcon.classList.add('hidden');
      button.classList.remove('btn-disabled');
      buttonWrapper.classList.add('hidden');

      // Increase width of modal
      this.element.closest('.modal__container').style.setProperty('width', '75%', 'important');
    });
  }
}


