import TomSelect from 'tom-select';
import 'tom-select/dist/css/tom-select';
import { createPopper } from '@popperjs/core';
import DragAndDropPlugin from './select_drag_drop_plugin'

TomSelect.define('drag_drop', DragAndDropPlugin)

const initializeSelect = ({selector, config = {}, changeCallback = null, otherSettings = {}}) => {
  // Check that the select is not already initialized. If yes, return
  if (selector.tomselect !== undefined) return;

  // Add section
  let addSection = {
    create: config.addable,
    closeAfterSelect: config.close_after_select,
    maxOptions: 1000
  }

  // For Multiple Select fields with a maximum limit of selected items we also add to the config the max_items
  if (config.max_items !== undefined) Object.assign(addSection, { maxItems: config.max_items }) 

  // Add relevant plugins
  let plugins = []
  if (config.remove_button) plugins.push('remove_button')
  if (config.drag_and_drop) plugins.push('drag_drop')
  let pluginSection = {plugins: plugins}

  // Add remote data possibility
  let ajaxSection = {}
  if (config.hasOwnProperty('ajax_params') && Object.keys(config.ajax_params).length !== 0) {
    const ajaxFunction = function(query, callback) {
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
          .content.value;

      fetch(config.ajax_params["url"],{
        method: "POST",
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify({term: query, ...config.ajax_params["input"]})
      }).then(response => response.json())
        .then(json => {
          callback(json);
        }).catch(()=>{
          callback();
        });
      }

    ajaxSection = {load: ajaxFunction, preload: true}
  }

  // Add single select behavior if select is a single select
  let singleSelectBehavior = {};
  if (!selector.multiple) {
    singleSelectBehavior = {
      onType: function(str) {
        const tomInstance = selector.tomselect;
        if (tomInstance.items.length > 0) tomInstance.clear(true)
      },
      onItemAdd: function(str){
        const tomInstance = selector.tomselect;
        tomInstance.blur()
      }
    }
  }

  let changeSection = {};
  if (changeCallback !== null) {
    changeSection = {
      onChange: changeCallback
    }
  }

  const dropdownPosition = {
   onInitialize:function(){
      this.popper = createPopper(this.control,this.dropdown); 
    },
    onDropdownOpen:function(){
     this.popper.update();
    }
  }

  let settings = {...addSection, ...ajaxSection, ...changeSection, ...pluginSection, ...singleSelectBehavior, ...dropdownPosition}

  settings = Object.assign(settings, otherSettings)

  const selectInstance = new TomSelect(selector, settings)

  if (config.locked) selectInstance.lock()

  return selectInstance;
}

export default initializeSelect;
