import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";
import { projectName } from "../components/utilities";
import initializeFlatpickr from "../components/flatpickr_date";

export default class extends Controller {
  static targets = ['input'];

  connect(){
    switch (this.inputTarget.type) {
      case 'select-one':
        this.selectOptions = {
          selector: this.inputTarget,
          otherSettings: {
            items: JSON.parse(this.inputTarget.dataset.items)
          }
        };
        this.selectOptions.otherSettings.options = JSON.parse(this.inputTarget.dataset.options)
        initializeSelect(this.selectOptions);
        break;
      case 'date':
        initializeFlatpickr(this.inputTarget);
        break;
    }
  }

  updateClusterFields(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/update_cluster_field`;
		const verb = "POST";
    let value;

    if(event.currentTarget.type === 'checkbox') {
      value = event.currentTarget.checked
    }
    else{
      value = event.currentTarget.value
    }

		const body = {
      id: event.currentTarget.dataset.clusterId,
			field_value: value,
      field_id: event.currentTarget.dataset.fieldId
		};

		this.AJAXcall(url, verb, body);
  }

  // AJAX call to update cluster
	AJAXcall(url, verb, body){
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
		    .content.value;
    fetch(url, {
      method: verb,
      headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data);
    });
	}
}
