import { Controller } from "@hotwired/stimulus"
import setTooltips from '../../../../components/app_tooltips';

export default class extends Controller {

  connect(){
    setTooltips(this.element);
  }

  openUnclusteredPanel(){
    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: 'none' } });
    window.dispatchEvent(stateEvent);

    const topicPanelEvent = new CustomEvent("topic-panel-updated");
    window.dispatchEvent(topicPanelEvent);
  }
}
