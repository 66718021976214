import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal'];

  openModal(){
    const modalId = event.currentTarget.dataset.id;
    MicroModal.show(modalId);
  }

  destroy(){
    event.preventDefault()

    const destroyConfirmation = confirm(event.currentTarget.dataset.confirm)
    if (!destroyConfirmation) return;

    const connectorWrapper = event.currentTarget.closest('a');

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    const url = event.currentTarget.dataset.href;

    fetch(url, {
        method: 'delete',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin"
      })
    .then(response => response.json())
    .then(data => {
      if (data['state'] === 'success') {
        connectorWrapper.remove()
      }
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    })
  }
}