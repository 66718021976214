import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['paramForm']

  // Event handlers
  parametersUpdatedHandler = (event) => this.updateParameters()

  connect(){
    window.addEventListener('parameters-updated', this.parametersUpdatedHandler)
  }

  disconnect(){
    window.removeEventListener('parameters-updated', this.parametersUpdatedHandler)
  }

  updateParameters(){
    Rails.fire(this.paramFormTarget, 'submit')
  }

  updateParamIcon(){
    const [_data, _status, xhr] = event.detail;
    const data = JSON.parse(xhr.response)

    const updateIconEvent = new CustomEvent("update-parameter-icon", { detail: data.parameter_active });
    window.dispatchEvent(updateIconEvent);
  }
}