import { Controller } from "@hotwired/stimulus"
import { projectName, capitalize } from '../../../components/utilities';
import setTooltips from '../../../components/app_tooltips';
import initializeSelect from "../../../components/initialize_select";

export default class extends Controller {
  static targets = ['articleSelect', 'articleList', 'existingForm']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  // Event handlers
  initializeArticleDropdownHandler = (event) => this.initializeArticleDropdown();

  connect(){
    // Event
    window.addEventListener('initialize-article-dropdown', this.initializeArticleDropdownHandler)

    setTooltips(this.element)
  }

  disconnect(){
    window.removeEventListener('initialize-article-dropdown', this.initializeArticleDropdownHandler)
  }

  initializeArticleDropdown(){
    if (this.tomselectInstance !== undefined) {
      this.tomselectInstance.focus()
      return;
    }

    this.tomselectInstance = initializeSelect({
      selector: this.articleSelectTarget,
      config: JSON.parse(this.articleSelectTarget.dataset.config),
      otherSettings: {
        searchField: ['article_name', 'knowledge_name'],
        options: JSON.parse(this.articleSelectTarget.dataset.options),
        items: this.articleSelectTarget.dataset.items,
        render: {
          option: (data, escape) => {
  
            return `<div class="cursor-pointer flex justify-start items-center gap-1">
                      <div class="flex p-1 bg-gray-100 rounded flex-shrink-0">
                        <img class="w-3" src="${data.icon}">
                      </div>
                      <div>${escape(data.knowledge_name)} - ${escape(data.article_name)}</div>
                      <i class="text-orange-400 fa-light fa-lock ${(data.public) ? 'hidden' : ''}"></i>
                    </div>`
          },
          item: (data, escape) => {
            return `<div class="cursor-pointer flex justify-start items-center gap-1">
                      <div class="flex p-1 bg-gray-100 rounded flex-shrink-0">
                        <img class="w-3" src="${data.icon}">
                      </div>
                      <div>${escape(data.knowledge_name)} - ${escape(data.article_name)}</div>
                      <i class="text-orange-400 fa-light fa-lock ${(data.public) ? 'hidden' : ''}"></i>
                    </div>`
          }
        },
        onItemAdd: (value, item) => {
            this.addArticle(value)
        }
      }
    });

    this.tomselectInstance.focus()
  }

  addArticle(articleId){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/${this.element.dataset.class}/${this.element.dataset.id}/add_article`
    const body = { article_id: articleId, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
    .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Knowledge list in overview panel
      const knowleddgeListEvent = new CustomEvent("knowledges-updated", { detail: { data: data } });
      window.dispatchEvent(knowleddgeListEvent);
      
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

      // Article list in topic panel
      this.articleListTarget.innerHTML = data['Topics::Topics::ArticleListComponent']
      setTooltips(this.articleListTarget)

      // Remove option
      this.tomselectInstance.removeOption(articleId)
    });
  }

  removeArticle(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/${this.element.dataset.class}/${this.element.dataset.id}/remove_article`
    const body = { page_state: this.pageState(), article_id: event.currentTarget.dataset.articleId }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Knowledge list in overview panel
      const knowleddgeListEvent = new CustomEvent("knowledges-updated", { detail: { data: data } });
      window.dispatchEvent(knowleddgeListEvent);
      
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

      // Article in topic panel
      this.element.insertAdjacentHTML('afterend', data['Topics::Topics::ArticlesComponent'])
      this.element.remove();
    });
  }
}
