import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../../components/utilities';
import setTooltips from '../../../components/app_tooltips';

export default class extends Controller {
  static targets = ['chart'];

  // Event handlers
  timeFrameUpdatedHandler = (event) => this.initializePanel()
  automationUpdatedHandler = (event) => this.initializePanel()

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }
  
  connect() {
    window.addEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
    window.addEventListener('automation-updated', this.automationUpdatedHandler)

    this.initializePanel()
  }

  disconnect() {
    window.removeEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
    window.removeEventListener('automation-updated', this.automationUpdatedHandler)
  }

  initializePanel(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/workflows/results_panel`;

    const body = { type: this.element.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.element)
      this.element.insertAdjacentHTML('afterbegin', data["Automations::FunnelComponent"] );
      setTooltips(this.element)
    })
  }
}